import styled from 'styled-components'

import Pender from '@nickel/ui/components/legacy/Pender'

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 1em 2em;
    width: 100%;

    & > ${Pender} {
        display: block;
        flex-grow: 1;
        margin: 0 auto;
    }
`

const Details = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1em;
`

export default {
    Container,
    Details
}
