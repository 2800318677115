import { actions as uiActions } from '@nickel/stimulus/ui'

import { AddMrzLinesResultPayload, ControlValidity, RegistrationControl } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import form from './form'
import modals from './modals'

const mrzControl: Control = {
    actions: {
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlPayload: {
                        mrzLinesValidity: ControlValidity.VALID
                    } as AddMrzLinesResultPayload,
                    controlType: RegistrationControl.MRZ_LINES
                })
            }
        ],
        invalidate: [
            {
                action: uiActions.showModal(modals.invalidate)
            }
        ]
    },
    Form: form,
    hasDocumentChecker: true
}

export default mrzControl
