import React from 'react'

import { format } from 'date-fns'
import kebabCase from 'lodash/kebabCase'

import i18n from '@nickel/i18n'

import { getDateFormat, getDateWithHoursFormat } from '../../../l10n/date-utils'
import { ROUTES } from '../../../navigation/constants'
import { RegistrationFormListItemView } from '../../../services/api'
import { getComplianceStatusColor, getRegistrationFormTypeColor } from '../../utils'
import ColorItemRenderer from '../ColorItemRenderer'
import Styled from '../styles'
import { RegitrationFormTableData } from '../types'

const COLUMNS = [
    { accessor: 'registrationCountry' },
    { accessor: 'type' },
    { accessor: 'registrationCode' },
    { accessor: 'lastNames' },
    { accessor: 'firstNames' },
    { accessor: 'birthDate' },
    { accessor: 'createdAt' },
    { accessor: 'finalizationDate' },
    { accessor: 'assignee' },
    { accessor: 'complianceStatus' }
]

export const getColumns = () => COLUMNS.map((item) => ({ ...item, Header: i18n.t(`home:${kebabCase(item.accessor)}`) }))

export const convertToTableData: (dataInput: RegistrationFormListItemView[]) => RegitrationFormTableData[] = (
    dataInput: RegistrationFormListItemView[]
) =>
    dataInput.map(
        ({
            assignee,
            birthDate,
            complianceStatus,
            createdAt,
            finalizationDate,
            firstNames,
            id,
            lastNames,
            registrationCode,
            registrationCountry,
            type
        }) => ({
            assignee: assignee ?? '',
            birthDate: birthDate ? format(new Date(birthDate), getDateFormat()) : '',
            complianceStatus: (
                <ColorItemRenderer
                    color={getComplianceStatusColor(complianceStatus)}
                    label={i18n.t(`common:compliance-status.${complianceStatus}`)}
                />
            ),
            createdAt: createdAt ? format(new Date(createdAt), getDateWithHoursFormat()) : '',
            finalizationDate: finalizationDate ? format(new Date(finalizationDate), getDateWithHoursFormat()) : '',
            firstNames: firstNames?.join(' ') ?? '',
            id: id ?? '',
            lastNames: lastNames?.join(' ') ?? '',
            registrationCode: <Styled.LinkItem to={`/${ROUTES.DETAILS}/${id}`}>{registrationCode}</Styled.LinkItem>,
            registrationCountry: registrationCountry ?? '',
            type: (
                <ColorItemRenderer
                    color={getRegistrationFormTypeColor(type)}
                    label={i18n.t(`common:registration-form-types.${type}`)}
                />
            )
        })
    )
