import React, { useCallback, useMemo } from 'react'

import { isNil } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { SupportedCountryISO2 } from '@nickel/l10n/types'
import Select from '@nickel/ui/components/legacy/form/Select'

import {
    actions as registrationFormsActions,
    selectors as registrationFormsSelectors
} from '../../../store/registrationForms'

const SearchCountry = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const storedValue = useSelector(registrationFormsSelectors.getSearchCountry)

    const onChange = useCallback(
        (value: SupportedCountryISO2) => {
            if (!isNil(value)) dispatch(registrationFormsActions.setSearchCountry(value))
        },
        [dispatch]
    )
    const options = useMemo(
        () =>
            Object.values(SupportedCountryISO2).map((value) => ({
                value,
                label: t(`countries:${value}`)
            })),
        [t]
    )

    return (
        <Select
            light
            name="search-country-select"
            onChange={onChange}
            options={options}
            placeholder={t('home:search-country')}
            label={t('home:search-country')}
            value={storedValue}
        />
    )
}

export default SearchCountry
