import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import { actions as controlsActions, selectors as controlSelectors } from '../..'
import { StimulusType } from '../../../../config/errors'
import { controlResourceApi } from '../../../../services'
import { AddMrzLinesResultPayload } from '../../../../services/api'
import { selectors as navigationSelectors } from '../../../navigation'
import { http } from '../../../utils'

export function* control(payload: AddMrzLinesResultPayload): SagaIterator {
    try {
        const currentRegistrationFormId = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId = yield select(controlSelectors.getDocumentId)
        const mrzLines: string[] = yield select(controlSelectors.getMrzLines)

        const _payload: AddMrzLinesResultPayload = {
            ...payload,
            mrzLines: mrzLines.filter(Boolean)
        }

        yield call(http(), controlResourceApi.updateMrzLineValidity, currentRegistrationFormId, documentId, _payload)
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}
