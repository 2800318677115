import { actions as uiActions } from '@nickel/stimulus/ui'

import { RegistrationControl, UpdateFontValidityPayload, ControlValidity } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import modals from './modals'

const control: Control = {
    actions: {
        invalidate: [
            {
                action: uiActions.showModal(modals.doubt),
                label: 'common:i-have-a-doubt'
            },
            {
                action: uiActions.showModal(modals.invalid),
                label: 'common:no'
            }
        ],
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.FONT,
                    controlPayload: {
                        validity: ControlValidity.VALID
                    } as UpdateFontValidityPayload
                }),
                label: 'common:yes'
            }
        ]
    },
    hasDocumentChecker: true
}

export default control
