import styled from 'styled-components'

import { FontWeight, Spacing } from '@nickel/ui/tokens'

const Container = styled.div`
    background-color: white;
    column-gap: ${Spacing.WIDE};
    display: flex;
    flex-direction: row;
    padding: ${Spacing.LARGE} 0 ${Spacing.LARGE} 0;
`

const ContainerFullName = styled.div`
    align-self: center;
    column-gap: 0;
`
const Civility = styled.span`
    font-weight: ${FontWeight.MEDIUM};
`

const Name = styled.span`
    font-weight: ${FontWeight.MEDIUM};
`

const Link = styled.a`
    &::before {
        color: black;
        content: '(';
    }

    &::after {
        color: black;
        content: ')';
    }
`

export default {
    Container,
    ContainerFullName,
    Civility,
    Name,
    Link
}
