import { buildStimulusType, DisplayType, setStimulusToUiDataMap, UiDataType } from '@nickel/stimulus/stimulus'

import { actions as navigationActions } from '../store/navigation'

import { TemplateType } from './templates'

export type ModalMessage = string | string[]

export type ModalData = UiDataType & {
    message?: string | [string, Record<string, unknown>]
    i18nContext?: string
    template?: {
        type?: string
        payload?: unknown
    }
    showCloseButton?: boolean
}

export const StimulusType = buildStimulusType({
    CONTROL_ERROR: 'CONTROL_ERROR',
    DETAILS_ERROR: 'DETAILS_ERROR',
    NICKEL_SERVICE_UNAVAIBLE: 'NICKEL_SERVICE_UNAVAIBLE',
    UNAUTHORIZED: 'UNAUTHORIZED'
})

const stimulusToUiDataMap = new Map()

stimulusToUiDataMap.set(StimulusType.ALL, () => ({
    displayTypes: [DisplayType.MODAL]
}))

stimulusToUiDataMap.set(StimulusType.CONTROL_ERROR, () => ({
    message: 'controls:errors.generic.message',
    primaryAction: {
        text: 'common:back',
        action: navigationActions.goBack()
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:errors.generic.title'
}))

stimulusToUiDataMap.set(StimulusType.DETAILS_ERROR, (stimulus: { data: ModalMessage }) => ({
    message: stimulus.data,
    primaryAction: { text: 'common:understood' },
    template: { type: TemplateType.DETAILS_ERROR },
    title: 'details:errors.title'
}))

stimulusToUiDataMap.set(StimulusType.DEFAULT, () => ({
    ...stimulusToUiDataMap.get(StimulusType.DEFAULT),
    primaryAction: { text: 'common:understood' }
}))

setStimulusToUiDataMap(stimulusToUiDataMap)
