import React, { useState, useCallback } from 'react'

import { range } from 'ramda'

import Input from '@nickel/ui/components/legacy/form/Input'

import Styled from './styles'

type Props = {
    addButtonDataTestId?: string
    maxFieldLength?: number
    maxFields?: number
    name: string
    onChange?: (value: string[]) => void
    removeButtonDataTestId?: string
    title?: string
    value: string[]
}

const Names = ({
    addButtonDataTestId,
    maxFieldLength,
    maxFields,
    name,
    onChange,
    removeButtonDataTestId,
    title,
    value
}: Props) => {
    const [lineCount, setLineCount] = useState(Math.max(1, value.length))

    const onInputChange = useCallback(
        (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(Object.assign([], value, { [index]: e.target.value.trimLeft() }))
        },
        [onChange, value]
    )

    const addLine = useCallback(() => {
        if (maxFields && maxFields > lineCount) {
            setLineCount(lineCount + 1)
        }
    }, [setLineCount, lineCount, maxFields])

    const removeLine = useCallback(
        (index: number) => () => {
            if (onChange) onChange([...value.slice(0, index), ...value.slice(index + 1)])

            setLineCount(Math.max(1, lineCount - 1))
        },
        [lineCount, onChange, setLineCount, value]
    )

    return (
        <Styled.Container>
            {title && <Styled.Title>{title}</Styled.Title>}
            {range(0, lineCount).map((index: number) => (
                <Styled.Row key={index}>
                    <Input
                        maxLength={maxFieldLength}
                        name={`${name}_${index}`}
                        onChange={onInputChange(index)}
                        value={value[index] ?? ''}
                    />
                    <Styled.RemoveIcon data-testid={`${removeButtonDataTestId}_${index}`} onClick={removeLine(index)} />
                </Styled.Row>
            ))}
            {(!maxFields || maxFields >= lineCount + 1) && (
                <Styled.AddIcon data-testid={addButtonDataTestId} onClick={addLine} />
            )}
        </Styled.Container>
    )
}

export default Names
