import React, { useMemo } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'

import OnfidoReport from '../../../components/OnfidoReport'
import { getDateFormat } from '../../../l10n/date-utils'
import { ComplianceStatus } from '../../../services/api'
import { Details } from '../../../store/details'

import Banner from './Banner'
import Status from './Status'
import Styled from './styles'

type Props = {
    details: Details
    onControlClick: () => void
    onValidateRegistration: () => void
}

const CustomerInfos = ({ details, onControlClick, onValidateRegistration }: Props) => {
    const {
        birthDate,
        complianceStatus,
        firstNames,
        lastNames,
        moderationTodoCauses,
        notCompliantCause,
        registrationCode,
        registrationDocuments,
        registrationFormPlatform,
        registrationFormType,
        sex
    } = details

    const { t } = useTranslation()

    const birthDateLabel = useMemo(
        () =>
            birthDate
                ? t('details:birth-date', {
                      context: sex === 'FEMALE' ? 'female' : '',
                      date: format(new Date(birthDate), getDateFormat())
                  })
                : null,
        [birthDate, sex, t]
    )
    const fullNameLabel = useMemo(() => `${firstNames?.join(' ') ?? ''} ${lastNames?.join(' ') ?? ''}`, [
        firstNames,
        lastNames
    ])

    const moderationCauses = moderationTodoCauses
        ?.map((moderationCause) => {
            const cause = t(`details:moderation-todo-causes.${moderationCause.moderationCause}`)
            if (moderationCause.score) {
                return `${cause} (${moderationCause.score}%)`
            }
            return cause
        })
        .join(', ')

    return (
        <Styled.Infos>
            <Styled.Column>
                {registrationFormType && <Banner platform={registrationFormPlatform} type={registrationFormType} />}
                {complianceStatus && <Status status={complianceStatus} />}
            </Styled.Column>
            <Styled.Column flexGrow="1">
                <Styled.Name data-testid="fullName">{fullNameLabel}</Styled.Name>
                {birthDateLabel && <Styled.InfoText data-testid="birthLabel">{birthDateLabel}</Styled.InfoText>}
                {moderationCauses && (
                    <Styled.InfoText data-testid="moderationCauses">
                        {t('details:moderation-todo-causes-label')}
                        {moderationCauses}
                    </Styled.InfoText>
                )}
            </Styled.Column>
            <Styled.RightSide>
                {complianceStatus === ComplianceStatus.NOT_COMPLIANT && (
                    <Button
                        testId="ValidateRegistrationButton"
                        onClick={onValidateRegistration}
                        text={t('details:update-compliant-status-button')}
                        variant="secondary"
                    />
                )}
                <Styled.Column>
                    {registrationCode && (
                        <Styled.InfoTextRegistrationCode data-testid="registrationFormCode">
                            {t('details:registration-code', { number: registrationCode })}
                        </Styled.InfoTextRegistrationCode>
                    )}
                    {complianceStatus === ComplianceStatus.TODO && (
                        <Styled.ControlButton
                            disabled={!registrationDocuments?.length}
                            onClick={onControlClick}
                            testId="beginControlButton"
                            text={t('common:control')}
                        />
                    )}
                    {complianceStatus === ComplianceStatus.NOT_COMPLIANT && (
                        <Styled.NotCompliantCause data-testid="detailNotComplianceCause">
                            {t([
                                `details:not-compliance-reason.${notCompliantCause}`,
                                'details:not-compliance-reason.UNDEFINED'
                            ])}
                        </Styled.NotCompliantCause>
                    )}
                    <Styled.ReportButton>
                        <OnfidoReport />
                    </Styled.ReportButton>
                </Styled.Column>
            </Styled.RightSide>
        </Styled.Infos>
    )
}

export default CustomerInfos
