import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { actions as controlsActions, selectors as controlSelectors } from '../..'
import { StimulusType } from '../../../../config/errors'
import modals from '../../../../controls/modals'
import { controlResourceApi } from '../../../../services'
import { selectors as navigationSelectors } from '../../../navigation'
import { http, isAlreadyKnownCustomer } from '../../../utils'

export function* control(postControlActionsConfirmed?: boolean): SagaIterator {
    const payload = yield select(controlSelectors.getBirthDataProperties)
    try {
        const currentRegistrationFormId: string | undefined = yield select(
            navigationSelectors.getCurrentRegistrationFormId
        )
        const documentId: string | undefined = yield select(controlSelectors.getDocumentId)

        yield call(
            http({ maxTries: 1 }),
            controlResourceApi.updateBirthData,
            currentRegistrationFormId,
            documentId,
            { ...payload, nationality: payload.nationalities[0] },
            postControlActionsConfirmed
        )
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        if (isAlreadyKnownCustomer(err)) {
            yield put(
                uiActions.showModal(
                    modals.createExistingCustomerModal({
                        registrationCode: err.response?.data.params.registrationCode,
                        existingCustomerRegistrationCode: err.response?.data.params.existingCustomerRegistrationCode
                    })
                )
            )
        } else {
            yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
        }
    }
}
