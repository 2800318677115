import styled from 'styled-components'

import { Spacing } from '@nickel/ui/tokens'

type DetailFilesProps = {
    count: number
}

const AvatarBtn = styled.button`
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    visibility: hidden;
    width: 2rem;
`

const AvatarBtnLeft = styled(AvatarBtn)`
    left: 0;
`
const AvatarBtnRight = styled(AvatarBtn)`
    right: 0;
`

const ImgContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    &:hover {
        ${AvatarBtnLeft}, ${AvatarBtnRight} {
            visibility: visible;
            z-index: 1;
        }
    }
`

const DetailFiles = styled.div<DetailFilesProps>`
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 60vh;
    text-align: center;
    width: 100%;

    ${ImgContainer}, video {
        display: flex;
        flex: 1;
        height: auto;
        margin: ${Spacing.MEDIUM};
        max-height: 60vh;
        width: auto;
    }

    video {
        max-width: calc(${(props) => 100 / props.count}% - ${Spacing.MEDIUM} * 2);
    }

    ${ImgContainer} {
        max-width: 20vw;
    }
`

export default {
    DetailFiles,
    ImgContainer,
    AvatarBtnLeft,
    AvatarBtnRight
}
