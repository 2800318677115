import React, { useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Names from '../../components/Names'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'
import { RootState } from '../../store/rootReducer'

import FirstNameAlert from './modals'

const MAX_FIELDS_COUNT = 3

const Form = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const firstNames = useSelector<RootState, string[] | undefined>(controlsSelectors.getFirstNames)
    useEffect(
        () => {
            dispatch(
                controlsActions.setCivilDataBeforeEdit({
                    firstNames
                })
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const createSetFirstName = useCallback(
        (names: string[]) => {
            if (isOwner) {
                dispatch(controlsActions.setFirstNamesOwner({ firstNamesOwner: names }))
            } else {
                dispatch(controlsActions.setFirstNamesGuardian({ firstNamesGuardian: names }))
            }
        },
        [dispatch, isOwner]
    )

    return (
        <>
            <FirstNameAlert />
            <Names
                addButtonDataTestId="AddFirstName"
                name="firstName"
                maxFields={MAX_FIELDS_COUNT}
                onChange={createSetFirstName}
                removeButtonDataTestId="RemoveFirstName"
                title={t('controls:FIRST_NAMES.firstNames')}
                value={firstNames ?? []}
            />
        </>
    )
}

export default Form
