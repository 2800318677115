import React, { memo, useMemo } from 'react'

import useBoAuthentication from '@nickel/bo-authentication/hooks/useBoAuthentication'
import i18n from '@nickel/i18n'

import MicroFrontendLoader from '../../config/MicroFrontendLoader'

const Header = () => {
    const { givenName, familyName, roles, logout } = useBoAuthentication()
    const props = useMemo(
        () => ({ hasL10NSelector: true, i18n, logout, roles, username: `${givenName} ${familyName}` }),
        [logout, roles, givenName, familyName]
    )

    return (
        <>
            {roles && roles.length > 0 && (
                <MicroFrontendLoader host={window.REACT_APP_BO_MENU_MF_URL} id="mf-bo-menu" mfProps={props} />
            )}
        </>
    )
}

export default memo(Header)
