import styled from 'styled-components'

import { Spacing } from '@nickel/ui/tokens'

const Container = styled.div`
    align-content: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
`

const ControlButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > :not(:last-child) {
        margin-right: ${Spacing.LARGE};
    }
`

export default {
    Container,
    ControlButtons
}
