import React, { ChangeEvent, useCallback, useMemo } from 'react'

import { defaultTo, filter, pipe } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import CopyInput from '../../components/CopyInput'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'

import Styled from './styles'

const MIN_LENGTH = 3

const _defaultTo: <T>(d: T[]) => (v: T[]) => T[] = defaultTo

const padEnd = (array: string[]) => [...array, ...new Array(MIN_LENGTH - array.length).fill('')]

const Form = () => {
    const dispatch = useDispatch()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const mrzLines = useSelector(controlsSelectors.getMrzLines)
    const mrzLinesSanitized = useMemo(
        () => pipe(_defaultTo<string>(['']), filter(Boolean), padEnd)(mrzLines),
        [mrzLines]
    )

    const onChangeHandler = useCallback(
        (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
            const _mrzLines = [...mrzLinesSanitized]
            _mrzLines[index] = event.target.value.trim()
            if (isOwner) {
                dispatch(controlsActions.setMrzLinesOwner({ mrzLinesOwner: _mrzLines }))
            } else {
                dispatch(controlsActions.setMrzLinesGuardian({ mrzLinesGuardian: _mrzLines }))
            }
        },
        [dispatch, isOwner, mrzLinesSanitized]
    )

    return (
        <div>
            {mrzLinesSanitized.map((mrzLine: string, index: number) => (
                <Styled.MrzInput key={String(index)}>
                    <CopyInput name={`mrzLine_${index}`} value={mrzLine} onChange={onChangeHandler(index)} />
                </Styled.MrzInput>
            ))}
            <iframe
                height="280"
                src={window.REACT_APP_MRZ_CHECKER_URL}
                title="Document checker"
                width="100%"
                frameBorder="none"
            />
        </div>
    )
}

export default Form
