import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { COUNTRY_CODE } from '@nickel/l10n/constants'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

import { RegistrationDocumentType } from '../../services/api'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'

const Form = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const issuingCountry = useSelector(controlsSelectors.getIssuingCountry)
    const storedDocumentProperties = useSelector(controlsSelectors.getDocumentProperties)
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const storedDocumentNumber = useSelector(controlsSelectors.getDocumentNumber)
    const storedPersonalNumber = useSelector(controlsSelectors.getPersonalNumber)

    const [documentNumber, setDocumentNumber] = useState(storedDocumentNumber)
    const [personalNumber, setPersonalNumber] = useState(storedPersonalNumber)

    const onDocumentNumberChange = useCallback((event) => setDocumentNumber(event?.target.value), [setDocumentNumber])
    const onPersonalNumberChange = useCallback((event) => setPersonalNumber(event?.target.value), [setPersonalNumber])

    useEffect(() => {
        if (isOwner) {
            dispatch(
                controlsActions.setDocumentNumberOwner({
                    documentNumberOwnerData: { documentNumber, personalNumber }
                })
            )
        } else {
            dispatch(
                controlsActions.setDocumentNumberGuardian({
                    documentNumberGuardianData: { documentNumber, personalNumber }
                })
            )
        }
    }, [dispatch, isOwner, documentNumber, personalNumber])

    const displayFiscalNumber = useMemo(() => {
        const { type } = storedDocumentProperties || {}
        const isNicOrResidencePermit =
            type &&
            (type === RegistrationDocumentType.NATIONAL_IDENTITY_CARD ||
                type === RegistrationDocumentType.RESIDENCE_PERMIT)
        return (issuingCountry === COUNTRY_CODE.PT || issuingCountry === COUNTRY_CODE.BE) && isNicOrResidencePermit
    }, [issuingCountry, storedDocumentProperties])

    return (
        <div>
            {issuingCountry === COUNTRY_CODE.ES && (
                <Field htmlFor="personal-number" label={t('controls:DOCUMENT_NUMBER.personal-number')}>
                    <Input name="personal-number" onChange={onPersonalNumberChange} value={personalNumber} />
                </Field>
            )}
            {displayFiscalNumber && (
                <Field htmlFor="personal-number" label={t('controls:DOCUMENT_NUMBER.fiscal-number')}>
                    <Input name="personal-number" onChange={onPersonalNumberChange} value={personalNumber} />
                </Field>
            )}
            <Field htmlFor="document-number" label={t('controls:DOCUMENT_NUMBER.document-number')}>
                <Input name="document-number" onChange={onDocumentNumberChange} value={documentNumber} />
            </Field>
        </div>
    )
}

export default Form
