export enum ROUTES {
    CONTROL = 'control',
    DETAILS = 'details',
    HOME = '/'
}

export const PATHS = {
    CONTROL: `/${ROUTES.DETAILS}/:registrationFormId/${ROUTES.CONTROL}`,
    DETAILS: `/${ROUTES.DETAILS}/:registrationFormId`,
    HOME: '/'
}
