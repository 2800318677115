import React, { useEffect, useState } from 'react'

import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getB64FromLocalForage } from '@nickel/utils/lib/file'

import { selectors as documentsSelectors, actions as documentsActions } from '../../store/documents'
import GridImage from '../GridImage'
import { ImageParts } from '../GridImage/GridImage'

import { NewWindowDocument } from './NewWindowDocument'
import { rotateImageParts } from './utils'

type Props = {
    forageKey: string
    rotateDegrees?: number
    testId: string
    fileId: string
}

const AsyncImage = ({ forageKey, testId, rotateDegrees = 0, fileId }: Props) => {
    const [imageParts, setImageParts] = useState<ImageParts>()
    const openedDocuments = useSelector(documentsSelectors.getOpenedDocumentsInExternalWindow)
    const externalWindow = openedDocuments[fileId]
    const dispatch = useDispatch()

    useEffect(() => {
        const rotateImage = async () => {
            const storedImageParts = await getB64FromLocalForage(forageKey)
            if (!storedImageParts) return
            const rotatedImageParts = await rotateImageParts(JSON.parse(storedImageParts), rotateDegrees)
            setImageParts(rotatedImageParts)
        }
        rotateImage()
    }, [forageKey, rotateDegrees, setImageParts])

    useEffect(() => {
        if (externalWindow) {
            externalWindow.addEventListener('beforeunload', () => {
                dispatch(documentsActions.setOpenedDocumentInExternalWindow({ [fileId]: null }))
            })
        }
        window.addEventListener('beforeunload', () => {
            Object.values(openedDocuments).forEach((windowToClose) => windowToClose?.close())
        })
    }, [dispatch, externalWindow, fileId, openedDocuments])
    const handleOpenNewWindow = () => {
        if (!openedDocuments[fileId] && !!imageParts) {
            const newWindowRef = window.open()
            ReactDOM.render(
                <NewWindowDocument imageParts={imageParts} newWindowRef={newWindowRef} testId={testId} />,
                document.createDocumentFragment()
            )
            dispatch(documentsActions.setOpenedDocumentInExternalWindow({ [fileId]: newWindowRef }))
        }
    }

    return imageParts ? <GridImage gridImage={imageParts} testId={testId} onClick={handleOpenNewWindow} /> : null
}

export default AsyncImage
