import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { ControlWarningView } from '../../services/api'

import Styled from './styles'

type Props = {
    warnings: ControlWarningView[]
}

const ControlWarnings = ({ warnings }: Props) => {
    const { t } = useTranslation()

    const warningsList = useMemo(() => warnings.map((warning) => t(`controls:warnings.${warning.code}`)).join(', '), [
        t,
        warnings
    ])
    const title = useMemo(() => {
        if (warnings.some((warning) => warning.code?.startsWith('moderationTodoCause'))) {
            return 'controls:moderation-todo-cause-detected-warnings'
        }
        return 'controls:automatic-analysis-detected-warnings'
    }, [warnings])
    return (
        <Styled.Container testId="ControlWarning">
            {t(title)}
            <span>{warningsList}</span>
        </Styled.Container>
    )
}

export default ControlWarnings
