import axios from 'axios'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { actions as controlsActions, selectors as controlSelectors } from '../..'
import { StimulusType } from '../../../../config/errors'
import modals from '../../../../controls/ISSUING_COUNTRY/modals'
import { controlResourceApi } from '../../../../services'
import { selectors as navigationSelectors } from '../../../navigation'
import { hasMessage, http } from '../../../utils'

import { NEED_MANUAL_CONFIRMATION_ERROR } from './constants'

export function* control(postControlActionsConfirmed?: boolean): SagaIterator {
    try {
        const issuingCountry = yield select(controlSelectors.getIssuingCountry)
        const currentRegistrationFormId = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId = yield select(controlSelectors.getDocumentId)
        const payload = { issuingCountry }
        yield call(
            http({ maxTries: 1 }),
            controlResourceApi.updateIssuingCountry,
            currentRegistrationFormId,
            documentId,
            payload,
            postControlActionsConfirmed
        )
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        if (
            axios.isAxiosError(err) &&
            err.response?.status === NEED_MANUAL_CONFIRMATION_ERROR.status &&
            hasMessage(err.response, NEED_MANUAL_CONFIRMATION_ERROR.message)
        ) {
            yield put(uiActions.showModal(modals.invalidate))
        } else yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}
