import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { StimulusType } from '../../config/errors'
import modals from '../../controls/modals'
import { controlResourceApi } from '../../services'
import { UpdateLastNamesPayload, UpdateLastNamesPayloadModificationReasonsEnum } from '../../services/api'
import { actions as controlsActions, selectors as controlSelectors } from '../controls'
import { selectors as navigationSelectors } from '../navigation'
import { http, isAlreadyKnownCustomer } from '../utils'

import { actions as lastNamesActions, selectors as lastNamesSelectors } from '.'

/**
 * Soumet l'écran et passe à l'étape suivate
 * @param payload contient les données provenant de la vue
 */
export function* submitControls(payload: UpdateLastNamesPayload): SagaIterator {
    try {
        const currentRegistrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId: string = yield select(controlSelectors.getDocumentId)
        const popinState = yield select(lastNamesSelectors.getPopinState)
        if (popinState) {
            yield put(lastNamesActions.openCloseControlPopin())
        }
        yield call(
            http({ maxTries: 1 }),
            controlResourceApi.updateLastNames,
            currentRegistrationFormId,
            documentId,
            payload
        )
        yield call(http(), controlResourceApi.validateOcrSurnames, currentRegistrationFormId, documentId)
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        if (isAlreadyKnownCustomer(err)) {
            yield put(
                uiActions.showModal(
                    modals.createExistingCustomerModal({
                        registrationCode: err.response?.data.params.registrationCode,
                        existingCustomerRegistrationCode: err.response?.data.params.existingCustomerRegistrationCode
                    })
                )
            )
        } else {
            yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
        }
    }
}

/**
 * Soumet les raisons de modification et passe à l'écran suivant
 */
export function* submitModificationReasonAndEndScreen(): SagaIterator {
    const lastNames: string[] = yield select(controlSelectors.getLastNames)
    const marriedLastName: string = yield select(controlSelectors.getMarriedLastName)
    const preferredLastName: string = yield select(controlSelectors.getPreferredLastName)
    const modificationReasons: UpdateLastNamesPayloadModificationReasonsEnum[] = yield select(
        lastNamesSelectors.getModificationReason
    )
    if (modificationReasons.length > 0) {
        const payload: UpdateLastNamesPayload = { lastNames, marriedLastName, preferredLastName, modificationReasons }
        yield put(uiActions.hideModal())
        yield call(submitControls, payload)
    }
}

export default function* sagas() {
    yield all([takeLatest(lastNamesActions.popinValidation, submitModificationReasonAndEndScreen)])
}
