import React from 'react'

import { useTranslation } from 'react-i18next'

import BuildingIcon from '@nickel/ui/components/icons/Building'
import ComputerScreenIcon from '@nickel/ui/components/icons/ComputerScreen'

import { RegistrationFormPlatform, RegistrationFormType } from '../../../services/api'

import Styled from './styles'

type Props = {
    platform?: RegistrationFormPlatform
    type: RegistrationFormType
}

const Banner = ({ platform, type }: Props) => {
    const { t } = useTranslation()

    return (
        <Styled.Banner registrationFormType={type} data-testid="formType">
            {t(`common:registration-form-types.${type}`)}
            {platform && (platform === RegistrationFormPlatform.TERMINAL ? <BuildingIcon /> : <ComputerScreenIcon />)}
        </Styled.Banner>
    )
}

export default Banner
