import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { addLibTranslations as addCoreUiTranslations } from '@nickel/core-ui/lib/i18n'
import i18n, { addTranslations, Dictionnary } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { addLibTranslations as addKycTranslations } from '@nickel/kyc/lib/i18n'
import { setGlobalISO2 } from '@nickel/l10n/config'
import { addLibTranslations as addL10nTranslations } from '@nickel/l10n/lib/i18n'
import { addLibTranslation as addLibUiTranslations } from '@nickel/stimulus'

import getISO2CountryCodeFromCurrentLng from '../l10n/utils'

import deDE from './languages/de_DE'
import enGB from './languages/en_GB'
import enUS from './languages/en_US'
import esES from './languages/es_ES'
import frBE from './languages/fr_BE'
import frFR from './languages/fr_FR'
import nlBE from './languages/nl_BE'
import ptPT from './languages/pt_PT'
import root from './languages/root'

const dictionnary: Dictionnary = {
    [SupportedLocale.DE_DE]: deDE,
    [SupportedLocale.EN_GB]: enGB,
    [SupportedLocale.EN_US]: enUS,
    [SupportedLocale.ES_ES]: esES,
    [SupportedLocale.FR_BE]: frBE,
    [SupportedLocale.FR_FR]: frFR,
    [SupportedLocale.NL_BE]: nlBE,
    [SupportedLocale.PT_PT]: ptPT,
    root
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'root',
        returnNull: false
    })
    .then(() => {
        setGlobalISO2(getISO2CountryCodeFromCurrentLng())
    })

addTranslations(dictionnary)
addKycTranslations()
addL10nTranslations()
addLibUiTranslations()
addCoreUiTranslations()

export default i18n
