import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { actions as uiActions } from '@nickel/stimulus/ui'
import LibUiGlobalStyle from '@nickel/ui/components/config/GlobalStyle'
import ExportIcon from '@nickel/ui/components/icons/Export'
import Button from '@nickel/ui/components/legacy/Button'

import { isControl, isDetails } from '../navigation/utils'
import { selectors as navigationSelectors } from '../store/navigation'
import Control from '../views/Control'
import Detail from '../views/Detail'
import RegistrationFormList from '../views/RegistrationFormList'
import SearchBar from '../views/RegistrationFormList/SearchBar'

import Header from './Header'
import { exportActivityReport } from './modals'
import Styled from './styles'

const Layout = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()

    const isHomeDisplayed = useSelector(navigationSelectors.getIsHomeDisplayed)

    const [isControlDisplayed, setIsControlDisplayed] = useState(false)

    const onActivityReportClick = useCallback(() => {
        dispatch(uiActions.showModal(exportActivityReport))
    }, [dispatch])

    useEffect(() => {
        if (isControl(location.pathname)) setIsControlDisplayed(true)
        else if (isDetails(location.pathname)) setIsControlDisplayed(false)
    }, [location, setIsControlDisplayed])

    return (
        <>
            <LibUiGlobalStyle />
            <Styled.Global />
            <Header />
            <Styled.Content>
                <Styled.LeftSection collapsed={!isHomeDisplayed}>
                    <Styled.TitleContainer>
                        <Styled.Title data-testid="headerTitle">{t('header:title')}</Styled.Title>
                        <Button
                            testId="activityReportButton"
                            Icon={ExportIcon}
                            onClick={onActivityReportClick}
                            text={t('header:activity-report')}
                            variant="secondary"
                        />
                    </Styled.TitleContainer>
                    <SearchBar />
                    <RegistrationFormList />
                </Styled.LeftSection>
                <Styled.RightSection collapsed={isHomeDisplayed}>
                    {isControlDisplayed ? <Control /> : <Detail />}
                </Styled.RightSection>
            </Styled.Content>
        </>
    )
}

export default Layout
