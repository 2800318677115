import React, { useCallback } from 'react'

import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { actions as uiActions } from '@nickel/stimulus/ui'
import Pender from '@nickel/ui/components/legacy/Pender'

import DocumentsList from '../../components/DocumentsList'
import { confirmCompliantStatus } from '../../layout/modals'
import { ComplianceStatus } from '../../services/api'
import { selectors as detailsSelectors } from '../../store/details'
import { selectors as documentsSelectors, actions as documentsActions } from '../../store/documents'
import { selectors as navigationSelectors } from '../../store/navigation'
import { actions as registrationFormsActions } from '../../store/registrationForms'

import CustomerInfos from './CustomerInfos'
import Styled from './styles'

const Detail = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const registrationFormId = useSelector(navigationSelectors.getCurrentRegistrationFormId)
    const details = useSelector(detailsSelectors.get)
    const isFetching = useSelector(detailsSelectors.getIsFetching)
    const hasGuardianDocuments = useSelector(detailsSelectors.getHasGuardianDocuments)
    const ownerDocumentsFiles = useSelector(detailsSelectors.getOwnerDocumentsFiles)
    const guardianDocumentsFiles = useSelector(detailsSelectors.getGuardianDocumentsFiles)
    const documentRotateDegrees = useSelector(documentsSelectors.getDocumentFileConfigurationRotateDegrees)

    const onControlClick = useCallback(
        () => dispatch(registrationFormsActions.controlCustomer({ registrationFormId })),
        [dispatch, registrationFormId]
    )

    const onValidateRegistration = useCallback(() => {
        if (registrationFormId)
            dispatch(uiActions.showModal(confirmCompliantStatus(registrationFormId, ComplianceStatus.COMPLIANT)))
    }, [dispatch, registrationFormId])

    const onRotateDocument = useCallback(
        (id: string, rotateDegrees: number) => {
            dispatch(
                documentsActions.setDocumentFileConfigurationRotateDegrees({
                    [id]: rotateDegrees
                })
            )
        },
        [dispatch]
    )

    return (
        <Styled.Container>
            {isFetching || isEmpty(details) ? (
                <>
                    <h1>{t('details:title-loading')}</h1>
                    <Pender width={50} />
                </>
            ) : (
                <Styled.Details>
                    <CustomerInfos
                        key="owner_infos"
                        details={details}
                        onControlClick={onControlClick}
                        onValidateRegistration={onValidateRegistration}
                    />
                    <DocumentsList
                        files={ownerDocumentsFiles}
                        key="owner_documents"
                        titleKey="details:documents"
                        onRotateDocument={onRotateDocument}
                        rotateDegrees={documentRotateDegrees}
                        {...(hasGuardianDocuments && { titleContext: 'child' })}
                    />
                    {hasGuardianDocuments && (
                        <DocumentsList
                            files={guardianDocumentsFiles}
                            onRotateDocument={onRotateDocument}
                            rotateDegrees={documentRotateDegrees}
                            titleKey="details:documents-guardian"
                        />
                    )}
                </Styled.Details>
            )}
        </Styled.Container>
    )
}

export default Detail
