import activityReport from './activity-report.json'
import common from './common.json'
import controls from './controls.json'
import details from './details.json'
import header from './header.json'
import home from './home.json'

export default {
    'activity-report': activityReport,
    common,
    controls,
    details,
    header,
    home
}
