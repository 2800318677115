import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Select from '@nickel/ui/components/legacy/form/Select'

import {
    actions as registrationFormsActions,
    selectors as registrationFormsSelectors
} from '../../../store/registrationForms'
import { RegistrationFormComplianceStatus, RegistrationFormComplianceStatusValues } from '../types'

const ComplianceStatusFilter = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const storedValue = useSelector(registrationFormsSelectors.getSearchComplianceStatus)

    const onChange = useCallback(
        (value: RegistrationFormComplianceStatusValues) => {
            dispatch(registrationFormsActions.setComplianceStatus(value))
        },
        [dispatch]
    )
    const options = Object.values(RegistrationFormComplianceStatus).map((value) => ({
        value,
        label: t(`common:${value ? `compliance-status.${value}` : 'all'}`)
    }))

    return (
        <Select
            light
            name="compliance-status-select"
            onChange={onChange}
            options={options}
            placeholder={t('home:compliance-status-filter')}
            label={t('home:compliance-status-filter')}
            value={storedValue}
        />
    )
}

export default ComplianceStatusFilter
