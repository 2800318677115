import React, { ChangeEvent, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ArrowLeftIcon from '@nickel/ui/components/icons/ArrowLeft'
import SearchIcon from '@nickel/ui/components/icons/Search'
import Input from '@nickel/ui/components/legacy/form/Input'
import IconAdornment from '@nickel/ui/components/legacy/form/Input/adornments/Icon'

import { selectors as navigationSelectors } from '../../../store/navigation'
import {
    actions as registrationFormsActions,
    selectors as registrationFormsSelectors
} from '../../../store/registrationForms'

const ArrowLeftIconAdornment = () => <IconAdornment Icon={ArrowLeftIcon} right />
const SearchIconAdornment = () => <IconAdornment Icon={SearchIcon} right light />

const SearchInput = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isHomeDisplayed = useSelector(navigationSelectors.getIsHomeDisplayed)
    const value = useSelector(registrationFormsSelectors.getSearchQuery) ?? ''

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(registrationFormsActions.setSearchQuery(event.target.value))
        },
        [dispatch]
    )

    return (
        <Input
            name="search-query"
            onChange={onChange}
            placeholder={t('home:search-input-placeholder')}
            label={t('home:search-input-placeholder')}
            RightAdornment={isHomeDisplayed ? SearchIconAdornment : ArrowLeftIconAdornment}
            value={value}
        />
    )
}

export default SearchInput
