import { ModalData } from '../config/errors'
import { TemplateType } from '../config/templates'
import i18n from '../i18n'
import { actions as controlsActions } from '../store/controls'

type CustomerExistingErrorPayload = {
    registrationCode: string
    existingCustomerRegistrationCode: string
}

function createExistingCustomerModal(payload: CustomerExistingErrorPayload): ModalData {
    const message = i18n.t('controls:modals.existing-customer.message', payload)
    return {
        message,
        primaryAction: {
            action: controlsActions.getNextControlAsyncRequest(),
            text: 'common:understood'
        },
        template: { type: TemplateType.ALREADY_KNOWN_CUSTOMER },
        title: 'controls:modals.existing-customer.title'
    }
}

export default {
    createExistingCustomerModal
}
