/* tslint:disable */
/* eslint-disable */
/**
 * customer-registration-control-api
 * API BFF for registration control
 *
 * The version of the OpenAPI document: v1.0.0
 * Contact: team-onboarders@nickel.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccountStakeholder = {
    OWNER: 'OWNER',
    GUARDIAN: 'GUARDIAN'
} as const;

export type AccountStakeholder = typeof AccountStakeholder[keyof typeof AccountStakeholder];


/**
 * 
 * @export
 * @interface AddMrzLinesResultPayload
 */
export interface AddMrzLinesResultPayload {
    /**
     * 
     * @type {ControlValidity}
     * @memberof AddMrzLinesResultPayload
     */
    'mrzLinesValidity': ControlValidity;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddMrzLinesResultPayload
     */
    'mrzLines'?: Array<string>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationVersion = {
    LEGACY: 'LEGACY',
    INTER: 'INTER'
} as const;

export type ApplicationVersion = typeof ApplicationVersion[keyof typeof ApplicationVersion];

/**
 *
 * @export
 * @interface CustomerView
 */
export interface CustomerView {
    /**
     *
     * @type {number}
     * @memberof CustomerView
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface BirthDataView
 */
export interface BirthDataView {
    /**
     * 
     * @type {string}
     * @memberof BirthDataView
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BirthDataView
     */
    'birthCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof BirthDataView
     */
    'birthPlace'?: string;
    /**
     * 
     * @type {string}
     * @memberof BirthDataView
     */
    'birthRegion'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChipPresence = {
    COMPLIANT: 'COMPLIANT',
    NOT_COMPLIANT: 'NOT_COMPLIANT',
    DOUBT: 'DOUBT'
} as const;

export type ChipPresence = typeof ChipPresence[keyof typeof ChipPresence];


/**
 * 
 * @export
 * @interface CivilDataView
 */
export interface CivilDataView {
    /**
     * 
     * @type {Sex}
     * @memberof CivilDataView
     */
    'sex'?: Sex;
    /**
     * 
     * @type {Array<string>}
     * @memberof CivilDataView
     */
    'firstNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CivilDataView
     */
    'lastNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CivilDataView
     */
    'marriedName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CivilDataView
     */
    'preferredLastName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CivilDataView
     */
    'nationalities'?: Array<string>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ComplianceStatus = {
    TODO: 'TODO',
    COMPLIANT: 'COMPLIANT',
    NOT_COMPLIANT: 'NOT_COMPLIANT',
    DETERMINING: 'DETERMINING'
} as const;

export type ComplianceStatus = typeof ComplianceStatus[keyof typeof ComplianceStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ControlValidity = {
    VALID: 'VALID',
    INVALID: 'INVALID'
} as const;

export type ControlValidity = typeof ControlValidity[keyof typeof ControlValidity];


/**
 * 
 * @export
 * @interface ControlWarningListView
 */
export interface ControlWarningListView {
    /**
     * 
     * @type {Array<ControlWarningView>}
     * @memberof ControlWarningListView
     */
    'warnings'?: Array<ControlWarningView>;
}
/**
 * 
 * @export
 * @interface ControlWarningView
 */
export interface ControlWarningView {
    /**
     * 
     * @type {string}
     * @memberof ControlWarningView
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW'
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];


/**
 * 
 * @export
 * @enum {string}
 */

export const DoubtableValidity = {
    VALID: 'VALID',
    DOUBT: 'DOUBT',
    INVALID: 'INVALID'
} as const;

export type DoubtableValidity = typeof DoubtableValidity[keyof typeof DoubtableValidity];


/**
 * 
 * @export
 * @interface DoubtableValidityPayload
 */
export interface DoubtableValidityPayload {
    /**
     * 
     * @type {DoubtableValidity}
     * @memberof DoubtableValidityPayload
     */
    'validity': DoubtableValidity;
}


/**
 * 
 * @export
 * @interface FacialCaptureListView
 */
export interface FacialCaptureListView {
    /**
     * 
     * @type {Array<FacialCaptureView>}
     * @memberof FacialCaptureListView
     */
    'facialCaptures'?: Array<FacialCaptureView>;
}
/**
 * 
 * @export
 * @interface FacialCaptureView
 */
export interface FacialCaptureView {
    /**
     * 
     * @type {string}
     * @memberof FacialCaptureView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacialCaptureView
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacialCaptureView
     */
    'mimeType'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FamilyDocumentReadability = {
    READABLE: 'READABLE',
    NOT_READABLE: 'NOT_READABLE'
} as const;

export type FamilyDocumentReadability = typeof FamilyDocumentReadability[keyof typeof FamilyDocumentReadability];


/**
 * 
 * @export
 * @enum {string}
 */

export const FamilyDocumentType = {
    FAMILY_REGISTER: 'FAMILY_REGISTER',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    JUDGEMENT: 'JUDGEMENT',
    OTHER: 'OTHER'
} as const;

export type FamilyDocumentType = typeof FamilyDocumentType[keyof typeof FamilyDocumentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const IdentityDocumentReadability = {
    READABLE: 'READABLE',
    NOT_READABLE: 'NOT_READABLE'
} as const;

export type IdentityDocumentReadability = typeof IdentityDocumentReadability[keyof typeof IdentityDocumentReadability];


/**
 * 
 * @export
 * @enum {string}
 */

export const IdentityDocumentType = {
    PASSPORT: 'PASSPORT',
    NATIONAL_IDENTITY_CARD: 'NATIONAL_IDENTITY_CARD',
    RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
    FAMILY_REGISTER: 'FAMILY_REGISTER',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    JUDGEMENT: 'JUDGEMENT',
    OTHER: 'OTHER'
} as const;

export type IdentityDocumentType = typeof IdentityDocumentType[keyof typeof IdentityDocumentType];


/**
 * 
 * @export
 * @interface ImagePartsView
 */
export interface ImagePartsView {
    /**
     * 
     * @type {ImageSizeView}
     * @memberof ImagePartsView
     */
    'size'?: ImageSizeView;
    /**
     * 
     * @type {number}
     * @memberof ImagePartsView
     */
    'nbRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImagePartsView
     */
    'nbColumns'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImagePartsView
     */
    'data'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ImageSizeView
 */
export interface ImageSizeView {
    /**
     * 
     * @type {number}
     * @memberof ImageSizeView
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageSizeView
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    'last'?: string;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    'prev'?: string;
    /**
     * 
     * @type {string}
     * @memberof Links
     */
    'self'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ModerationCause = {
    FALLBACK_BECAUSE_TECHNICAL_ERROR: 'FALLBACK_BECAUSE_TECHNICAL_ERROR',
    USER_INPUT_DOES_NOT_MATCH_OCR: 'USER_INPUT_DOES_NOT_MATCH_OCR',
    ONFIDO_CHECK_FAILED: 'ONFIDO_CHECK_FAILED',
    SPANISH_RESIDENCE_PERMIT_USED: 'SPANISH_RESIDENCE_PERMIT_USED',
    CUSTOMER_PROBABLY_ALREADY_KNOWN: 'CUSTOMER_PROBABLY_ALREADY_KNOWN',
    CLOSING_REASON_WAS_SCO_REJECTED_DOC: 'CLOSING_REASON_WAS_SCO_REJECTED_DOC',
    REGISTRATION_ES_WITH_PASSPORT: 'REGISTRATION_ES_WITH_PASSPORT',
    CUSTOMER_DID_NOT_CONSENT_TO_BIOMETRIC_PROCESSING: 'CUSTOMER_DID_NOT_CONSENT_TO_BIOMETRIC_PROCESSING',
    SUSPICIOUS_IDENTITY_CARD: 'SUSPICIOUS_IDENTITY_CARD',
    SPOOFING_SELFIE: 'SPOOFING_SELFIE',
    QUALITY_SELFIE: 'QUALITY_SELFIE',
    USURPATION_SELFIE: 'USURPATION_SELFIE',
    CUSTOMER_MODIFY_TAX_ID: 'CUSTOMER_MODIFY_TAX_ID',
    BANNED_DOCUMENT: 'BANNED_DOCUMENT',
    FORBIDDEN_ISSUING_COUNTRY: 'FORBIDDEN_ISSUING_COUNTRY',
    REGISTRATION_PT_WITH_BR_PASSPORT: 'REGISTRATION_PT_WITH_BR_PASSPORT',
    NATIONALITY_DIFFERENT_FROM_OCR: 'NATIONALITY_DIFFERENT_FROM_OCR',
    PASSPORT_WITHOUT_SIGNATURE: 'PASSPORT_WITHOUT_SIGNATURE',
    DOCUMENT_NUMBER_PROBABLY_ALREADY_KNOWN: 'DOCUMENT_NUMBER_PROBABLY_ALREADY_KNOWN',
    YOUNG_SUBSCRIPTION: 'YOUNG_SUBSCRIPTION'
} as const;

export type ModerationCause = typeof ModerationCause[keyof typeof ModerationCause];


/**
 * 
 * @export
 * @interface ModerationTodoCauseView
 */
export interface ModerationTodoCauseView {
    /**
     * 
     * @type {ModerationCause}
     * @memberof ModerationTodoCauseView
     */
    'moderationCause'?: ModerationCause;
    /**
     * 
     * @type {string}
     * @memberof ModerationTodoCauseView
     */
    'score'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NameModificationReason = {
    INATTENTIVENESS_ERROR: 'INATTENTIVENESS_ERROR',
    MARITAL_NAME_INVERSION: 'MARITAL_NAME_INVERSION',
    USAGE_NAME_INVERSION: 'USAGE_NAME_INVERSION',
    LAST_NAME_FIRST_NAME_SAME_INPUT: 'LAST_NAME_FIRST_NAME_SAME_INPUT',
    OTHER: 'OTHER',
    SECOND_THIRD_FIRST_NAME_ERROR: 'SECOND_THIRD_FIRST_NAME_ERROR'
} as const;

export type NameModificationReason = typeof NameModificationReason[keyof typeof NameModificationReason];


/**
 * 
 * @export
 * @interface NextControlView
 */
export interface NextControlView {
    /**
     * 
     * @type {string}
     * @memberof NextControlView
     */
    'registrationDocumentId'?: string;
    /**
     * 
     * @type {RegistrationControl}
     * @memberof NextControlView
     */
    'control'?: RegistrationControl;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotCompliantCause = {
    FRAUDE_IDENTITAIRE: 'FRAUDE_IDENTITAIRE',
    CNI_ENFANT_ILLISIBLE: 'CNI_ENFANT_ILLISIBLE',
    CNI_PHOTOCOPIE: 'CNI_PHOTOCOPIE',
    DOCUMENT_IDENTITE_NON_ACCEPTE_REFUS_OUVERTURE: 'DOCUMENT_IDENTITE_NON_ACCEPTE_REFUS_OUVERTURE',
    MAUVAISE_QUALITE_SCAN: 'MAUVAISE_QUALITE_SCAN',
    LIVRET_DE_FAMILLE_PHOTOCOPIE: 'LIVRET_DE_FAMILLE_PHOTOCOPIE',
    LIVRET_DE_FAMILLE_ILLISIBLE: 'LIVRET_DE_FAMILLE_ILLISIBLE',
    PASSPORT_PAYS_INTERDIT: 'PASSPORT_PAYS_INTERDIT',
    CNI_PAYS_INTERDIT: 'CNI_PAYS_INTERDIT',
    TITRE_DE_SEJOUR_PAYS_INTERDIT: 'TITRE_DE_SEJOUR_PAYS_INTERDIT',
    MRZ_NON_VALIDE: 'MRZ_NON_VALIDE',
    CUSTOMER_DEJA_CONNU: 'CUSTOMER_DEJA_CONNU',
    MAUVAISE_QUALITE_SELFIE: 'MAUVAISE_QUALITE_SELFIE'
} as const;

export type NotCompliantCause = typeof NotCompliantCause[keyof typeof NotCompliantCause];


/**
 * 
 * @export
 * @interface NotCompliantCauseCountView
 */
export interface NotCompliantCauseCountView {
    /**
     * 
     * @type {NotCompliantCause}
     * @memberof NotCompliantCauseCountView
     */
    'notCompliantCause'?: NotCompliantCause;
    /**
     * 
     * @type {number}
     * @memberof NotCompliantCauseCountView
     */
    'count'?: number;
}


/**
 * 
 * @export
 * @interface NotCompliantCauseDistributionView
 */
export interface NotCompliantCauseDistributionView {
    /**
     * 
     * @type {Array<NotCompliantCauseCountView>}
     * @memberof NotCompliantCauseDistributionView
     */
    'data'?: Array<NotCompliantCauseCountView>;
    /**
     * 
     * @type {number}
     * @memberof NotCompliantCauseDistributionView
     */
    'totalRegistrationsToModerate'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OnfidoCheckResult = {
    CLEAR: 'clear',
    CONSIDER: 'consider'
} as const;

export type OnfidoCheckResult = typeof OnfidoCheckResult[keyof typeof OnfidoCheckResult];


/**
 * 
 * @export
 * @interface PageResponseRegistrationFormListItemView
 */
export interface PageResponseRegistrationFormListItemView {
    /**
     * 
     * @type {Array<RegistrationFormListItemView>}
     * @memberof PageResponseRegistrationFormListItemView
     */
    'content'?: Array<RegistrationFormListItemView>;
    /**
     * 
     * @type {Paging}
     * @memberof PageResponseRegistrationFormListItemView
     */
    'paging'?: Paging;
    /**
     * 
     * @type {Links}
     * @memberof PageResponseRegistrationFormListItemView
     */
    'links'?: Links;
}
/**
 * 
 * @export
 * @interface Paging
 */
export interface Paging {
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    'limitPerPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PreviousControlView
 */
export interface PreviousControlView {
    /**
     * 
     * @type {string}
     * @memberof PreviousControlView
     */
    'registrationDocumentId'?: string;
    /**
     * 
     * @type {RegistrationControl}
     * @memberof PreviousControlView
     */
    'control'?: RegistrationControl;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationControl = {
    IDENTITY_DOCUMENT_TYPE: 'IDENTITY_DOCUMENT_TYPE',
    FAMILY_DOCUMENT_TYPE: 'FAMILY_DOCUMENT_TYPE',
    ISSUING_COUNTRY: 'ISSUING_COUNTRY',
    PHOTOCOPY: 'PHOTOCOPY',
    SIDES: 'SIDES',
    IDENTITY_DOCUMENT_READABILITY: 'IDENTITY_DOCUMENT_READABILITY',
    FAMILY_DOCUMENT_READABILITY: 'FAMILY_DOCUMENT_READABILITY',
    CHIP: 'CHIP',
    MRZ_LINES: 'MRZ_LINES',
    HOLOGRAMS: 'HOLOGRAMS',
    FRENCH_REPUBLIC_MENTIONS: 'FRENCH_REPUBLIC_MENTIONS',
    FONT: 'FONT',
    DOCUMENT_NUMBER_POSITION: 'DOCUMENT_NUMBER_POSITION',
    PHOTO: 'PHOTO',
    COLORS: 'COLORS',
    DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
    LAST_NAMES: 'LAST_NAMES',
    FIRST_NAMES: 'FIRST_NAMES',
    BIRTH_DATA: 'BIRTH_DATA',
    SEX: 'SEX',
    EXPIRATION_DATE: 'EXPIRATION_DATE',
    SIGNATURE: 'SIGNATURE',
    UNKNOWN: 'UNKNOWN'
} as const;

export type RegistrationControl = typeof RegistrationControl[keyof typeof RegistrationControl];


/**
 * 
 * @export
 * @interface RegistrationControlProgressView
 */
export interface RegistrationControlProgressView {
    /**
     * 
     * @type {number}
     * @memberof RegistrationControlProgressView
     */
    'doneCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationControlProgressView
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface RegistrationDocumentFileView
 */
export interface RegistrationDocumentFileView {
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentFileView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface RegistrationDocumentListView
 */
export interface RegistrationDocumentListView {
    /**
     * 
     * @type {Array<RegistrationDocumentView>}
     * @memberof RegistrationDocumentListView
     */
    'registrationDocuments'?: Array<RegistrationDocumentView>;
}
/**
 * 
 * @export
 * @interface RegistrationDocumentOcrPropertiesView
 */
export interface RegistrationDocumentOcrPropertiesView {
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'firstNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'lastNames'?: Array<string>;
    /**
     * 
     * @type {Sex}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'sex'?: Sex;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'nationality'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'personalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'issuingCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'issuingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationDocumentOcrPropertiesView
     */
    'mrzLines'?: Array<string>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationDocumentType = {
    PASSPORT: 'PASSPORT',
    NATIONAL_IDENTITY_CARD: 'NATIONAL_IDENTITY_CARD',
    RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
    DRIVING_LICENCE: 'DRIVING_LICENCE',
    FAMILY_REGISTER: 'FAMILY_REGISTER',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    JUDGEMENT: 'JUDGEMENT',
    OTHER: 'OTHER',
    UNKNOWN: 'UNKNOWN'
} as const;

export type RegistrationDocumentType = typeof RegistrationDocumentType[keyof typeof RegistrationDocumentType];


/**
 * 
 * @export
 * @interface RegistrationDocumentView
 */
export interface RegistrationDocumentView {
    /**
     * 
     * @type {string}
     * @memberof RegistrationDocumentView
     */
    'id'?: string;
    /**
     * 
     * @type {RegistrationDocumentType}
     * @memberof RegistrationDocumentView
     */
    'type'?: RegistrationDocumentType;
    /**
     * 
     * @type {AccountStakeholder}
     * @memberof RegistrationDocumentView
     */
    'accountStakeHolder'?: AccountStakeholder;
    /**
     * 
     * @type {Array<RegistrationDocumentFileView>}
     * @memberof RegistrationDocumentView
     */
    'files'?: Array<RegistrationDocumentFileView>;
}


/**
 * 
 * @export
 * @interface RegistrationFormListItemView
 */
export interface RegistrationFormListItemView {
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'registrationCode'?: string;
    /**
     * 
     * @type {RegistrationFormType}
     * @memberof RegistrationFormListItemView
     */
    'type'?: RegistrationFormType;
    /**
     * 
     * @type {RegistrationFormPlatform}
     * @memberof RegistrationFormListItemView
     */
    'platform'?: RegistrationFormPlatform;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'finalizationDate'?: string;
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof RegistrationFormListItemView
     */
    'complianceStatus'?: ComplianceStatus;
    /**
     * 
     * @type {OnfidoCheckResult}
     * @memberof RegistrationFormListItemView
     */
    'onfidoCheckResult'?: OnfidoCheckResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationFormListItemView
     */
    'firstNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationFormListItemView
     */
    'lastNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormListItemView
     */
    'registrationCountry'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationFormPlatform = {
    WEB: 'WEB',
    TERMINAL: 'TERMINAL'
} as const;

export type RegistrationFormPlatform = typeof RegistrationFormPlatform[keyof typeof RegistrationFormPlatform];


/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationFormType = {
    ADULT: 'ADULT',
    CHILD: 'CHILD'
} as const;

export type RegistrationFormType = typeof RegistrationFormType[keyof typeof RegistrationFormType];


/**
 * 
 * @export
 * @interface RegistrationFormView
 */
export interface RegistrationFormView {
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormView
     */
    'registrationFormId'?: string;
    /**
     * 
     * @type {RegistrationFormType}
     * @memberof RegistrationFormView
     */
    'registrationFormType'?: RegistrationFormType;
    /**
     * 
     * @type {RegistrationFormPlatform}
     * @memberof RegistrationFormView
     */
    'registrationFormPlatform'?: RegistrationFormPlatform;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormView
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormView
     */
    'registrationCode'?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrationFormView
     */
    'registrationCodeParent'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationFormView
     */
    'finalizationDate'?: string;
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof RegistrationFormView
     */
    'complianceStatus'?: ComplianceStatus;
    /**
     * 
     * @type {NotCompliantCause}
     * @memberof RegistrationFormView
     */
    'notCompliantCause'?: NotCompliantCause;
    /**
     * 
     * @type {Array<ModerationTodoCauseView>}
     * @memberof RegistrationFormView
     */
    'moderationTodoCauses'?: Array<ModerationTodoCauseView>;
    /**
     * 
     * @type {ApplicationVersion}
     * @memberof RegistrationFormView
     */
    'applicationVersion'?: ApplicationVersion;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Sex = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    UNKNOWN: 'UNKNOWN',
    NEUTRAL: 'NEUTRAL'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * 
 * @export
 * @interface SignatureControlResultPayload
 */
export interface SignatureControlResultPayload {
    /**
     * 
     * @type {ControlValidity}
     * @memberof SignatureControlResultPayload
     */
    'signatureValidity': ControlValidity;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SortingDirection = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type SortingDirection = typeof SortingDirection[keyof typeof SortingDirection];


/**
 *
 * @export
 * @interface StakeholderDocumentsView
 */
export interface StakeholderDocumentsView {
    /**
     *
     * @type {Array<string>}
     * @memberof StakeholderDocumentsView
     */
    'owner'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof StakeholderDocumentsView
     */
    'guardian'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBirthDataPayload
 */
export interface UpdateBirthDataPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthDataPayload
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthDataPayload
     */
    'birthPlace': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthDataPayload
     */
    'birthCountry': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthDataPayload
     */
    'birthRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthDataPayload
     */
    'nationality': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBirthDataPayload
     */
    'nationalities': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateChipPresencePayload
 */
export interface UpdateChipPresencePayload {
    /**
     * 
     * @type {ChipPresence}
     * @memberof UpdateChipPresencePayload
     */
    'presence': ChipPresence;
}


/**
 * 
 * @export
 * @interface UpdateComplianceStatusPayload
 */
export interface UpdateComplianceStatusPayload {
    /**
     * 
     * @type {ComplianceStatus}
     * @memberof UpdateComplianceStatusPayload
     */
    'complianceStatus'?: ComplianceStatus;
}


/**
 * 
 * @export
 * @interface UpdateDocumentNumberPayload
 */
export interface UpdateDocumentNumberPayload {
    /**
     *
     * @type {string}
     * @memberof UpdateDocumentNumberPayload
     */
    'documentNumber': string;
    /**
     *
     * @type {string}
     * @memberof UpdateDocumentNumberPayload
     */
    'personalNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDocumentNumberPositionPayload
 */
export interface UpdateDocumentNumberPositionPayload {
    /**
     * 
     * @type {ControlValidity}
     * @memberof UpdateDocumentNumberPositionPayload
     */
    'validity': ControlValidity;
}


/**
 * 
 * @export
 * @interface UpdateExpirationDatePayload
 */
export interface UpdateExpirationDatePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateExpirationDatePayload
     */
    'expirationDate': string;
}
/**
 * 
 * @export
 * @interface UpdateFamilyDocumentReadabilityPayload
 */
export interface UpdateFamilyDocumentReadabilityPayload {
    /**
     * 
     * @type {FamilyDocumentReadability}
     * @memberof UpdateFamilyDocumentReadabilityPayload
     */
    'readability': FamilyDocumentReadability;
}


/**
 * 
 * @export
 * @interface UpdateFamilyDocumentTypePayload
 */
export interface UpdateFamilyDocumentTypePayload {
    /**
     * 
     * @type {FamilyDocumentType}
     * @memberof UpdateFamilyDocumentTypePayload
     */
    'familyDocumentType': FamilyDocumentType;
    /**
     * 
     * @type {RegistrationDocumentType}
     * @memberof UpdateFamilyDocumentTypePayload
     */
    'registrationDocumentType'?: RegistrationDocumentType;
}


/**
 * 
 * @export
 * @interface UpdateFirstNamesPayload
 */
export interface UpdateFirstNamesPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFirstNamesPayload
     */
    'firstNames': Array<string>;
    /**
     * 
     * @type {Array<NameModificationReason>}
     * @memberof UpdateFirstNamesPayload
     */
    'modificationReasons'?: Array<NameModificationReason>;
}
/**
 * 
 * @export
 * @interface UpdateFontValidityPayload
 */
export interface UpdateFontValidityPayload {
    /**
     * 
     * @type {DoubtableValidity}
     * @memberof UpdateFontValidityPayload
     */
    'validity': DoubtableValidity;
}


/**
 * 
 * @export
 * @interface UpdateFrenchRepublicMentionsPayload
 */
export interface UpdateFrenchRepublicMentionsPayload {
    /**
     * 
     * @type {DoubtableValidity}
     * @memberof UpdateFrenchRepublicMentionsPayload
     */
    'validity': DoubtableValidity;
}


/**
 * 
 * @export
 * @interface UpdateHologramsPayload
 */
export interface UpdateHologramsPayload {
    /**
     * 
     * @type {DoubtableValidity}
     * @memberof UpdateHologramsPayload
     */
    'validity': DoubtableValidity;
}


/**
 * 
 * @export
 * @interface UpdateIdentityDocumentReadabilityPayload
 */
export interface UpdateIdentityDocumentReadabilityPayload {
    /**
     * 
     * @type {IdentityDocumentReadability}
     * @memberof UpdateIdentityDocumentReadabilityPayload
     */
    'readability': IdentityDocumentReadability;
}


/**
 * 
 * @export
 * @interface UpdateIdentityDocumentTypePayload
 */
export interface UpdateIdentityDocumentTypePayload {
    /**
     * 
     * @type {IdentityDocumentType}
     * @memberof UpdateIdentityDocumentTypePayload
     */
    'identityDocumentType': IdentityDocumentType;
    /**
     * 
     * @type {RegistrationDocumentType}
     * @memberof UpdateIdentityDocumentTypePayload
     */
    'registrationDocumentType'?: RegistrationDocumentType;
}


/**
 * 
 * @export
 * @interface UpdateIssuingCountryPayload
 */
export interface UpdateIssuingCountryPayload {
    /**
     * 
     * @type {CountryCode}
     * @memberof UpdateIssuingCountryPayload
     */
    'issuingCountry': CountryCode;
}


/**
 * 
 * @export
 * @interface UpdateLastNamesPayload
 */
export interface UpdateLastNamesPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLastNamesPayload
     */
    'lastNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateLastNamesPayload
     */
    'marriedLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLastNamesPayload
     */
    'preferredLastName'?: string;
    /**
     * 
     * @type {Array<NameModificationReason>}
     * @memberof UpdateLastNamesPayload
     */
    'modificationReasons'?: Array<NameModificationReason>;
}
/**
 * 
 * @export
 * @interface UpdatePhotoPayload
 */
export interface UpdatePhotoPayload {
    /**
     * 
     * @type {DoubtableValidity}
     * @memberof UpdatePhotoPayload
     */
    'validity': DoubtableValidity;
}


/**
 * 
 * @export
 * @interface UpdateSexPayload
 */
export interface UpdateSexPayload {
    /**
     * 
     * @type {Sex}
     * @memberof UpdateSexPayload
     */
    'sex': Sex;
}


/**
 * 
 * @export
 * @interface UpdateSidesValidityPayload
 */
export interface UpdateSidesValidityPayload {
    /**
     * 
     * @type {ControlValidity}
     * @memberof UpdateSidesValidityPayload
     */
    'validity': ControlValidity;
}



/**
 * ControlResourceApi - axios parameter creator
 * @export
 */
export const ControlResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if current user is allowed to control subscription
         * @summary Check if current user is allowed to control subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAllowed: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control-allowed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration control progress
         * @summary Get registration control progress
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsProgress: async (formId: string, registrationDocumentId?: string, control?: RegistrationControl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getControlsProgress', 'formId', formId)
            const localVarPath = `/registration-forms/{formId}/controls/progress`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registrationDocumentId !== undefined) {
                localVarQueryParameter['registration-document-id'] = registrationDocumentId;
            }

            if (control !== undefined) {
                localVarQueryParameter['control'] = control;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get next control
         * @summary Get next control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextControl: async (formId: string, registrationDocumentId?: string, control?: RegistrationControl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getNextControl', 'formId', formId)
            const localVarPath = `/registration-forms/{formId}/controls/next`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registrationDocumentId !== undefined) {
                localVarQueryParameter['registration-document-id'] = registrationDocumentId;
            }

            if (control !== undefined) {
                localVarQueryParameter['control'] = control;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get previous control
         * @summary Get previous control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] dentifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviousControl: async (formId: string, registrationDocumentId?: string, control?: RegistrationControl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getPreviousControl', 'formId', formId)
            const localVarPath = `/registration-forms/{formId}/controls/previous`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registrationDocumentId !== undefined) {
                localVarQueryParameter['registration-document-id'] = registrationDocumentId;
            }

            if (control !== undefined) {
                localVarQueryParameter['control'] = control;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration control warnings
         * @summary Get registration control warnings
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {string} controlCode Control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarnings: async (formId: string, documentId: string, controlCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getWarnings', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getWarnings', 'documentId', documentId)
            // verify required parameter 'controlCode' is not null or undefined
            assertParamExists('getWarnings', 'controlCode', controlCode)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/{controlCode}/warnings`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"controlCode"}}`, encodeURIComponent(String(controlCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer birth data
         * @summary Update customer birth data
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateBirthDataPayload} updateBirthDataPayload Data to update customer birth data
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthData: async (formId: string, documentId: string, updateBirthDataPayload: UpdateBirthDataPayload, postControlActionsConfirmed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateBirthData', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateBirthData', 'documentId', documentId)
            // verify required parameter 'updateBirthDataPayload' is not null or undefined
            assertParamExists('updateBirthData', 'updateBirthDataPayload', updateBirthDataPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/birth-data/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (postControlActionsConfirmed != null) {
                localVarHeaderParameter['post-control-actions-confirmed'] = typeof postControlActionsConfirmed === 'string' 
                    ? postControlActionsConfirmed 
                    : JSON.stringify(postControlActionsConfirmed);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBirthDataPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document color control
         * @summary Update identity document color control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update identity document color control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColors: async (formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateColors', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateColors', 'documentId', documentId)
            // verify required parameter 'doubtableValidityPayload' is not null or undefined
            assertParamExists('updateColors', 'doubtableValidityPayload', doubtableValidityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/colors/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doubtableValidityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update compliance status
         * @summary Update compliance status
         * @param {string} formId Identifier of the registration
         * @param {UpdateComplianceStatusPayload} updateComplianceStatusPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplianceStatus: async (formId: string, updateComplianceStatusPayload: UpdateComplianceStatusPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateComplianceStatus', 'formId', formId)
            // verify required parameter 'updateComplianceStatusPayload' is not null or undefined
            assertParamExists('updateComplianceStatus', 'updateComplianceStatusPayload', updateComplianceStatusPayload)
            const localVarPath = `/registration-forms/{formId}/controls/compliance-status`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateComplianceStatusPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control identity document chip presence
         * @summary Update document chip control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateChipPresencePayload} updateChipPresencePayload Data to update identity document chip presence control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentChip: async (formId: string, documentId: string, updateChipPresencePayload: UpdateChipPresencePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateDocumentChip', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentChip', 'documentId', documentId)
            // verify required parameter 'updateChipPresencePayload' is not null or undefined
            assertParamExists('updateDocumentChip', 'updateChipPresencePayload', updateChipPresencePayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/chip/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChipPresencePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumber: async (formId: string, documentId: string, stakeholder: AccountStakeholder, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateDocumentNumber', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentNumber', 'documentId', documentId)
            // verify required parameter 'stakeholder' is not null or undefined
            assertParamExists('updateDocumentNumber', 'stakeholder', stakeholder)
            // verify required parameter 'updateDocumentNumberPayload' is not null or undefined
            assertParamExists('updateDocumentNumber', 'updateDocumentNumberPayload', updateDocumentNumberPayload)
            const localVarPath = `/registration-forms/{formId}/stakeholder/{stakeholder}/registration-documents/{documentId}/controls/document-number/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"stakeholder"}}`, encodeURIComponent(String(stakeholder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentNumberPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumber1: async (formId: string, documentId: string, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateDocumentNumber1', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentNumber1', 'documentId', documentId)
            // verify required parameter 'updateDocumentNumberPayload' is not null or undefined
            assertParamExists('updateDocumentNumber1', 'updateDocumentNumberPayload', updateDocumentNumberPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/document-number/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentNumberPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document number position control
         * @summary Update identity document number position control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPositionPayload} updateDocumentNumberPositionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumberPosition: async (formId: string, documentId: string, updateDocumentNumberPositionPayload: UpdateDocumentNumberPositionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateDocumentNumberPosition', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentNumberPosition', 'documentId', documentId)
            // verify required parameter 'updateDocumentNumberPositionPayload' is not null or undefined
            assertParamExists('updateDocumentNumberPosition', 'updateDocumentNumberPositionPayload', updateDocumentNumberPositionPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/document-number-position/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentNumberPositionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate: async (formId: string, documentId: string, stakeholder: AccountStakeholder, updateExpirationDatePayload: UpdateExpirationDatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateExpirationDate', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateExpirationDate', 'documentId', documentId)
            // verify required parameter 'stakeholder' is not null or undefined
            assertParamExists('updateExpirationDate', 'stakeholder', stakeholder)
            // verify required parameter 'updateExpirationDatePayload' is not null or undefined
            assertParamExists('updateExpirationDate', 'updateExpirationDatePayload', updateExpirationDatePayload)
            const localVarPath = `/registration-forms/{formId}/stakeholder/{stakeholder}/registration-documents/{documentId}/controls/expiration-date/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"stakeholder"}}`, encodeURIComponent(String(stakeholder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpirationDatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate1: async (formId: string, documentId: string, updateExpirationDatePayload: UpdateExpirationDatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateExpirationDate1', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateExpirationDate1', 'documentId', documentId)
            // verify required parameter 'updateExpirationDatePayload' is not null or undefined
            assertParamExists('updateExpirationDate1', 'updateExpirationDatePayload', updateExpirationDatePayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/expiration-date/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpirationDatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentReadabilityPayload} updateFamilyDocumentReadabilityPayload Data to update family document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyDocumentReadability: async (formId: string, documentId: string, updateFamilyDocumentReadabilityPayload: UpdateFamilyDocumentReadabilityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateFamilyDocumentReadability', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateFamilyDocumentReadability', 'documentId', documentId)
            // verify required parameter 'updateFamilyDocumentReadabilityPayload' is not null or undefined
            assertParamExists('updateFamilyDocumentReadability', 'updateFamilyDocumentReadabilityPayload', updateFamilyDocumentReadabilityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/family-document-readability/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFamilyDocumentReadabilityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update family document type
         * @summary Update family document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentTypePayload} updateFamilyDocumentTypePayload Data to update family document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyDocumentType: async (formId: string, documentId: string, updateFamilyDocumentTypePayload: UpdateFamilyDocumentTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateFamilyDocumentType', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateFamilyDocumentType', 'documentId', documentId)
            // verify required parameter 'updateFamilyDocumentTypePayload' is not null or undefined
            assertParamExists('updateFamilyDocumentType', 'updateFamilyDocumentTypePayload', updateFamilyDocumentTypePayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/family-document-type/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFamilyDocumentTypePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer firstnames
         * @summary Update customer firstnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFirstNamesPayload} updateFirstNamesPayload Data to update customer firstnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirstNames: async (formId: string, documentId: string, updateFirstNamesPayload: UpdateFirstNamesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateFirstNames', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateFirstNames', 'documentId', documentId)
            // verify required parameter 'updateFirstNamesPayload' is not null or undefined
            assertParamExists('updateFirstNames', 'updateFirstNamesPayload', updateFirstNamesPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/first-names/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFirstNamesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control if identity document font is valid
         * @summary Update identity document font control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFontValidityPayload} updateFontValidityPayload Data to update identity document font control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFont: async (formId: string, documentId: string, updateFontValidityPayload: UpdateFontValidityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateFont', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateFont', 'documentId', documentId)
            // verify required parameter 'updateFontValidityPayload' is not null or undefined
            assertParamExists('updateFont', 'updateFontValidityPayload', updateFontValidityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/font/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFontValidityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update french republic mentions control
         * @summary Update french republic mentions control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFrenchRepublicMentionsPayload} updateFrenchRepublicMentionsPayload Data to update french republic mentions control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFrenchRepublicMentions: async (formId: string, documentId: string, updateFrenchRepublicMentionsPayload: UpdateFrenchRepublicMentionsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateFrenchRepublicMentions', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateFrenchRepublicMentions', 'documentId', documentId)
            // verify required parameter 'updateFrenchRepublicMentionsPayload' is not null or undefined
            assertParamExists('updateFrenchRepublicMentions', 'updateFrenchRepublicMentionsPayload', updateFrenchRepublicMentionsPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/french-republic-mentions/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFrenchRepublicMentionsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document holograms validity control
         * @summary Update identity document holograms control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateHologramsPayload} updateHologramsPayload Data to update identity document hologram validity control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHolograms: async (formId: string, documentId: string, updateHologramsPayload: UpdateHologramsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateHolograms', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateHolograms', 'documentId', documentId)
            // verify required parameter 'updateHologramsPayload' is not null or undefined
            assertParamExists('updateHolograms', 'updateHologramsPayload', updateHologramsPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/holograms/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHologramsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update document readability control
         * @summary Update document readability control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentReadabilityPayload} updateIdentityDocumentReadabilityPayload Data to update identity document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityDocumentReadability: async (formId: string, documentId: string, updateIdentityDocumentReadabilityPayload: UpdateIdentityDocumentReadabilityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateIdentityDocumentReadability', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateIdentityDocumentReadability', 'documentId', documentId)
            // verify required parameter 'updateIdentityDocumentReadabilityPayload' is not null or undefined
            assertParamExists('updateIdentityDocumentReadability', 'updateIdentityDocumentReadabilityPayload', updateIdentityDocumentReadabilityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/identity-document-readability/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIdentityDocumentReadabilityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentTypePayload} updateIdentityDocumentTypePayload Data to update identity document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityDocumentType: async (formId: string, documentId: string, updateIdentityDocumentTypePayload: UpdateIdentityDocumentTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateIdentityDocumentType', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateIdentityDocumentType', 'documentId', documentId)
            // verify required parameter 'updateIdentityDocumentTypePayload' is not null or undefined
            assertParamExists('updateIdentityDocumentType', 'updateIdentityDocumentTypePayload', updateIdentityDocumentTypePayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/identity-document-type/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIdentityDocumentTypePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update document issuing country
         * @summary Update document issuing country
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIssuingCountryPayload} updateIssuingCountryPayload Data to update document issuing country
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssuingCountry: async (formId: string, documentId: string, updateIssuingCountryPayload: UpdateIssuingCountryPayload, postControlActionsConfirmed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateIssuingCountry', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateIssuingCountry', 'documentId', documentId)
            // verify required parameter 'updateIssuingCountryPayload' is not null or undefined
            assertParamExists('updateIssuingCountry', 'updateIssuingCountryPayload', updateIssuingCountryPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/issuing-country/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (postControlActionsConfirmed != null) {
                localVarHeaderParameter['post-control-actions-confirmed'] = typeof postControlActionsConfirmed === 'string' 
                    ? postControlActionsConfirmed 
                    : JSON.stringify(postControlActionsConfirmed);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIssuingCountryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer lastnames
         * @summary Update customer lastnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateLastNamesPayload} updateLastNamesPayload Data to update customer lastnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastNames: async (formId: string, documentId: string, updateLastNamesPayload: UpdateLastNamesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateLastNames', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateLastNames', 'documentId', documentId)
            // verify required parameter 'updateLastNamesPayload' is not null or undefined
            assertParamExists('updateLastNames', 'updateLastNamesPayload', updateLastNamesPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/last-names/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLastNamesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save MRZ lines control result
         * @summary Save MRZ lines control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AddMrzLinesResultPayload} addMrzLinesResultPayload Data to update MRZ lines control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMrzLineValidity: async (formId: string, documentId: string, addMrzLinesResultPayload: AddMrzLinesResultPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateMrzLineValidity', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateMrzLineValidity', 'documentId', documentId)
            // verify required parameter 'addMrzLinesResultPayload' is not null or undefined
            assertParamExists('updateMrzLineValidity', 'addMrzLinesResultPayload', addMrzLinesResultPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/mrz-lines/results`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMrzLinesResultPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update identity document photo control
         * @summary Update identity document photo control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdatePhotoPayload} updatePhotoPayload Data to update identity document photo control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoto: async (formId: string, documentId: string, updatePhotoPayload: UpdatePhotoPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updatePhoto', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updatePhoto', 'documentId', documentId)
            // verify required parameter 'updatePhotoPayload' is not null or undefined
            assertParamExists('updatePhoto', 'updatePhotoPayload', updatePhotoPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/photo/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePhotoPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control if given document is a photocopy
         * @summary Control photocopy control validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update doubtable validity for document photocopy control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhotocopy: async (formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updatePhotocopy', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updatePhotocopy', 'documentId', documentId)
            // verify required parameter 'doubtableValidityPayload' is not null or undefined
            assertParamExists('updatePhotocopy', 'doubtableValidityPayload', doubtableValidityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/photocopy/results`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doubtableValidityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer sex
         * @summary Update customer sex
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSexPayload} updateSexPayload Data to update customer sex
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSex: async (formId: string, documentId: string, updateSexPayload: UpdateSexPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateSex', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateSex', 'documentId', documentId)
            // verify required parameter 'updateSexPayload' is not null or undefined
            assertParamExists('updateSex', 'updateSexPayload', updateSexPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/sex/observations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSexPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control if identity document side is valid
         * @summary Control identity document sides validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSidesValidityPayload} updateSidesValidityPayload Data to update validity for identity document side control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSides: async (formId: string, documentId: string, updateSidesValidityPayload: UpdateSidesValidityPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateSides', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateSides', 'documentId', documentId)
            // verify required parameter 'updateSidesValidityPayload' is not null or undefined
            assertParamExists('updateSides', 'updateSidesValidityPayload', updateSidesValidityPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/sides/results`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSidesValidityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save signature validity control
         * @summary Save signature validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {SignatureControlResultPayload} signatureControlResultPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignatureValidity: async (formId: string, documentId: string, signatureControlResultPayload: SignatureControlResultPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateSignatureValidity', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateSignatureValidity', 'documentId', documentId)
            // verify required parameter 'signatureControlResultPayload' is not null or undefined
            assertParamExists('updateSignatureValidity', 'signatureControlResultPayload', signatureControlResultPayload)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/signature/results`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signatureControlResultPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply surname validity control
         * @summary Apply surname validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOcrSurnames: async (formId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('validateOcrSurnames', 'formId', formId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('validateOcrSurnames', 'documentId', documentId)
            const localVarPath = `/registration-forms/{formId}/registration-documents/{documentId}/controls/ocr-surnames/validations`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ControlResourceApi - functional programming interface
 * @export
 */
export const ControlResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if current user is allowed to control subscription
         * @summary Check if current user is allowed to control subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAllowed(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAllowed(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration control progress
         * @summary Get registration control progress
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsProgress(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationControlProgressView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsProgress(formId, registrationDocumentId, control, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get next control
         * @summary Get next control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NextControlView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextControl(formId, registrationDocumentId, control, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get previous control
         * @summary Get previous control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] dentifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviousControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviousControlView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviousControl(formId, registrationDocumentId, control, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration control warnings
         * @summary Get registration control warnings
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {string} controlCode Control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarnings(formId: string, documentId: string, controlCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlWarningListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarnings(formId, documentId, controlCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update customer birth data
         * @summary Update customer birth data
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateBirthDataPayload} updateBirthDataPayload Data to update customer birth data
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBirthData(formId: string, documentId: string, updateBirthDataPayload: UpdateBirthDataPayload, postControlActionsConfirmed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBirthData(formId, documentId, updateBirthDataPayload, postControlActionsConfirmed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document color control
         * @summary Update identity document color control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update identity document color control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateColors(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateColors(formId, documentId, doubtableValidityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update compliance status
         * @summary Update compliance status
         * @param {string} formId Identifier of the registration
         * @param {UpdateComplianceStatusPayload} updateComplianceStatusPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComplianceStatus(formId: string, updateComplianceStatusPayload: UpdateComplianceStatusPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComplianceStatus(formId, updateComplianceStatusPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Control identity document chip presence
         * @summary Update document chip control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateChipPresencePayload} updateChipPresencePayload Data to update identity document chip presence control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentChip(formId: string, documentId: string, updateChipPresencePayload: UpdateChipPresencePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentChip(formId, documentId, updateChipPresencePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentNumber(formId: string, documentId: string, stakeholder: AccountStakeholder, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentNumber(formId, documentId, stakeholder, updateDocumentNumberPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentNumber1(formId: string, documentId: string, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentNumber1(formId, documentId, updateDocumentNumberPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document number position control
         * @summary Update identity document number position control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPositionPayload} updateDocumentNumberPositionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentNumberPosition(formId: string, documentId: string, updateDocumentNumberPositionPayload: UpdateDocumentNumberPositionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentNumberPosition(formId, documentId, updateDocumentNumberPositionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExpirationDate(formId: string, documentId: string, stakeholder: AccountStakeholder, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExpirationDate(formId, documentId, stakeholder, updateExpirationDatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExpirationDate1(formId: string, documentId: string, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExpirationDate1(formId, documentId, updateExpirationDatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentReadabilityPayload} updateFamilyDocumentReadabilityPayload Data to update family document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFamilyDocumentReadability(formId: string, documentId: string, updateFamilyDocumentReadabilityPayload: UpdateFamilyDocumentReadabilityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFamilyDocumentReadability(formId, documentId, updateFamilyDocumentReadabilityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update family document type
         * @summary Update family document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentTypePayload} updateFamilyDocumentTypePayload Data to update family document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFamilyDocumentType(formId: string, documentId: string, updateFamilyDocumentTypePayload: UpdateFamilyDocumentTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFamilyDocumentType(formId, documentId, updateFamilyDocumentTypePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update customer firstnames
         * @summary Update customer firstnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFirstNamesPayload} updateFirstNamesPayload Data to update customer firstnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFirstNames(formId: string, documentId: string, updateFirstNamesPayload: UpdateFirstNamesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFirstNames(formId, documentId, updateFirstNamesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Control if identity document font is valid
         * @summary Update identity document font control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFontValidityPayload} updateFontValidityPayload Data to update identity document font control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFont(formId: string, documentId: string, updateFontValidityPayload: UpdateFontValidityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFont(formId, documentId, updateFontValidityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update french republic mentions control
         * @summary Update french republic mentions control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFrenchRepublicMentionsPayload} updateFrenchRepublicMentionsPayload Data to update french republic mentions control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFrenchRepublicMentions(formId: string, documentId: string, updateFrenchRepublicMentionsPayload: UpdateFrenchRepublicMentionsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFrenchRepublicMentions(formId, documentId, updateFrenchRepublicMentionsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document holograms validity control
         * @summary Update identity document holograms control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateHologramsPayload} updateHologramsPayload Data to update identity document hologram validity control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHolograms(formId: string, documentId: string, updateHologramsPayload: UpdateHologramsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHolograms(formId, documentId, updateHologramsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update document readability control
         * @summary Update document readability control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentReadabilityPayload} updateIdentityDocumentReadabilityPayload Data to update identity document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentityDocumentReadability(formId: string, documentId: string, updateIdentityDocumentReadabilityPayload: UpdateIdentityDocumentReadabilityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdentityDocumentReadability(formId, documentId, updateIdentityDocumentReadabilityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentTypePayload} updateIdentityDocumentTypePayload Data to update identity document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentityDocumentType(formId: string, documentId: string, updateIdentityDocumentTypePayload: UpdateIdentityDocumentTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdentityDocumentType(formId, documentId, updateIdentityDocumentTypePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update document issuing country
         * @summary Update document issuing country
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIssuingCountryPayload} updateIssuingCountryPayload Data to update document issuing country
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssuingCountry(formId: string, documentId: string, updateIssuingCountryPayload: UpdateIssuingCountryPayload, postControlActionsConfirmed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssuingCountry(formId, documentId, updateIssuingCountryPayload, postControlActionsConfirmed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update customer lastnames
         * @summary Update customer lastnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateLastNamesPayload} updateLastNamesPayload Data to update customer lastnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastNames(formId: string, documentId: string, updateLastNamesPayload: UpdateLastNamesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastNames(formId, documentId, updateLastNamesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save MRZ lines control result
         * @summary Save MRZ lines control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AddMrzLinesResultPayload} addMrzLinesResultPayload Data to update MRZ lines control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMrzLineValidity(formId: string, documentId: string, addMrzLinesResultPayload: AddMrzLinesResultPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMrzLineValidity(formId, documentId, addMrzLinesResultPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update identity document photo control
         * @summary Update identity document photo control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdatePhotoPayload} updatePhotoPayload Data to update identity document photo control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhoto(formId: string, documentId: string, updatePhotoPayload: UpdatePhotoPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhoto(formId, documentId, updatePhotoPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Control if given document is a photocopy
         * @summary Control photocopy control validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update doubtable validity for document photocopy control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhotocopy(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhotocopy(formId, documentId, doubtableValidityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update customer sex
         * @summary Update customer sex
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSexPayload} updateSexPayload Data to update customer sex
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSex(formId: string, documentId: string, updateSexPayload: UpdateSexPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSex(formId, documentId, updateSexPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Control if identity document side is valid
         * @summary Control identity document sides validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSidesValidityPayload} updateSidesValidityPayload Data to update validity for identity document side control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSides(formId: string, documentId: string, updateSidesValidityPayload: UpdateSidesValidityPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSides(formId, documentId, updateSidesValidityPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save signature validity control
         * @summary Save signature validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {SignatureControlResultPayload} signatureControlResultPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSignatureValidity(formId: string, documentId: string, signatureControlResultPayload: SignatureControlResultPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSignatureValidity(formId, documentId, signatureControlResultPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply surname validity control
         * @summary Apply surname validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOcrSurnames(formId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateOcrSurnames(formId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ControlResourceApi - factory interface
 * @export
 */
export const ControlResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ControlResourceApiFp(configuration)
    return {
        /**
         * Check if current user is allowed to control subscription
         * @summary Check if current user is allowed to control subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAllowed(options?: any): AxiosPromise<boolean> {
            return localVarFp.controlAllowed(options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration control progress
         * @summary Get registration control progress
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsProgress(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: any): AxiosPromise<RegistrationControlProgressView> {
            return localVarFp.getControlsProgress(formId, registrationDocumentId, control, options).then((request) => request(axios, basePath));
        },
        /**
         * Get next control
         * @summary Get next control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] Identifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: any): AxiosPromise<NextControlView> {
            return localVarFp.getNextControl(formId, registrationDocumentId, control, options).then((request) => request(axios, basePath));
        },
        /**
         * Get previous control
         * @summary Get previous control
         * @param {string} formId Identifier of the registration
         * @param {string} [registrationDocumentId] dentifier of the registration document
         * @param {RegistrationControl} [control] Current control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviousControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: any): AxiosPromise<PreviousControlView> {
            return localVarFp.getPreviousControl(formId, registrationDocumentId, control, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration control warnings
         * @summary Get registration control warnings
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {string} controlCode Control code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarnings(formId: string, documentId: string, controlCode: string, options?: any): AxiosPromise<ControlWarningListView> {
            return localVarFp.getWarnings(formId, documentId, controlCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer birth data
         * @summary Update customer birth data
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateBirthDataPayload} updateBirthDataPayload Data to update customer birth data
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthData(formId: string, documentId: string, updateBirthDataPayload: UpdateBirthDataPayload, postControlActionsConfirmed?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateBirthData(formId, documentId, updateBirthDataPayload, postControlActionsConfirmed, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document color control
         * @summary Update identity document color control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update identity document color control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColors(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateColors(formId, documentId, doubtableValidityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update compliance status
         * @summary Update compliance status
         * @param {string} formId Identifier of the registration
         * @param {UpdateComplianceStatusPayload} updateComplianceStatusPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplianceStatus(formId: string, updateComplianceStatusPayload: UpdateComplianceStatusPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateComplianceStatus(formId, updateComplianceStatusPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Control identity document chip presence
         * @summary Update document chip control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateChipPresencePayload} updateChipPresencePayload Data to update identity document chip presence control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentChip(formId: string, documentId: string, updateChipPresencePayload: UpdateChipPresencePayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentChip(formId, documentId, updateChipPresencePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumber(formId: string, documentId: string, stakeholder: AccountStakeholder, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentNumber(formId, documentId, stakeholder, updateDocumentNumberPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document number and personal number
         * @summary Update identity document number
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumber1(formId: string, documentId: string, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentNumber1(formId, documentId, updateDocumentNumberPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document number position control
         * @summary Update identity document number position control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateDocumentNumberPositionPayload} updateDocumentNumberPositionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentNumberPosition(formId: string, documentId: string, updateDocumentNumberPositionPayload: UpdateDocumentNumberPositionPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentNumberPosition(formId, documentId, updateDocumentNumberPositionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate(formId: string, documentId: string, stakeholder: AccountStakeholder, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateExpirationDate(formId, documentId, stakeholder, updateExpirationDatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document expiration date
         * @summary Update identity document expiration date
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate1(formId: string, documentId: string, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateExpirationDate1(formId, documentId, updateExpirationDatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentReadabilityPayload} updateFamilyDocumentReadabilityPayload Data to update family document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyDocumentReadability(formId: string, documentId: string, updateFamilyDocumentReadabilityPayload: UpdateFamilyDocumentReadabilityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateFamilyDocumentReadability(formId, documentId, updateFamilyDocumentReadabilityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update family document type
         * @summary Update family document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFamilyDocumentTypePayload} updateFamilyDocumentTypePayload Data to update family document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyDocumentType(formId: string, documentId: string, updateFamilyDocumentTypePayload: UpdateFamilyDocumentTypePayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateFamilyDocumentType(formId, documentId, updateFamilyDocumentTypePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer firstnames
         * @summary Update customer firstnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFirstNamesPayload} updateFirstNamesPayload Data to update customer firstnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirstNames(formId: string, documentId: string, updateFirstNamesPayload: UpdateFirstNamesPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateFirstNames(formId, documentId, updateFirstNamesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Control if identity document font is valid
         * @summary Update identity document font control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFontValidityPayload} updateFontValidityPayload Data to update identity document font control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFont(formId: string, documentId: string, updateFontValidityPayload: UpdateFontValidityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateFont(formId, documentId, updateFontValidityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update french republic mentions control
         * @summary Update french republic mentions control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateFrenchRepublicMentionsPayload} updateFrenchRepublicMentionsPayload Data to update french republic mentions control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFrenchRepublicMentions(formId: string, documentId: string, updateFrenchRepublicMentionsPayload: UpdateFrenchRepublicMentionsPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateFrenchRepublicMentions(formId, documentId, updateFrenchRepublicMentionsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document holograms validity control
         * @summary Update identity document holograms control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateHologramsPayload} updateHologramsPayload Data to update identity document hologram validity control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHolograms(formId: string, documentId: string, updateHologramsPayload: UpdateHologramsPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateHolograms(formId, documentId, updateHologramsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update document readability control
         * @summary Update document readability control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentReadabilityPayload} updateIdentityDocumentReadabilityPayload Data to update identity document readability control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityDocumentReadability(formId: string, documentId: string, updateIdentityDocumentReadabilityPayload: UpdateIdentityDocumentReadabilityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdentityDocumentReadability(formId, documentId, updateIdentityDocumentReadabilityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document type
         * @summary Update identity document type
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIdentityDocumentTypePayload} updateIdentityDocumentTypePayload Data to update identity document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityDocumentType(formId: string, documentId: string, updateIdentityDocumentTypePayload: UpdateIdentityDocumentTypePayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdentityDocumentType(formId, documentId, updateIdentityDocumentTypePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update document issuing country
         * @summary Update document issuing country
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateIssuingCountryPayload} updateIssuingCountryPayload Data to update document issuing country
         * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssuingCountry(formId: string, documentId: string, updateIssuingCountryPayload: UpdateIssuingCountryPayload, postControlActionsConfirmed?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateIssuingCountry(formId, documentId, updateIssuingCountryPayload, postControlActionsConfirmed, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer lastnames
         * @summary Update customer lastnames
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateLastNamesPayload} updateLastNamesPayload Data to update customer lastnames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastNames(formId: string, documentId: string, updateLastNamesPayload: UpdateLastNamesPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateLastNames(formId, documentId, updateLastNamesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Save MRZ lines control result
         * @summary Save MRZ lines control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {AddMrzLinesResultPayload} addMrzLinesResultPayload Data to update MRZ lines control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMrzLineValidity(formId: string, documentId: string, addMrzLinesResultPayload: AddMrzLinesResultPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateMrzLineValidity(formId, documentId, addMrzLinesResultPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update identity document photo control
         * @summary Update identity document photo control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdatePhotoPayload} updatePhotoPayload Data to update identity document photo control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoto(formId: string, documentId: string, updatePhotoPayload: UpdatePhotoPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updatePhoto(formId, documentId, updatePhotoPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Control if given document is a photocopy
         * @summary Control photocopy control validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update doubtable validity for document photocopy control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhotocopy(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updatePhotocopy(formId, documentId, doubtableValidityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer sex
         * @summary Update customer sex
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSexPayload} updateSexPayload Data to update customer sex
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSex(formId: string, documentId: string, updateSexPayload: UpdateSexPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateSex(formId, documentId, updateSexPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Control if identity document side is valid
         * @summary Control identity document sides validity
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {UpdateSidesValidityPayload} updateSidesValidityPayload Data to update validity for identity document side control
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSides(formId: string, documentId: string, updateSidesValidityPayload: UpdateSidesValidityPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateSides(formId, documentId, updateSidesValidityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Save signature validity control
         * @summary Save signature validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {SignatureControlResultPayload} signatureControlResultPayload Data to update compliance status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignatureValidity(formId: string, documentId: string, signatureControlResultPayload: SignatureControlResultPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateSignatureValidity(formId, documentId, signatureControlResultPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply surname validity control
         * @summary Apply surname validity control
         * @param {string} formId Identifier of the registration
         * @param {string} documentId Identifier of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOcrSurnames(formId: string, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.validateOcrSurnames(formId, documentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ControlResourceApi - object-oriented interface
 * @export
 * @class ControlResourceApi
 * @extends {BaseAPI}
 */
export class ControlResourceApi extends BaseAPI {
    /**
     * Check if current user is allowed to control subscription
     * @summary Check if current user is allowed to control subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public controlAllowed(options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).controlAllowed(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration control progress
     * @summary Get registration control progress
     * @param {string} formId Identifier of the registration
     * @param {string} [registrationDocumentId] Identifier of the document
     * @param {RegistrationControl} [control] Current control code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public getControlsProgress(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).getControlsProgress(formId, registrationDocumentId, control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get next control
     * @summary Get next control
     * @param {string} formId Identifier of the registration
     * @param {string} [registrationDocumentId] Identifier of the registration document
     * @param {RegistrationControl} [control] Current control code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public getNextControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).getNextControl(formId, registrationDocumentId, control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get previous control
     * @summary Get previous control
     * @param {string} formId Identifier of the registration
     * @param {string} [registrationDocumentId] dentifier of the registration document
     * @param {RegistrationControl} [control] Current control code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public getPreviousControl(formId: string, registrationDocumentId?: string, control?: RegistrationControl, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).getPreviousControl(formId, registrationDocumentId, control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration control warnings
     * @summary Get registration control warnings
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {string} controlCode Control code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public getWarnings(formId: string, documentId: string, controlCode: string, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).getWarnings(formId, documentId, controlCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer birth data
     * @summary Update customer birth data
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateBirthDataPayload} updateBirthDataPayload Data to update customer birth data
     * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateBirthData(formId: string, documentId: string, updateBirthDataPayload: UpdateBirthDataPayload, postControlActionsConfirmed?: boolean, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateBirthData(formId, documentId, updateBirthDataPayload, postControlActionsConfirmed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document color control
     * @summary Update identity document color control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update identity document color control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateColors(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateColors(formId, documentId, doubtableValidityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update compliance status
     * @summary Update compliance status
     * @param {string} formId Identifier of the registration
     * @param {UpdateComplianceStatusPayload} updateComplianceStatusPayload Data to update compliance status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateComplianceStatus(formId: string, updateComplianceStatusPayload: UpdateComplianceStatusPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateComplianceStatus(formId, updateComplianceStatusPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control identity document chip presence
     * @summary Update document chip control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateChipPresencePayload} updateChipPresencePayload Data to update identity document chip presence control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateDocumentChip(formId: string, documentId: string, updateChipPresencePayload: UpdateChipPresencePayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateDocumentChip(formId, documentId, updateChipPresencePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document number and personal number
     * @summary Update identity document number
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
     * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateDocumentNumber(formId: string, documentId: string, stakeholder: AccountStakeholder, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateDocumentNumber(formId, documentId, stakeholder, updateDocumentNumberPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document number and personal number
     * @summary Update identity document number
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateDocumentNumberPayload} updateDocumentNumberPayload Data to update identity document number and personal number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateDocumentNumber1(formId: string, documentId: string, updateDocumentNumberPayload: UpdateDocumentNumberPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateDocumentNumber1(formId, documentId, updateDocumentNumberPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document number position control
     * @summary Update identity document number position control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateDocumentNumberPositionPayload} updateDocumentNumberPositionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateDocumentNumberPosition(formId: string, documentId: string, updateDocumentNumberPositionPayload: UpdateDocumentNumberPositionPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateDocumentNumberPosition(formId, documentId, updateDocumentNumberPositionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document expiration date
     * @summary Update identity document expiration date
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {AccountStakeholder} stakeholder Document\&#39;s stakeholder
     * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateExpirationDate(formId: string, documentId: string, stakeholder: AccountStakeholder, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateExpirationDate(formId, documentId, stakeholder, updateExpirationDatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document expiration date
     * @summary Update identity document expiration date
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateExpirationDatePayload} updateExpirationDatePayload Data to update identity document expiration date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateExpirationDate1(formId: string, documentId: string, updateExpirationDatePayload: UpdateExpirationDatePayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateExpirationDate1(formId, documentId, updateExpirationDatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document type
     * @summary Update identity document type
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateFamilyDocumentReadabilityPayload} updateFamilyDocumentReadabilityPayload Data to update family document readability control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateFamilyDocumentReadability(formId: string, documentId: string, updateFamilyDocumentReadabilityPayload: UpdateFamilyDocumentReadabilityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateFamilyDocumentReadability(formId, documentId, updateFamilyDocumentReadabilityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update family document type
     * @summary Update family document type
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateFamilyDocumentTypePayload} updateFamilyDocumentTypePayload Data to update family document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateFamilyDocumentType(formId: string, documentId: string, updateFamilyDocumentTypePayload: UpdateFamilyDocumentTypePayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateFamilyDocumentType(formId, documentId, updateFamilyDocumentTypePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer firstnames
     * @summary Update customer firstnames
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateFirstNamesPayload} updateFirstNamesPayload Data to update customer firstnames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateFirstNames(formId: string, documentId: string, updateFirstNamesPayload: UpdateFirstNamesPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateFirstNames(formId, documentId, updateFirstNamesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control if identity document font is valid
     * @summary Update identity document font control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateFontValidityPayload} updateFontValidityPayload Data to update identity document font control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateFont(formId: string, documentId: string, updateFontValidityPayload: UpdateFontValidityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateFont(formId, documentId, updateFontValidityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update french republic mentions control
     * @summary Update french republic mentions control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateFrenchRepublicMentionsPayload} updateFrenchRepublicMentionsPayload Data to update french republic mentions control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateFrenchRepublicMentions(formId: string, documentId: string, updateFrenchRepublicMentionsPayload: UpdateFrenchRepublicMentionsPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateFrenchRepublicMentions(formId, documentId, updateFrenchRepublicMentionsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document holograms validity control
     * @summary Update identity document holograms control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateHologramsPayload} updateHologramsPayload Data to update identity document hologram validity control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateHolograms(formId: string, documentId: string, updateHologramsPayload: UpdateHologramsPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateHolograms(formId, documentId, updateHologramsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update document readability control
     * @summary Update document readability control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateIdentityDocumentReadabilityPayload} updateIdentityDocumentReadabilityPayload Data to update identity document readability control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateIdentityDocumentReadability(formId: string, documentId: string, updateIdentityDocumentReadabilityPayload: UpdateIdentityDocumentReadabilityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateIdentityDocumentReadability(formId, documentId, updateIdentityDocumentReadabilityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document type
     * @summary Update identity document type
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateIdentityDocumentTypePayload} updateIdentityDocumentTypePayload Data to update identity document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateIdentityDocumentType(formId: string, documentId: string, updateIdentityDocumentTypePayload: UpdateIdentityDocumentTypePayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateIdentityDocumentType(formId, documentId, updateIdentityDocumentTypePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update document issuing country
     * @summary Update document issuing country
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateIssuingCountryPayload} updateIssuingCountryPayload Data to update document issuing country
     * @param {boolean} [postControlActionsConfirmed] Post control confirmed ? 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateIssuingCountry(formId: string, documentId: string, updateIssuingCountryPayload: UpdateIssuingCountryPayload, postControlActionsConfirmed?: boolean, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateIssuingCountry(formId, documentId, updateIssuingCountryPayload, postControlActionsConfirmed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer lastnames
     * @summary Update customer lastnames
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateLastNamesPayload} updateLastNamesPayload Data to update customer lastnames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateLastNames(formId: string, documentId: string, updateLastNamesPayload: UpdateLastNamesPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateLastNames(formId, documentId, updateLastNamesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save MRZ lines control result
     * @summary Save MRZ lines control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {AddMrzLinesResultPayload} addMrzLinesResultPayload Data to update MRZ lines control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateMrzLineValidity(formId: string, documentId: string, addMrzLinesResultPayload: AddMrzLinesResultPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateMrzLineValidity(formId, documentId, addMrzLinesResultPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update identity document photo control
     * @summary Update identity document photo control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdatePhotoPayload} updatePhotoPayload Data to update identity document photo control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updatePhoto(formId: string, documentId: string, updatePhotoPayload: UpdatePhotoPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updatePhoto(formId, documentId, updatePhotoPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control if given document is a photocopy
     * @summary Control photocopy control validity
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {DoubtableValidityPayload} doubtableValidityPayload Data to update doubtable validity for document photocopy control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updatePhotocopy(formId: string, documentId: string, doubtableValidityPayload: DoubtableValidityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updatePhotocopy(formId, documentId, doubtableValidityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer sex
     * @summary Update customer sex
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateSexPayload} updateSexPayload Data to update customer sex
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateSex(formId: string, documentId: string, updateSexPayload: UpdateSexPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateSex(formId, documentId, updateSexPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control if identity document side is valid
     * @summary Control identity document sides validity
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {UpdateSidesValidityPayload} updateSidesValidityPayload Data to update validity for identity document side control
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateSides(formId: string, documentId: string, updateSidesValidityPayload: UpdateSidesValidityPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateSides(formId, documentId, updateSidesValidityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save signature validity control
     * @summary Save signature validity control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {SignatureControlResultPayload} signatureControlResultPayload Data to update compliance status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public updateSignatureValidity(formId: string, documentId: string, signatureControlResultPayload: SignatureControlResultPayload, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).updateSignatureValidity(formId, documentId, signatureControlResultPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply surname validity control
     * @summary Apply surname validity control
     * @param {string} formId Identifier of the registration
     * @param {string} documentId Identifier of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlResourceApi
     */
    public validateOcrSurnames(formId: string, documentId: string, options?: AxiosRequestConfig) {
        return ControlResourceApiFp(this.configuration).validateOcrSurnames(formId, documentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacialCaptureResourceApi - axios parameter creator
 * @export
 */
export const FacialCaptureResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadFacialCapture: async (registrationFormId: string, facialCaptureId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('downloadFacialCapture', 'registrationFormId', registrationFormId)
            // verify required parameter 'facialCaptureId' is not null or undefined
            assertParamExists('downloadFacialCapture', 'facialCaptureId', facialCaptureId)
            const localVarPath = `/registration-forms/{registrationFormId}/facial-captures/{facialCaptureId}/download`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"facialCaptureId"}}`, encodeURIComponent(String(facialCaptureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacialCaptures: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFacialCaptures', 'id', id)
            const localVarPath = `/registration-forms/{id}/facial-captures`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacialCaptureResourceApi - functional programming interface
 * @export
 */
export const FacialCaptureResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacialCaptureResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async downloadFacialCapture(registrationFormId: string, facialCaptureId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFacialCapture(registrationFormId, facialCaptureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacialCaptures(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacialCaptureListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacialCaptures(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacialCaptureResourceApi - factory interface
 * @export
 */
export const FacialCaptureResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacialCaptureResourceApiFp(configuration)
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadFacialCapture(registrationFormId: string, facialCaptureId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadFacialCapture(registrationFormId, facialCaptureId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacialCaptures(id: string, options?: any): AxiosPromise<FacialCaptureListView> {
            return localVarFp.getFacialCaptures(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacialCaptureResourceApi - object-oriented interface
 * @export
 * @class FacialCaptureResourceApi
 * @extends {BaseAPI}
 */
export class FacialCaptureResourceApi extends BaseAPI {
    /**
     * Download registration facial capture
     * @summary Download registration facial capture
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} facialCaptureId Identifier of the facial capture
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FacialCaptureResourceApi
     */
    public downloadFacialCapture(registrationFormId: string, facialCaptureId: string, options?: AxiosRequestConfig) {
        return FacialCaptureResourceApiFp(this.configuration).downloadFacialCapture(registrationFormId, facialCaptureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get facial capture list for a registration
     * @summary Get facial capture list for a registration
     * @param {string} id Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacialCaptureResourceApi
     */
    public getFacialCaptures(id: string, options?: AxiosRequestConfig) {
        return FacialCaptureResourceApiFp(this.configuration).getFacialCaptures(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacialCaptureResourceV1Api - axios parameter creator
 * @export
 */
export const FacialCaptureResourceV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFacialCaptureImageParts: async (registrationFormId: string, facialCaptureId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('downloadFacialCaptureImageParts', 'registrationFormId', registrationFormId)
            // verify required parameter 'facialCaptureId' is not null or undefined
            assertParamExists('downloadFacialCaptureImageParts', 'facialCaptureId', facialCaptureId)
            const localVarPath = `/v1/registration-forms/{registrationFormId}/facial-captures/{facialCaptureId}/download`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"facialCaptureId"}}`, encodeURIComponent(String(facialCaptureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacialCaptureResourceV1Api - functional programming interface
 * @export
 */
export const FacialCaptureResourceV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacialCaptureResourceV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFacialCaptureImageParts(registrationFormId: string, facialCaptureId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagePartsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFacialCaptureImageParts(registrationFormId, facialCaptureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacialCaptureResourceV1Api - factory interface
 * @export
 */
export const FacialCaptureResourceV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacialCaptureResourceV1ApiFp(configuration)
    return {
        /**
         * Download registration facial capture
         * @summary Download registration facial capture
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} facialCaptureId Identifier of the facial capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFacialCaptureImageParts(registrationFormId: string, facialCaptureId: string, options?: any): AxiosPromise<ImagePartsView> {
            return localVarFp.downloadFacialCaptureImageParts(registrationFormId, facialCaptureId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacialCaptureResourceV1Api - object-oriented interface
 * @export
 * @class FacialCaptureResourceV1Api
 * @extends {BaseAPI}
 */
export class FacialCaptureResourceV1Api extends BaseAPI {
    /**
     * Download registration facial capture
     * @summary Download registration facial capture
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} facialCaptureId Identifier of the facial capture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacialCaptureResourceV1Api
     */
    public downloadFacialCaptureImageParts(registrationFormId: string, facialCaptureId: string, options?: AxiosRequestConfig) {
        return FacialCaptureResourceV1ApiFp(this.configuration).downloadFacialCaptureImageParts(registrationFormId, facialCaptureId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationDocumentResourceApi - axios parameter creator
 * @export
 */
export const RegistrationDocumentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download Onfido check report file
         * @summary Download Onfido check report file
         * @param {string} registrationFormId Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOnfidoCheckReportFile: async (registrationFormId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('downloadOnfidoCheckReportFile', 'registrationFormId', registrationFormId)
            const localVarPath = `/registration-forms/{registrationFormId}/onfido-check-report-file`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadRegistrationDocumentFile: async (registrationFormId: string, registrationDocumentId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFile', 'registrationFormId', registrationFormId)
            // verify required parameter 'registrationDocumentId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFile', 'registrationDocumentId', registrationDocumentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFile', 'fileId', fileId)
            const localVarPath = `/registration-forms/{registrationFormId}/registration-documents/{registrationDocumentId}/files/{fileId}/download`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"registrationDocumentId"}}`, encodeURIComponent(String(registrationDocumentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocument: async (registrationFormId: string, registrationDocumentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('getRegistrationDocument', 'registrationFormId', registrationFormId)
            // verify required parameter 'registrationDocumentId' is not null or undefined
            assertParamExists('getRegistrationDocument', 'registrationDocumentId', registrationDocumentId)
            const localVarPath = `/registration-forms/{registrationFormId}/registration-documents/{registrationDocumentId}`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"registrationDocumentId"}}`, encodeURIComponent(String(registrationDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration document OCR properties
         * @summary Get OCR properties
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocumentOcrProperties: async (registrationFormId: string, registrationDocumentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('getRegistrationDocumentOcrProperties', 'registrationFormId', registrationFormId)
            // verify required parameter 'registrationDocumentId' is not null or undefined
            assertParamExists('getRegistrationDocumentOcrProperties', 'registrationDocumentId', registrationDocumentId)
            const localVarPath = `/registration-forms/{registrationFormId}/registration-documents/{registrationDocumentId}/ocr-properties`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"registrationDocumentId"}}`, encodeURIComponent(String(registrationDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration document list
         * @summary Get registration document list
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocuments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegistrationDocuments', 'id', id)
            const localVarPath = `/registration-forms/{id}/registration-documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationDocumentResourceApi - functional programming interface
 * @export
 */
export const RegistrationDocumentResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationDocumentResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Download Onfido check report file
         * @summary Download Onfido check report file
         * @param {string} registrationFormId Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOnfidoCheckReportFile(registrationFormId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOnfidoCheckReportFile(registrationFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async downloadRegistrationDocumentFile(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRegistrationDocumentFile(registrationFormId, registrationDocumentId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationDocument(registrationFormId: string, registrationDocumentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationDocumentView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationDocument(registrationFormId, registrationDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration document OCR properties
         * @summary Get OCR properties
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationDocumentOcrProperties(registrationFormId: string, registrationDocumentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationDocumentOcrPropertiesView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationDocumentOcrProperties(registrationFormId, registrationDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration document list
         * @summary Get registration document list
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationDocuments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationDocumentListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationDocumentResourceApi - factory interface
 * @export
 */
export const RegistrationDocumentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationDocumentResourceApiFp(configuration)
    return {
        /**
         * Download Onfido check report file
         * @summary Download Onfido check report file
         * @param {string} registrationFormId Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOnfidoCheckReportFile(registrationFormId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOnfidoCheckReportFile(registrationFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadRegistrationDocumentFile(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadRegistrationDocumentFile(registrationFormId, registrationDocumentId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get facial capture list for a registration
         * @summary Get facial capture list for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocument(registrationFormId: string, registrationDocumentId: string, options?: any): AxiosPromise<RegistrationDocumentView> {
            return localVarFp.getRegistrationDocument(registrationFormId, registrationDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration document OCR properties
         * @summary Get OCR properties
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocumentOcrProperties(registrationFormId: string, registrationDocumentId: string, options?: any): AxiosPromise<RegistrationDocumentOcrPropertiesView> {
            return localVarFp.getRegistrationDocumentOcrProperties(registrationFormId, registrationDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration document list
         * @summary Get registration document list
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationDocuments(id: string, options?: any): AxiosPromise<RegistrationDocumentListView> {
            return localVarFp.getRegistrationDocuments(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationDocumentResourceApi - object-oriented interface
 * @export
 * @class RegistrationDocumentResourceApi
 * @extends {BaseAPI}
 */
export class RegistrationDocumentResourceApi extends BaseAPI {
    /**
     * Download Onfido check report file
     * @summary Download Onfido check report file
     * @param {string} registrationFormId Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceApi
     */
    public downloadOnfidoCheckReportFile(registrationFormId: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceApiFp(this.configuration).downloadOnfidoCheckReportFile(registrationFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get facial capture list for a registration
     * @summary Get facial capture list for a registration
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} registrationDocumentId Identifier of the registration document
     * @param {string} fileId Identifier of the file
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceApi
     */
    public downloadRegistrationDocumentFile(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceApiFp(this.configuration).downloadRegistrationDocumentFile(registrationFormId, registrationDocumentId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get facial capture list for a registration
     * @summary Get facial capture list for a registration
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} registrationDocumentId Identifier of the registration document ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceApi
     */
    public getRegistrationDocument(registrationFormId: string, registrationDocumentId: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceApiFp(this.configuration).getRegistrationDocument(registrationFormId, registrationDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration document OCR properties
     * @summary Get OCR properties
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} registrationDocumentId Identifier of the registration document ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceApi
     */
    public getRegistrationDocumentOcrProperties(registrationFormId: string, registrationDocumentId: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceApiFp(this.configuration).getRegistrationDocumentOcrProperties(registrationFormId, registrationDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration document list
     * @summary Get registration document list
     * @param {string} id Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceApi
     */
    public getRegistrationDocuments(id: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceApiFp(this.configuration).getRegistrationDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationDocumentResourceV1Api - axios parameter creator
 * @export
 */
export const RegistrationDocumentResourceV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get registration document for a registration
         * @summary Get registration document for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegistrationDocumentFileParts: async (registrationFormId: string, registrationDocumentId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationFormId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFileParts', 'registrationFormId', registrationFormId)
            // verify required parameter 'registrationDocumentId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFileParts', 'registrationDocumentId', registrationDocumentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadRegistrationDocumentFileParts', 'fileId', fileId)
            const localVarPath = `/v1/registration-forms/{registrationFormId}/registration-documents/{registrationDocumentId}/files/{fileId}/download`
                .replace(`{${"registrationFormId"}}`, encodeURIComponent(String(registrationFormId)))
                .replace(`{${"registrationDocumentId"}}`, encodeURIComponent(String(registrationDocumentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationDocumentResourceV1Api - functional programming interface
 * @export
 */
export const RegistrationDocumentResourceV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationDocumentResourceV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * Get registration document for a registration
         * @summary Get registration document for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRegistrationDocumentFileParts(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagePartsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRegistrationDocumentFileParts(registrationFormId, registrationDocumentId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationDocumentResourceV1Api - factory interface
 * @export
 */
export const RegistrationDocumentResourceV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationDocumentResourceV1ApiFp(configuration)
    return {
        /**
         * Get registration document for a registration
         * @summary Get registration document for a registration
         * @param {string} registrationFormId Identifier of the registration
         * @param {string} registrationDocumentId Identifier of the registration document
         * @param {string} fileId Identifier of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegistrationDocumentFileParts(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: any): AxiosPromise<ImagePartsView> {
            return localVarFp.downloadRegistrationDocumentFileParts(registrationFormId, registrationDocumentId, fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationDocumentResourceV1Api - object-oriented interface
 * @export
 * @class RegistrationDocumentResourceV1Api
 * @extends {BaseAPI}
 */
export class RegistrationDocumentResourceV1Api extends BaseAPI {
    /**
     * Get registration document for a registration
     * @summary Get registration document for a registration
     * @param {string} registrationFormId Identifier of the registration
     * @param {string} registrationDocumentId Identifier of the registration document
     * @param {string} fileId Identifier of the file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationDocumentResourceV1Api
     */
    public downloadRegistrationDocumentFileParts(registrationFormId: string, registrationDocumentId: string, fileId: string, options?: AxiosRequestConfig) {
        return RegistrationDocumentResourceV1ApiFp(this.configuration).downloadRegistrationDocumentFileParts(registrationFormId, registrationDocumentId, fileId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationFormResourceApi - axios parameter creator
 * @export
 */
export const RegistrationFormResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get registration birth data
         * @summary Get registration birth data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthData: async (id: string, stakeholder?: AccountStakeholder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBirthData', 'id', id)
            const localVarPath = `/registration-forms/{id}/birth-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stakeholder !== undefined) {
                localVarQueryParameter['stakeholder'] = stakeholder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration civil data
         * @summary Get registration civil data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilData: async (id: string, stakeholder?: AccountStakeholder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCivilData', 'id', id)
            const localVarPath = `/registration-forms/{id}/civil-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stakeholder !== undefined) {
                localVarQueryParameter['stakeholder'] = stakeholder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get document id
         * @summary Get document id
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentIds: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentIds', 'id', id)
            const localVarPath = `/registration-forms/{id}/document-ids`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration personal number
         * @summary Get registration personal number
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonalNumber', 'id', id)
            const localVarPath = `/registration-forms/{id}/personal-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration data by ID
         * @summary Get registration data by ID
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationForm: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegistrationForm', 'id', id)
            const localVarPath = `/registration-forms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration customer by ID
         * @summary Get registration customer by ID
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegistrationForm', 'id', id)
            const localVarPath = `/registration-forms/{id}/customer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration data by registration code
         * @summary Get registration data by registration code
         * @param {string} registrationCode The registration code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationFormByRegistrationCode: async (registrationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationCode' is not null or undefined
            assertParamExists('getRegistrationFormByRegistrationCode', 'registrationCode', registrationCode)
            const localVarPath = `/registration-forms/code/{registration-code}`
                .replace(`{${"registration-code"}}`, encodeURIComponent(String(registrationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get registration list
         * @summary Search registration
         * @param {CountryCode} [registrationCountry] Registration country
         * @param {ComplianceStatus} [complianceStatus] Compliance status
         * @param {string} [q] Search key word
         * @param {number} [limit] Result element max
         * @param {number} [pageNumber] Number of the page
         * @param {SortingDirection} [sortingDirection] Sorting direction
         * @param {RegistrationFormType} [registrationFormType] Registration form type (ADULT or CHILD)
         * @param {string} [lowerBoundCreationDate] Customer creation date lower bound
         * @param {string} [upperBoundCreationDate] Customer creation date upper bound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationForms: async (registrationCountry?: CountryCode, complianceStatus?: ComplianceStatus, q?: string, limit?: number, pageNumber?: number, sortingDirection?: SortingDirection, registrationFormType?: RegistrationFormType, lowerBoundCreationDate?: string, upperBoundCreationDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/registration-forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registrationCountry !== undefined) {
                localVarQueryParameter['registration-country'] = registrationCountry;
            }

            if (complianceStatus !== undefined) {
                localVarQueryParameter['compliance-status'] = complianceStatus;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page-number'] = pageNumber;
            }

            if (sortingDirection !== undefined) {
                localVarQueryParameter['sorting-direction'] = sortingDirection;
            }

            if (registrationFormType !== undefined) {
                localVarQueryParameter['registration-form-type'] = registrationFormType;
            }

            if (lowerBoundCreationDate !== undefined) {
                localVarQueryParameter['lower-bound-creation-date'] = (lowerBoundCreationDate as any instanceof Date) ?
                    (lowerBoundCreationDate as any).toISOString().substr(0,10) :
                    lowerBoundCreationDate;
            }

            if (upperBoundCreationDate !== undefined) {
                localVarQueryParameter['upper-bound-creation-date'] = (upperBoundCreationDate as any instanceof Date) ?
                    (upperBoundCreationDate as any).toISOString().substr(0,10) :
                    upperBoundCreationDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationFormResourceApi - functional programming interface
 * @export
 */
export const RegistrationFormResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationFormResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get registration birth data
         * @summary Get registration birth data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBirthData(id: string, stakeholder?: AccountStakeholder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BirthDataView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBirthData(id, stakeholder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration civil data
         * @summary Get registration civil data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCivilData(id: string, stakeholder?: AccountStakeholder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilDataView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCivilData(id, stakeholder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get document id
         * @summary Get document id
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentIds(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StakeholderDocumentsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentIds(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration personal number
         * @summary Get registration personal number
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration data by ID
         * @summary Get registration data by ID
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationForm(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFormView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationForm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration customer by ID
         * @summary Get registration customer by ID
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration data by registration code
         * @summary Get registration data by registration code
         * @param {string} registrationCode The registration code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationFormByRegistrationCode(registrationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFormView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationFormByRegistrationCode(registrationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get registration list
         * @summary Search registration
         * @param {CountryCode} [registrationCountry] Registration country
         * @param {ComplianceStatus} [complianceStatus] Compliance status
         * @param {string} [q] Search key word
         * @param {number} [limit] Result element max
         * @param {number} [pageNumber] Number of the page
         * @param {SortingDirection} [sortingDirection] Sorting direction
         * @param {RegistrationFormType} [registrationFormType] Registration form type (ADULT or CHILD)
         * @param {string} [lowerBoundCreationDate] Customer creation date lower bound
         * @param {string} [upperBoundCreationDate] Customer creation date upper bound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationForms(registrationCountry?: CountryCode, complianceStatus?: ComplianceStatus, q?: string, limit?: number, pageNumber?: number, sortingDirection?: SortingDirection, registrationFormType?: RegistrationFormType, lowerBoundCreationDate?: string, upperBoundCreationDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseRegistrationFormListItemView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationForms(registrationCountry, complianceStatus, q, limit, pageNumber, sortingDirection, registrationFormType, lowerBoundCreationDate, upperBoundCreationDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationFormResourceApi - factory interface
 * @export
 */
export const RegistrationFormResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationFormResourceApiFp(configuration)
    return {
        /**
         * Get registration birth data
         * @summary Get registration birth data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthData(id: string, stakeholder?: AccountStakeholder, options?: any): AxiosPromise<BirthDataView> {
            return localVarFp.getBirthData(id, stakeholder, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration civil data
         * @summary Get registration civil data
         * @param {string} id Identifier of the registration
         * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilData(id: string, stakeholder?: AccountStakeholder, options?: any): AxiosPromise<CivilDataView> {
            return localVarFp.getCivilData(id, stakeholder, options).then((request) => request(axios, basePath));
        },
        /**
         * Get document id
         * @summary Get document id
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentIds(id: string, options?: any): AxiosPromise<StakeholderDocumentsView> {
            return localVarFp.getDocumentIds(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration personal number
         * @summary Get registration personal number
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalNumber(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPersonalNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration data by ID
         * @summary Get registration data by ID
         * @param {string} id Identifier of the registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationForm(id: string, options?: any): AxiosPromise<RegistrationFormView> {
            return localVarFp.getRegistrationForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration data by registration code
         * @summary Get registration data by registration code
         * @param {string} registrationCode The registration code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationFormByRegistrationCode(registrationCode: string, options?: any): AxiosPromise<RegistrationFormView> {
            return localVarFp.getRegistrationFormByRegistrationCode(registrationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Get registration list
         * @summary Search registration
         * @param {CountryCode} [registrationCountry] Registration country
         * @param {ComplianceStatus} [complianceStatus] Compliance status
         * @param {string} [q] Search key word
         * @param {number} [limit] Result element max
         * @param {number} [pageNumber] Number of the page
         * @param {SortingDirection} [sortingDirection] Sorting direction
         * @param {RegistrationFormType} [registrationFormType] Registration form type (ADULT or CHILD)
         * @param {string} [lowerBoundCreationDate] Customer creation date lower bound
         * @param {string} [upperBoundCreationDate] Customer creation date upper bound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationForms(registrationCountry?: CountryCode, complianceStatus?: ComplianceStatus, q?: string, limit?: number, pageNumber?: number, sortingDirection?: SortingDirection, registrationFormType?: RegistrationFormType, lowerBoundCreationDate?: string, upperBoundCreationDate?: string, options?: any): AxiosPromise<PageResponseRegistrationFormListItemView> {
            return localVarFp.getRegistrationForms(registrationCountry, complianceStatus, q, limit, pageNumber, sortingDirection, registrationFormType, lowerBoundCreationDate, upperBoundCreationDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationFormResourceApi - object-oriented interface
 * @export
 * @class RegistrationFormResourceApi
 * @extends {BaseAPI}
 */
export class RegistrationFormResourceApi extends BaseAPI {
    /**
     * Get registration birth data
     * @summary Get registration birth data
     * @param {string} id Identifier of the registration
     * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getBirthData(id: string, stakeholder?: AccountStakeholder, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getBirthData(id, stakeholder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration civil data
     * @summary Get registration civil data
     * @param {string} id Identifier of the registration
     * @param {AccountStakeholder} [stakeholder] Registration form type (ADULT or CHILD)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getCivilData(id: string, stakeholder?: AccountStakeholder, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getCivilData(id, stakeholder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get document id
     * @summary Get document id
     * @param {string} id Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getDocumentIds(id: string, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getDocumentIds(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration personal number
     * @summary Get registration personal number
     * @param {string} id Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getPersonalNumber(id: string, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getPersonalNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration data by ID
     * @summary Get registration data by ID
     * @param {string} id Identifier of the registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getRegistrationForm(id: string, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getRegistrationForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration data by registration code
     * @summary Get registration data by registration code
     * @param {string} registrationCode The registration code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getRegistrationFormByRegistrationCode(registrationCode: string, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getRegistrationFormByRegistrationCode(registrationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get registration list
     * @summary Search registration
     * @param {CountryCode} [registrationCountry] Registration country
     * @param {ComplianceStatus} [complianceStatus] Compliance status
     * @param {string} [q] Search key word
     * @param {number} [limit] Result element max
     * @param {number} [pageNumber] Number of the page
     * @param {SortingDirection} [sortingDirection] Sorting direction
     * @param {RegistrationFormType} [registrationFormType] Registration form type (ADULT or CHILD)
     * @param {string} [lowerBoundCreationDate] Customer creation date lower bound
     * @param {string} [upperBoundCreationDate] Customer creation date upper bound
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationFormResourceApi
     */
    public getRegistrationForms(registrationCountry?: CountryCode, complianceStatus?: ComplianceStatus, q?: string, limit?: number, pageNumber?: number, sortingDirection?: SortingDirection, registrationFormType?: RegistrationFormType, lowerBoundCreationDate?: string, upperBoundCreationDate?: string, options?: AxiosRequestConfig) {
        return RegistrationFormResourceApiFp(this.configuration).getRegistrationForms(registrationCountry, complianceStatus, q, limit, pageNumber, sortingDirection, registrationFormType, lowerBoundCreationDate, upperBoundCreationDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsResourceApi - axios parameter creator
 * @export
 */
export const StatisticsResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of not compliance causes for given dates
         * @summary Get list of not compliance causes for given dates
         * @param {string} from Statistics date from
         * @param {string} to Statistics date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotCompliantCauseDistribution: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getNotCompliantCauseDistribution', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getNotCompliantCauseDistribution', 'to', to)
            const localVarPath = `/statistics/not-compliant-cause-distribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsResourceApi - functional programming interface
 * @export
 */
export const StatisticsResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of not compliance causes for given dates
         * @summary Get list of not compliance causes for given dates
         * @param {string} from Statistics date from
         * @param {string} to Statistics date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotCompliantCauseDistribution(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotCompliantCauseDistributionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotCompliantCauseDistribution(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsResourceApi - factory interface
 * @export
 */
export const StatisticsResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsResourceApiFp(configuration)
    return {
        /**
         * Get list of not compliance causes for given dates
         * @summary Get list of not compliance causes for given dates
         * @param {string} from Statistics date from
         * @param {string} to Statistics date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotCompliantCauseDistribution(from: string, to: string, options?: any): AxiosPromise<NotCompliantCauseDistributionView> {
            return localVarFp.getNotCompliantCauseDistribution(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsResourceApi - object-oriented interface
 * @export
 * @class StatisticsResourceApi
 * @extends {BaseAPI}
 */
export class StatisticsResourceApi extends BaseAPI {
    /**
     * Get list of not compliance causes for given dates
     * @summary Get list of not compliance causes for given dates
     * @param {string} from Statistics date from
     * @param {string} to Statistics date to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsResourceApi
     */
    public getNotCompliantCauseDistribution(from: string, to: string, options?: AxiosRequestConfig) {
        return StatisticsResourceApiFp(this.configuration).getNotCompliantCauseDistribution(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


