import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '@nickel/core-ui/components/Modal'
import Checkbox from '@nickel/ui/components/legacy/form/Checkbox'

import { NameModificationReason } from '../../services/api'
import { actions as lastNamesActions, selectors as lastNamesSelectors } from '../../store/lastNames'

const Modals = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const popinState: boolean = useSelector(lastNamesSelectors.getPopinState)

    const [validationButtonDisabled, setValidationButtonDisabled] = useState(true)

    const [inattentivenessError, setInattentivenessError] = useState(false)
    const [maritalNameInversion, setMaritalNameInversion] = useState(false)
    const [usageNameInversion, setUsageNameInversion] = useState(false)
    const [lastNameFirstNameSameInput, setLastNameFirstNameSameInput] = useState(false)
    const [other, setOther] = useState(false)

    useEffect(() => {
        return () => {
            setInattentivenessError(false)
            setMaritalNameInversion(false)
            setUsageNameInversion(false)
            setLastNameFirstNameSameInput(false)
            setOther(false)
        }
    }, [popinState])

    useEffect(() => {
        const reasons: NameModificationReason[] = []
        if (inattentivenessError) {
            reasons.push(NameModificationReason.INATTENTIVENESS_ERROR)
        }
        if (maritalNameInversion) {
            reasons.push(NameModificationReason.MARITAL_NAME_INVERSION)
        }
        if (usageNameInversion) {
            reasons.push(NameModificationReason.USAGE_NAME_INVERSION)
        }
        if (lastNameFirstNameSameInput) {
            reasons.push(NameModificationReason.LAST_NAME_FIRST_NAME_SAME_INPUT)
        }
        if (other) {
            reasons.push(NameModificationReason.OTHER)
        }
        setValidationButtonDisabled(
            !inattentivenessError &&
                !maritalNameInversion &&
                !usageNameInversion &&
                !lastNameFirstNameSameInput &&
                !other
        )
        dispatch(lastNamesActions.setModificationReasons(reasons))
    }, [dispatch, inattentivenessError, maritalNameInversion, usageNameInversion, lastNameFirstNameSameInput, other])

    return (
        <Modal
            open={popinState}
            showCloseButton
            onClose={() => dispatch(lastNamesActions.openCloseControlPopin())}
            testId="Modal"
            title={t('controls:modifiedData.modalTitle')}
            primaryButton={{
                onClick: () => dispatch(lastNamesActions.popinValidation()),
                testId: 'ModalPrimaryActionButton',
                text: t('common:confirm'),
                disabled: validationButtonDisabled
            }}
            secondaryButton={{
                onClick: () => dispatch(lastNamesActions.openCloseControlPopin()),
                testId: 'ModalSecondaryActionButton',
                text: t('common:cancel'),
                variant: 'secondary'
            }}
        >
            <p>{t('controls:modifiedData.modalSubTitle')}</p>
            <Checkbox
                label={t('controls:modifiedData.reasons.inattentivenessError')}
                onChange={setInattentivenessError}
                checked={inattentivenessError}
            />
            <Checkbox
                label={t('controls:modifiedData.reasons.maritalNameInversion')}
                onChange={setMaritalNameInversion}
                checked={maritalNameInversion}
            />
            <Checkbox
                label={t('controls:modifiedData.reasons.usageNameInversion')}
                onChange={setUsageNameInversion}
                checked={usageNameInversion}
            />
            <Checkbox
                label={t('controls:modifiedData.reasons.lastNameFirstNameSameInput')}
                onChange={setLastNameFirstNameSameInput}
                checked={lastNameFirstNameSameInput}
            />
            <Checkbox label={t('controls:modifiedData.reasons.other')} onChange={setOther} checked={other} />
        </Modal>
    )
}

export default Modals
