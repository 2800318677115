import { all, fork } from 'redux-saga/effects'

import { stimulusInterceptors } from '@nickel/stimulus/stimulus'

import activityReportSagas from './activityReport/sagas'
import controlsSagas from './controls/sagas'
import detailsSagas from './details/sagas'
import firstNamesSagas from './firstNames/sagas'
import lastNamesSagas from './lastNames/sagas'
import navigationSagas from './navigation/sagas'
import ocrPropertiesSagas from './ocrProperties/sagas'
import onfidoReportSagas from './onfidoReport/sagas'
import registrationFormsSagas from './registrationForms/sagas'

export default function* sagas() {
    yield all([
        // Stimuli
        ...stimulusInterceptors,

        // Registration Forms
        fork(registrationFormsSagas),

        // Controls
        fork(controlsSagas),

        // Documents Details
        fork(detailsSagas),

        // First names
        fork(firstNamesSagas),

        // Last names
        fork(lastNamesSagas),

        // Navigation
        fork(navigationSagas),

        // OCR Properties
        fork(ocrPropertiesSagas),

        // Activity Report
        fork(activityReportSagas),

        // Onfido Report
        fork(onfidoReportSagas)
    ])
}
