import { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { registrationFormResourceApi } from '../../services'
import { RegistrationFormListItemView } from '../../services/api'
import { selectors as registrationFormsSelectors } from '../../store/registrationForms'

import { useDebounce } from './utils/useDebounce'

const QUERY_KEY = 'getRegistrationForms'

export const QUERY_DEFAULT_LIMIT = 50

export const toISODateWithoutTime = (date?: string) => {
    if (!date) return undefined
    const [dateWithoutTime] = date.split('T')
    return dateWithoutTime
}

export const getLowerBound = (dateRange?: [string, string]) => {
    if (!dateRange) return undefined
    return toISODateWithoutTime(dateRange[0])
}

export const getUpperBound = (dateRange?: [string, string]) => {
    if (!dateRange) return undefined
    return toISODateWithoutTime(dateRange[1])
}

const useRegistrationFormList = () => {
    const [registrationForms, setRegistrationForms] = useState<RegistrationFormListItemView[]>([])

    const complianceStatus = useSelector(registrationFormsSelectors.getSearchComplianceStatus)
    const registrationFormType = useSelector(registrationFormsSelectors.getSearchRegistrationFormType)
    const queryString = useSelector(registrationFormsSelectors.getSearchQuery) ?? ''
    const sortingDirection = useSelector(registrationFormsSelectors.getSearchSort)
    const registrationCountry = useSelector(registrationFormsSelectors.getSearchCountry)
    const dateRangeCreationDate = useSelector(registrationFormsSelectors.getDateRangeCreationDate)
    const lowerBoundCreationDate = getLowerBound(dateRangeCreationDate)
    const upperBoundCreationDate = getUpperBound(dateRangeCreationDate)

    const debounceQueryString = useDebounce(queryString, 500)

    const query = (pageNumber: number) => async () =>
        (
            await registrationFormResourceApi.getRegistrationForms(
                registrationCountry,
                complianceStatus === '' ? undefined : complianceStatus,
                debounceQueryString,
                QUERY_DEFAULT_LIMIT,
                pageNumber,
                sortingDirection,
                registrationFormType === '' ? undefined : registrationFormType,
                lowerBoundCreationDate,
                upperBoundCreationDate
            )
        )()

    const { data, isError, isLoading } = useQuery({
        queryKey: [
            QUERY_KEY,
            registrationCountry,
            complianceStatus,
            debounceQueryString,
            sortingDirection,
            registrationFormType,
            lowerBoundCreationDate,
            upperBoundCreationDate
        ],
        queryFn: query(1),
        onSuccess: (res) => setRegistrationForms(res.data.content ?? [])
    })

    const { mutate: showMore } = useMutation({
        mutationKey: QUERY_KEY,
        mutationFn: query(Math.ceil(registrationForms.length / QUERY_DEFAULT_LIMIT) + 1),
        onSuccess: (res) => setRegistrationForms((old) => [...old, ...(res.data.content ?? [])])
    })

    return {
        data: {
            registrationForms,
            totalCount: data?.data?.paging?.totalElements
        },
        isError,
        isLoading,
        showMore: () => showMore()
    }
}

export default useRegistrationFormList
