import { actions as uiActions } from '@nickel/stimulus/ui'

import { RegistrationControl, UpdateDocumentNumberPositionPayload, ControlValidity } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import modals from './modals'

const control: Control = {
    actions: {
        invalidate: [
            {
                action: uiActions.showModal(modals.invalidate),
                label: 'common:no'
            }
        ],
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.DOCUMENT_NUMBER_POSITION,
                    controlPayload: {
                        validity: ControlValidity.VALID
                    } as UpdateDocumentNumberPositionPayload
                }),
                label: 'common:yes'
            }
        ]
    },
    hasDocumentChecker: true
}

export default control
