import { ComplianceStatus, RegistrationFormType } from '../services/api'

export const getComplianceStatusColor = (complianceStatus?: ComplianceStatus) => {
    switch (complianceStatus) {
        case ComplianceStatus.COMPLIANT:
            return '#5ACD00'

        case ComplianceStatus.NOT_COMPLIANT:
            return '#E30000'
        case ComplianceStatus.TODO:
            return '#FF8A00'
        default:
            return '#000000'
    }
}

export const getRegistrationFormTypeColor = (formType?: RegistrationFormType) => {
    switch (formType) {
        case RegistrationFormType.ADULT:
            return '#3FA756'

        case RegistrationFormType.CHILD:
            return '#00AFFA'
        default:
            return '#000000'
    }
}
