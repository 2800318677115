import React from 'react'

import Skeleton from 'react-loading-skeleton'

import Copy from '@nickel/core-ui/components/Copy'
import { Copy as CopyIcon } from '@nickel/core-ui/components/Icons/components'
import { IconSize } from '@nickel/core-ui/components/Icons/types'
import { TooltipPosition } from '@nickel/core-ui/components/Tooltip/types'
import { formatDataTestId } from '@nickel/core-ui/utils'
import { Color } from '@nickel/foundations/tokens'

import Styled from './styles'

const CopiableInfo = ({
    tooltipPosition,
    info,
    successText,
    failureText,
    testId
}: {
    tooltipPosition: TooltipPosition
    info?: string
    successText: string
    failureText: string
    testId: string
}) =>
    info ? (
        <Styled.CopyContainer tooltipPosition={tooltipPosition} testId={testId}>
            <Copy
                value={info ?? ''}
                testId={formatDataTestId(testId, 'copy')}
                successText={successText}
                failureText={failureText}
            >
                <Styled.Info>
                    {info}
                    <CopyIcon size={IconSize.SMALL} color={Color.SECONDARY_MEDIUM} />
                </Styled.Info>
            </Copy>
        </Styled.CopyContainer>
    ) : (
        <Skeleton width={200} height={20} />
    )

export default CopiableInfo
