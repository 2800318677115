import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import { reducer as ui } from '@nickel/stimulus/ui'

import activityReport from './activityReport'
import control from './controls'
import details from './details'
import documents from './documents'
import firstNames from './firstNames'
import history from './history'
import lastNames from './lastNames'
import ocrProperties from './ocrProperties'
import onfidoReport from './onfidoReport'
import registrationForms from './registrationForms'

const rootReducer = combineReducers({
    activityReport,
    control,
    details,
    documents,
    firstNames,
    lastNames,
    ocrProperties,
    onfidoReport,
    registrationForms,
    router: connectRouter(history),
    ui
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
