import { format } from 'date-fns'
import { isEmpty } from 'ramda'

import { COUNTRY_CODE } from '@nickel/l10n/constants'

import { getDateFormat } from '../../l10n/date-utils'
import {
    RegistrationControl,
    RegistrationDocumentOcrPropertiesView,
    RegistrationDocumentType
} from '../../services/api'

import { BirthDataControlProperties, CivilData, ControlDocumentProperties, ControlProperties } from '.'

const OVERRIDDEN_OCR_DATA_WARNINGS_CONTROLS: RegistrationControl[] = [
    RegistrationControl.BIRTH_DATA,
    RegistrationControl.FIRST_NAMES,
    RegistrationControl.LAST_NAMES,
    RegistrationControl.SEX,
    RegistrationControl.DOCUMENT_NUMBER
]

export const isArrayFilled = (value: string[]) => value.some((entry) => !isEmpty(entry))
export const isFieldFilled = (value: string) => !isEmpty(value)

export const getOverriddenOcrDataWarnings = (
    controlId: RegistrationControl,
    properties: ControlProperties,
    ocrProperties: RegistrationDocumentOcrPropertiesView,
    issuingCountry: string
) => {
    if (!OVERRIDDEN_OCR_DATA_WARNINGS_CONTROLS.includes(controlId)) return []

    const warnings: string[] = []
    const { birthDataProperties, civilDataProperties, taxIdentificationProperties, documentProperties } = properties
    switch (controlId) {
        case RegistrationControl.BIRTH_DATA:
            checkBirthData(ocrProperties, birthDataProperties, warnings)
            break
        case RegistrationControl.FIRST_NAMES:
            checkFirstName(ocrProperties, civilDataProperties, warnings)
            break
        case RegistrationControl.LAST_NAMES:
            checkLastName(ocrProperties, civilDataProperties, warnings)
            break
        case RegistrationControl.DOCUMENT_NUMBER:
            checkPersonalNumber(
                ocrProperties,
                taxIdentificationProperties,
                documentProperties,
                issuingCountry,
                warnings
            )
            break
        default:
            break
    }

    return warnings
}

const checkBirthData = (
    ocrProperties: RegistrationDocumentOcrPropertiesView,
    birthDataProperties: BirthDataControlProperties | undefined,
    warnings: string[]
) => {
    if (!!ocrProperties?.birthDate && ocrProperties.birthDate !== birthDataProperties?.birthDate)
        warnings.push(format(new Date(ocrProperties.birthDate), getDateFormat()))

    if (
        !!ocrProperties?.nationality &&
        birthDataProperties?.nationalities &&
        ocrProperties.nationality !== birthDataProperties?.nationalities[0]
    )
        warnings.push(ocrProperties.nationality)
}

const checkFirstName = (
    ocrProperties: RegistrationDocumentOcrPropertiesView,
    civilDataProperties: CivilData | undefined,
    warnings: string[]
) => {
    if (
        !!ocrProperties?.firstNames &&
        isArrayFilled(ocrProperties.firstNames) &&
        ocrProperties.firstNames.join(' ').toLowerCase() !== civilDataProperties?.firstNames?.join(' ').toLowerCase()
    )
        warnings.push(...ocrProperties.firstNames)
}

const checkLastName = (
    ocrProperties: RegistrationDocumentOcrPropertiesView,
    civilDataProperties: CivilData | undefined,
    warnings: string[]
) => {
    if (
        !!ocrProperties?.lastNames &&
        isArrayFilled(ocrProperties.lastNames) &&
        ocrProperties.lastNames.join(' ').toLowerCase() !== civilDataProperties?.lastNames?.join(' ').toLowerCase()
    )
        warnings.push(...ocrProperties.lastNames)
}
const checkPersonalNumber = (
    ocrProperties: RegistrationDocumentOcrPropertiesView,
    taxIdentificationProperties: string | undefined,
    documentProperties: ControlDocumentProperties | undefined,
    issuingCountry: string,
    warnings: string[]
) => {
    const isNicOrResidencePermit =
        documentProperties?.type &&
        (documentProperties?.type === RegistrationDocumentType.NATIONAL_IDENTITY_CARD ||
            documentProperties?.type === RegistrationDocumentType.RESIDENCE_PERMIT)

    const shouldAddPersonalNumberWarning =
        (issuingCountry === COUNTRY_CODE.PT || issuingCountry === COUNTRY_CODE.BE) && isNicOrResidencePermit

    if (
        shouldAddPersonalNumberWarning &&
        !!ocrProperties?.personalNumber &&
        isFieldFilled(ocrProperties.personalNumber) &&
        `${ocrProperties?.personalNumber}` !== `${taxIdentificationProperties}`
    )
        warnings.push(ocrProperties.personalNumber)
}
