import { matchPath } from 'react-router-dom'

import { PATHS } from './constants'

const isMatchedPath = (path: string, targetPath: string): boolean => {
    const match = matchPath(path, {
        path: targetPath,
        exact: true
    })

    return match?.isExact ?? false
}

export const isControl = (path: string): boolean => isMatchedPath(path, PATHS.CONTROL)

export const isDetails = (path: string): boolean => isMatchedPath(path, PATHS.DETAILS)

export const isHome = (path: string): boolean => isMatchedPath(path, PATHS.HOME)
