import styled from 'styled-components'

import { Color, Spacing } from '@nickel/ui/tokens'

const StepCounter = styled.h2`
    color: ${Color.SECONDARY_TEXT};
    white-space: nowrap;
`

const Title = styled.div`
    align-items: baseline;
    display: flex;

    h1 {
        flex-grow: 1;
        margin-left: ${Spacing.LARGE};
        text-align: left;
    }
`

export default {
    StepCounter,
    Title
}
