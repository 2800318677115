import styled, { css } from 'styled-components'

import Danger from '@nickel/ui/components/icons/Danger'
import { Color, Spacing } from '@nickel/ui/tokens'

const ICON_SIZE = Spacing.XLARGE

type TooltipProps = {
    isLeftPosition?: boolean
}

const Container = styled.div`
    display: inline;
    height: ${ICON_SIZE};
    position: relative;
    width: ${ICON_SIZE};
`

const Icon = styled(Danger)`
    cursor: pointer;
    fill: ${Color.PRIMARY};
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
`

const TooltipLeft = css`
    left: -${Spacing.MEDIUM};
    top: 50%;
    transform: translate(-100%, -50%);
`

const TooltipTop = css`
    left: 50%;
    top: -${Spacing.MEDIUM};
    transform: translate(-50%, -100%);
`

const Tooltip = styled.div<TooltipProps>`
    background: rgb(242, 242, 242);
    border: 1px solid ${Color.OUTLINE};
    border-radius: 0.2em;
    padding: 1em;
    position: absolute;
    white-space: nowrap;
    ${(props) => (props.isLeftPosition ? TooltipLeft : TooltipTop)}

    > * {
        margin: 0 0 0.5em;

        &:last-child {
            margin: 0;
        }
    }
`

export default {
    Container,
    Icon,
    Tooltip
}
