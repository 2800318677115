import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@nickel/core-ui/components/Button'
import { ChevronLeft, X } from '@nickel/core-ui/components/Icons'

import { ROUTES } from '../../../navigation/constants'
import { actions as controlsActions, selectors as controlSelectors } from '../../../store/controls'
import { actions as navigationActions, selectors as navigationSelectors } from '../../../store/navigation'
import { RootState } from '../../../store/rootReducer'

import Styled from './styles'

const Footer = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const registrationFormId = useSelector(navigationSelectors.getCurrentRegistrationFormId)
    const isFirst = useSelector<RootState, boolean>(controlSelectors.getIsFirst)

    const onBack = useCallback(
        () =>
            dispatch(
                navigationActions.goTo({
                    pathname: `/${ROUTES.DETAILS}/${registrationFormId}`
                })
            ),
        [dispatch, registrationFormId]
    )

    return (
        <Styled.Container>
            <Styled.ControlButtons>
                {!isFirst && (
                    <Button
                        onClick={() => dispatch(controlsActions.getPreviousControlAsyncRequest())}
                        text={t('common:previous')}
                        variant="ghost"
                        testId="previousFromControlButton"
                        Icon={ChevronLeft}
                    />
                )}
                <Button
                    onClick={onBack}
                    testId="backFromControlButton"
                    text={t('common:back')}
                    variant="ghost"
                    Icon={X}
                />
            </Styled.ControlButtons>
        </Styled.Container>
    )
}

export default Footer
