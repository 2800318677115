import React from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RegistrationControl } from '../../../services/api'
import { selectors as controlSelectors } from '../../../store/controls'
import { RootState } from '../../../store/rootReducer'

import Styled from './styles'

type Props = {
    controlId: RegistrationControl
}

const Header = ({ controlId }: Props) => {
    const { t } = useTranslation()

    const progressLabel = useSelector<RootState, string | undefined>(controlSelectors.getProgressLabel)
    const documentTypeContext = useSelector<RootState, string | undefined>(controlSelectors.getDocumentTypeContext)

    return (
        <Styled.Title>
            {progressLabel && <Styled.StepCounter>{progressLabel}</Styled.StepCounter>}
            <h1 data-testid="controlTitle">{t(`controls:${controlId}.title`, { context: documentTypeContext })}</h1>
        </Styled.Title>
    )
}

export default Header
