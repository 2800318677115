import React, { useCallback, useMemo } from 'react'

import { map, pipe } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import { getCountries, optionifyWithTranslatedLabel } from '@nickel/l10n/utils'
import Select from '@nickel/ui/components/legacy/form/Select'
import { Option } from '@nickel/ui/types'

import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'

const Form = () => {
    const dispatch = useDispatch()
    const issuingCountry = useSelector(controlsSelectors.getIssuingCountry)

    const onIssuingCountryChange = useCallback(
        (country) => {
            dispatch(controlsActions.setIssuingCountry({ issuingCountry: country }))
        },
        [dispatch]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const options: Option<string>[] = useMemo(pipe(getCountries, map(optionifyWithTranslatedLabel)), [])

    return (
        <Select
            name="issuing-country"
            options={options}
            searchable
            value={issuingCountry}
            onChange={onIssuingCountryChange}
        />
    )
}

export default Form
