/*eslint-disable*/
import styled from 'styled-components'

import { TooltipPosition } from '@nickel/core-ui/components/Tooltip/types'
import {
    getArrowTooltip,
    getPositionArrowTooltip,
    getPositionTooltip
} from '@nickel/core-ui/components/Tooltip/utils.styles'
import { Spacing } from '@nickel/ui/tokens'

const Info = styled.div`
    align-items: center;
    background-color: rgb(242, 242, 242);
    display: flex;
    gap: ${Spacing.MEDIUM};
    height: max-content;
    padding: 0.2rem;
`
const CopyContainer = styled.div<{ tooltipPosition: TooltipPosition; testId: string }>`
   
    ${({ testId, tooltipPosition }) => `
    div[data-testid='${testId}#copy#content'] {
        ${getPositionTooltip(tooltipPosition)};
        transform: translate(-50%, calc(50% + 1rem));
        height: 50%;
        &:before {
            border-top-style: none;
            border-top-color: transparent;
            ${getArrowTooltip(tooltipPosition, false)}
            ${getPositionArrowTooltip(tooltipPosition)}
        }
    }`}
`

export default { CopyContainer, Info }
