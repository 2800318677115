import { TemplateType } from '../../config/templates'
import {
    RegistrationControl,
    UpdateFamilyDocumentReadabilityPayload,
    FamilyDocumentReadability
} from '../../services/api'
import { actions as controlsActions } from '../../store/controls'

const notReadable = {
    message: 'controls:FAMILY_DOCUMENT_READABILITY.modals.not-readable.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.FAMILY_DOCUMENT_READABILITY,
            controlPayload: {
                readability: FamilyDocumentReadability.NOT_READABLE
            } as UpdateFamilyDocumentReadabilityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:FAMILY_DOCUMENT_READABILITY.title'
}

export default {
    notReadable
}
