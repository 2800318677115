import { TemplateType } from '../../config/templates'
import { DoubtableValidityPayload, DoubtableValidity, RegistrationControl } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'

const isFraudConfirmationModal = {
    isWithI18nContext: true,
    message: 'controls:PHOTOCOPY.modals.fraud.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.PHOTOCOPY,
            controlPayload: {
                validity: DoubtableValidity.INVALID
            } as DoubtableValidityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:PHOTOCOPY.modals.fraud.title'
}

const isPhotocopyConfirmationModal = {
    isWithI18nContext: true,
    message: 'controls:PHOTOCOPY.modals.photocopy.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.PHOTOCOPY,
            controlPayload: {
                validity: DoubtableValidity.DOUBT
            } as DoubtableValidityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:PHOTOCOPY.modals.photocopy.title'
}

export default {
    isFraudConfirmationModal,
    isPhotocopyConfirmationModal
}
