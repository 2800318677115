import { createAction, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { UpdateFirstNamesPayloadModificationReasonsEnum } from '../../services/api'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

type FirstNameState = {
    modificationReason: UpdateFirstNamesPayloadModificationReasonsEnum[]
    popinState: boolean
}

export const INITIAL_STATE: FirstNameState = { modificationReason: [], popinState: false }

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@first-names'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setModificationReasons: (
            state,
            { payload }: PayloadAction<UpdateFirstNamesPayloadModificationReasonsEnum[]>
        ): FirstNameState => ({
            ...state,
            modificationReason: payload
        }),
        openCloseControlPopin: (state): FirstNameState => ({
            ...state,
            popinState: !state.popinState
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    popinValidation: createAction(`${SLICE_NAME}/POPIN_VALIDATION`)
}

/* ------------- Selectors ------------- */

const getModificationReason: Selector<RootState, UpdateFirstNamesPayloadModificationReasonsEnum[]> = (state) =>
    state.firstNames.modificationReason
const getPopinState: Selector<RootState, boolean> = (state) => state.firstNames.popinState

export const selectors = {
    getModificationReason,
    getPopinState
}

/* ------------- Utils ------------- */
