import styled from 'styled-components'

import Button from '@nickel/ui/components/legacy/Button'
import { Color, Spacing } from '@nickel/ui/tokens'

type RightSideProps = {
    hasForm: boolean
}

const Cards = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 50%;

    > {
        margin-bottom: ${Spacing.LARGE};
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const DocumentsListWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`

const FormButtons = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: ${Spacing.LARGE};

    > * {
        white-space: nowrap;
    }

    > :not(:last-child) {
        margin-right: ${Spacing.LARGE};
    }
`

const FormWrapper = styled.div`
    display: flex;

    > :not(:last-child) {
        margin-right: ${Spacing.LARGE};
    }

    > :last-child {
        flex-grow: 1;
    }
`

const RightSide = styled.div<RightSideProps>`
    display: inline;
    margin: auto;
    width: ${(props) => (props.hasForm ? '33%' : 'auto')};
`

const Row = styled.div`
    display: flex;

    > ${Button}:not(:last-child) {
        margin-right: ${Spacing.LARGE};
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: start;
`

const ValidateButton = styled(Button)`
    [disabled],
    [disabled]:hover {
        background-color: ${Color.SECONDARY_TEXT};
        color: ${Color.WHITE};
    }
`

export default {
    Cards,
    Container,
    DocumentsListWrapper,
    RightSide,
    FormButtons,
    FormWrapper,
    Row,
    RowWrapper,
    ValidateButton
}
