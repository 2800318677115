import React from 'react'

import { Action, Dispatch } from 'redux'

import Modal from '@nickel/core-ui/components/Modal'
import i18ng from '@nickel/i18n'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { ModalData } from './errors'
import { modalTemplateMap } from './templates'

const renderModal = (
    modal: {
        isVisible: boolean
        uiData: ModalData
    },
    dispatch: Dispatch,
    i18n: typeof i18ng
) => {
    const {
        isVisible,
        uiData: {
            title,
            message,
            showCloseButton,
            i18nContext = undefined,
            primaryAction = {
                action: uiActions.hideModal(),
                text: 'common:understood'
            },
            secondaryAction,
            template
        }
    } = modal
    const onModalAction = (action?: Action, shouldClose = true) => () => {
        if (action && action.type) dispatch(action)
        if (shouldClose) dispatch(uiActions.hideModal())
    }
    const modalDetail = () => {
        if (!template) {
            if (Array.isArray(message) && message.length > 0) {
                const [key, context] = message
                return i18n.t(key, context)
            }
            return i18n.t(message ?? '')
        }
        const { type, payload } = template
        if (!type || !modalTemplateMap.has(type)) return
        if (payload) return modalTemplateMap.get(type)(i18n.t.bind(i18n), payload)
        if (message)
            return modalTemplateMap.get(type)(i18n.t.bind(i18n), i18nContext ? `${message}_${i18nContext}` : message)
        return modalTemplateMap.get(type)(i18n.t.bind(i18n))
    }

    return (
        <Modal
            open={isVisible}
            showCloseButton={showCloseButton}
            onClose={onModalAction()}
            testId="Modal"
            title={i18n.t(title || '')}
            primaryButton={{
                onClick: onModalAction(primaryAction?.action),
                testId: 'ModalPrimaryActionButton',
                text: i18n.t(primaryAction?.text || '')
            }}
            secondaryButton={
                secondaryAction && {
                    onClick: onModalAction(secondaryAction.action),
                    testId: 'ModalSecondaryActionButton',
                    text: i18n.t(secondaryAction.text),
                    variant: 'secondary'
                }
            }
        >
            {modalDetail()}
        </Modal>
    )
}

export default renderModal
