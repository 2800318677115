import styled from 'styled-components'

import InfoAlert from '@nickel/ui/components/legacy/Alert/Warning'
import { Spacing } from '@nickel/ui/tokens'

const Container = styled(InfoAlert)`
    margin-bottom: ${Spacing.MEDIUM};
`

export default {
    Container
}
