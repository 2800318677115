import React, { useCallback } from 'react'

import { useForm } from 'react-form'
import { useDispatch, useSelector } from 'react-redux'

import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'
import { RootState } from '../../store/rootReducer'

import ExpirationDate from './fields/ExpirationDate'

const Form = () => {
    const dispatch = useDispatch()
    const { Form: ReactForm } = useForm()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const expirationDate = useSelector<RootState, string | undefined>(controlsSelectors.getExpirationDate)

    const setExpirationDate = useCallback(
        (value) => {
            if (isOwner) {
                dispatch(controlsActions.setExpirationDateOwner({ expirationDateOwner: value }))
            } else {
                dispatch(controlsActions.setExpirationDateGuardian({ expirationDateGuardian: value }))
            }
        },
        [dispatch, isOwner]
    )

    return (
        <ReactForm>
            <ExpirationDate onChange={setExpirationDate} value={expirationDate} />
        </ReactForm>
    )
}

export default Form
