import React from 'react'

import { useTranslation } from 'react-i18next'

import Styled from './styles'

const DocumentCheckerAlert = () => {
    const { t } = useTranslation()

    return (
        <Styled.Container>
            {t('controls:need-to-connect-to')}

            <a href={window.REACT_APP_DOCUMENTCHECKER_URL} rel="noopener noreferrer" target="_blank">
                documentchecker
            </a>
        </Styled.Container>
    )
}

export default DocumentCheckerAlert
