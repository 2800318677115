import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from 'axios'
import { isNil } from 'ramda'
import { SagaIterator } from 'redux-saga'
import { call, delay, put } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { isNotNil } from '@nickel/utils/lib/common'

import { StimulusType } from '../config/errors'

import { ALREADY_KNOWN_CUSTOMER_ERROR } from './controls/sagas/control/constants'

const defaultHttpMaxTries = 3
const defaultHttpRetryDelay = 2000

type AxiosFunction = (axios?: AxiosInstance, basePath?: string) => AxiosPromise
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HttpFunction = (...args: any[]) => Promise<AxiosFunction>

export const hasMessage = (res?: AxiosResponse, message = '') => {
    return res?.data?.detail === message
}

export const hasErrorCode = (res?: AxiosResponse, errorCode = '') => {
    return res?.data?.errorCode === errorCode
}
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isAlreadyKnownCustomer<T = any, D = any>(err: any): err is AxiosError<T, D> {
    return (
        axios.isAxiosError(err) &&
        err.response?.status === ALREADY_KNOWN_CUSTOMER_ERROR.status &&
        hasErrorCode(err.response, ALREADY_KNOWN_CUSTOMER_ERROR.error)
    )
}

export type HttpOptions = {
    displayError?: boolean
    maxTries?: number
    retryDelay?: number
    retryFallback?: (response: AxiosResponse) => boolean
    options?: Record<string, string>
}

export const http = (httpOptions: HttpOptions = {}) =>
    function* httpGenerator(fn: HttpFunction, ...args: unknown[]): SagaIterator {
        const {
            displayError = true,
            maxTries = defaultHttpMaxTries,
            retryDelay = defaultHttpRetryDelay,
            retryFallback = () => false,
            options = {}
        } = httpOptions

        const request: AxiosFunction = yield call(fn, ...args, { ...options })

        let i = 0
        while (i < maxTries) {
            try {
                return yield call(request)
            } catch (err) {
                if (!axios.isAxiosError(err)) {
                    throw err
                }

                const { response } = err
                if (isNotNil(response) && retryFallback(response)) {
                    i = maxTries
                    throw err
                } else if (i < maxTries - 1) {
                    yield delay(retryDelay)
                } else if (isNil(response) && displayError) {
                    yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.NICKEL_SERVICE_UNAVAIBLE)))
                    throw new Error()
                } else {
                    throw err
                }
            } finally {
                i += 1
            }
        }
    }
