import { TemplateType } from '../../config/templates'
import { RegistrationControl, UpdateSidesValidityPayload, ControlValidity } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'

const invalidate = {
    message: 'controls:SIDES.modals.invalidate.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.SIDES,
            controlPayload: {
                validity: ControlValidity.INVALID
            } as UpdateSidesValidityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:SIDES.modals.invalidate.title'
}

export default {
    invalidate
}
