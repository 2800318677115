import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, debounce, put, takeLatest } from 'redux-saga/effects'

import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { StimulusType } from '../../config/errors'
import { ROUTES } from '../../navigation/constants'
import { controlResourceApi } from '../../services'
import { accessUnauthorized } from '../../views/Detail/modals'
import {
    RegistrationFormComplianceStatusValues,
    RegistrationFormSortsValues,
    RegistrationFormTypesValues
} from '../../views/RegistrationFormList/types'
import { actions as navigationActions } from '../navigation'
import { http } from '../utils'

import { ControlCustomerPayload, UpdateComplianceStatusPayload, actions as registrationFormsActions, Filters } from '.'

function setComplianceStatus({ payload }: PayloadAction<RegistrationFormComplianceStatusValues>) {
    sessionStorage.setItem(Filters.sortComplianceStatus, payload)
}

function setRegistrationFormType({ payload }: PayloadAction<RegistrationFormTypesValues>) {
    sessionStorage.setItem(Filters.sortFormType, payload)
}
function setSearchCountry({ payload }: PayloadAction<SupportedCountryISO2>) {
    sessionStorage.setItem(Filters.sortCountry, payload)
}
function setSearchQuery({ payload }: PayloadAction<string>) {
    sessionStorage.setItem(Filters.sortInput, payload)
}
function setSearchSort({ payload }: PayloadAction<RegistrationFormSortsValues>) {
    sessionStorage.setItem(Filters.sortOrder, payload)
}
function setSearchDateRangeCreationDate({ payload }: PayloadAction<[string, string]>) {
    sessionStorage.setItem(Filters.dateRangeCreationDate, JSON.stringify(payload))
}

function* updateComplianceStatus({ payload }: PayloadAction<UpdateComplianceStatusPayload>) {
    try {
        const { registrationFormId, status } = payload
        yield call(http(), controlResourceApi.updateComplianceStatus, registrationFormId, {
            complianceStatus: status
        })
        yield put(navigationActions.goTo({ pathname: ROUTES.HOME }))
        yield put(
            uiActions.showToast({
                message: 'details:update-compliant-status-success'
            })
        )
    } catch {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}

function* controlCustomer({ payload }: PayloadAction<ControlCustomerPayload>) {
    try {
        const { registrationFormId } = payload
        const { data: controlAllowed } = yield call(http(), controlResourceApi.controlAllowed)
        if (controlAllowed) {
            yield put(
                navigationActions.goTo({
                    pathname: `/${ROUTES.DETAILS}/${registrationFormId}/${ROUTES.CONTROL}`
                })
            )
        } else {
            yield put(uiActions.showModal(accessUnauthorized))
        }
    } catch {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}

export default function* sagas() {
    yield all([
        takeLatest(registrationFormsActions.controlCustomer, controlCustomer),
        takeLatest(registrationFormsActions.setComplianceStatus, setComplianceStatus),
        takeLatest(registrationFormsActions.setRegistrationFormType, setRegistrationFormType),
        takeLatest(registrationFormsActions.setSearchCountry, setSearchCountry),
        debounce(500, registrationFormsActions.setSearchQuery, setSearchQuery),
        takeLatest(registrationFormsActions.setSearchSort, setSearchSort),
        takeLatest(registrationFormsActions.updateComplianceStatus, updateComplianceStatus),
        takeLatest(registrationFormsActions.searchDateRangeCreationDate, setSearchDateRangeCreationDate)
    ])
}
