import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CheckCircleIcon from '@nickel/ui/components/icons/CheckCircle'
import CopyIcon from '@nickel/ui/components/icons/Copy'
import Input from '@nickel/ui/components/legacy/form/Input'
import ButtonAdornment from '@nickel/ui/components/legacy/form/Input/adornments/Button'
import { InputProps } from '@nickel/ui/components/legacy/form/Input/types'

import Styled from './styles'

const COPIED_MESSAGE_DISPLAY_TIME = 3000

const CopyInput = (props: InputProps) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLTextAreaElement>()
    const [isCopied, setIsCopied] = useState(false)

    const copyEventTargetContent = useCallback(() => {
        if (!inputRef.current || isCopied) return
        window.navigator.clipboard
            .writeText(inputRef.current.value)
            .catch(() => null)
            .then(() => setIsCopied(true))
    }, [inputRef, isCopied, setIsCopied])

    useEffect(() => {
        if (!isCopied) return
        const timer = setTimeout(() => setIsCopied(false), COPIED_MESSAGE_DISPLAY_TIME)

        return () => {
            clearTimeout(timer)
            setIsCopied(false)
        }
    }, [isCopied, setIsCopied])

    return (
        <Styled.Wrapper>
            <Input
                ref={inputRef}
                {...props}
                RightAdornment={() => (
                    <ButtonAdornment inputName={props.name} Icon={CopyIcon} onClick={copyEventTargetContent} />
                )}
            />
            <Styled.CopiedMessage copied={isCopied}>
                <CheckCircleIcon />
                <span>{t('common:value-copied-to-clipboard')}</span>
            </Styled.CopiedMessage>
        </Styled.Wrapper>
    )
}

export default CopyInput
