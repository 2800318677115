import React, { StrictMode } from 'react'

import { ConnectedRouter } from 'connected-react-router'
import { setConfiguration } from 'react-grid-system'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import Catcher from '@nickel/monitoring/components/Catcher'
import UIContainer from '@nickel/stimulus/ui/Container'
import bo from '@nickel/ui/themes/bo'

import AuthProvider from './config/AuthProvider'
import queryClient from './config/query/queryClient'
import renderLoader from './config/renderLoader'
import renderModal from './config/renderModal'
import renderToast from './config/renderToast'
import Layout from './layout'
import store from './store'
import history from './store/history'
import { RootState } from './store/rootReducer'

setConfiguration({
    containerWidths: [540, 750, 960, 1900]
})

const App = () => (
    <StrictMode>
        <Catcher pixelUrl={process.env.REACT_APP_MONITORING_PIXEL_URL}>
            <Provider store={store}>
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={bo}>
                            <UIContainer
                                getState={(state: RootState) => state.ui}
                                renderModal={renderModal}
                                renderLoader={renderLoader}
                                renderToast={renderToast}
                            >
                                <ConnectedRouter history={history}>
                                    <Layout />
                                </ConnectedRouter>
                            </UIContainer>
                        </ThemeProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </Provider>
        </Catcher>
    </StrictMode>
)

export default App
