import { PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { StimulusType } from '../../config/errors'
import { isControl } from '../../navigation/utils'
import { registrationDocumentResourceApi } from '../../services'
import { RegistrationDocumentOcrPropertiesView } from '../../services/api'
import { noOcrPropertiesModalData } from '../controls/modals'
import { selectors as navigationSelectors } from '../navigation'
import { hasMessage, http } from '../utils'

import { selectors as ocrPropertiesSelectors, actions as ocrPropertiesActions } from '.'

const OCR_PROPERTIES_NOT_FOUND_ERROR = { code: 404, message: 'error.ocrPropertiesNotFound' }

export function* fetchOcrProperties(documentId: string) {
    const navRegistrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
    const ocrRegistrationFormId: string = yield select(ocrPropertiesSelectors.getRegistrationFormId)
    if (navRegistrationFormId !== ocrRegistrationFormId)
        yield put(ocrPropertiesActions.setRegistrationFormId(navRegistrationFormId))

    const hasNoOcrProperties: boolean = yield select(ocrPropertiesSelectors.hasNoOcrProperties(documentId))
    if (hasNoOcrProperties) return

    const ocrProperties: RegistrationDocumentOcrPropertiesView = yield select(
        ocrPropertiesSelectors.getOcrPropertiesByDocumentId(documentId)
    )
    if (ocrProperties) return ocrProperties

    try {
        yield put(ocrPropertiesActions.updateFetchingIds({ documentId }))

        const retryFallback = (response: AxiosResponse) => {
            return response?.data?.message === OCR_PROPERTIES_NOT_FOUND_ERROR.message
        }
        const { data } = yield call(
            http({ retryFallback }),
            registrationDocumentResourceApi.getRegistrationDocumentOcrProperties,
            navRegistrationFormId,
            documentId
        )

        yield put(ocrPropertiesActions.putOcrProperties({ documentId, ocrProperties: data }))

        return data
    } catch (err) {
        if (axios.isAxiosError(err) && hasMessage(err.response, OCR_PROPERTIES_NOT_FOUND_ERROR.message)) {
            yield put(ocrPropertiesActions.pushNoOcrPropertiesId(documentId))
            const navPathname: string = yield select(navigationSelectors.getCurrentPathname)
            if (isControl(navPathname)) yield put(uiActions.showModal(noOcrPropertiesModalData))
        } else yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    } finally {
        yield put(ocrPropertiesActions.updateFetchingIds({ documentId, fetched: true }))
    }
}

function* fetchOcrPropertiesWithAction({ payload: documentId }: PayloadAction<string>) {
    const isFetchingId: boolean = yield select(ocrPropertiesSelectors.isFetchingId(documentId))
    if (!isFetchingId) yield call(fetchOcrProperties, documentId)
}

export default function* sagas() {
    yield takeEvery(ocrPropertiesActions.fetch, fetchOcrPropertiesWithAction)
}
