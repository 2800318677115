import { SagaIterator } from 'redux-saga'
import { call } from 'redux-saga/effects'

import { ControlPayload } from '../..'
import { RegistrationControl } from '../../../../services/api'

import { control as controlBirthData } from './birthData'
import { control as controlChip } from './chip'
import { control as controlColors } from './colors'
import { control as controlDocumentNumber } from './documentNumber'
import { control as controlDocumentNumberPosition } from './documentNumberPosition'
import { control as controlExpirationDate } from './expirationDate'
import { control as controlFamilyDocumentReadability } from './familyDocumentReadability'
import { control as controlFamilyDocumentType } from './familyDocumentType'
import { control as controlFirstNames } from './firstNames'
import { control as controlFont } from './font'
import { control as controlFrenchRepublicMentions } from './frenchRepublicMentions'
import { control as controlHolograms } from './holograms'
import { control as controlIdentityDocumentReadability } from './identityDocumentReadability'
import { control as controlIdentityDocumentType } from './identityDocumentType'
import { control as controlIssuingCountry } from './issuingCountry'
import { control as controlLastNames } from './lastNames'
import { control as controlMrz } from './mrz'
import { control as controlPhoto } from './photo'
import { control as controlPhotocopy } from './photocopy'
import { control as controlSex } from './sex'
import { control as controlSides } from './sides'
import { control as controlSignature } from './signature'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const controlMap: Record<string, (args: any) => SagaIterator> = {
    [RegistrationControl.BIRTH_DATA]: controlBirthData,
    [RegistrationControl.CHIP]: controlChip,
    [RegistrationControl.COLORS]: controlColors,
    [RegistrationControl.DOCUMENT_NUMBER]: controlDocumentNumber,
    [RegistrationControl.DOCUMENT_NUMBER_POSITION]: controlDocumentNumberPosition,
    [RegistrationControl.EXPIRATION_DATE]: controlExpirationDate,
    [RegistrationControl.FAMILY_DOCUMENT_READABILITY]: controlFamilyDocumentReadability,
    [RegistrationControl.FAMILY_DOCUMENT_TYPE]: controlFamilyDocumentType,
    [RegistrationControl.FIRST_NAMES]: controlFirstNames,
    [RegistrationControl.FONT]: controlFont,
    [RegistrationControl.FRENCH_REPUBLIC_MENTIONS]: controlFrenchRepublicMentions,
    [RegistrationControl.HOLOGRAMS]: controlHolograms,
    [RegistrationControl.IDENTITY_DOCUMENT_READABILITY]: controlIdentityDocumentReadability,
    [RegistrationControl.IDENTITY_DOCUMENT_TYPE]: controlIdentityDocumentType,
    [RegistrationControl.ISSUING_COUNTRY]: controlIssuingCountry,
    [RegistrationControl.LAST_NAMES]: controlLastNames,
    [RegistrationControl.MRZ_LINES]: controlMrz,
    [RegistrationControl.PHOTO]: controlPhoto,
    [RegistrationControl.PHOTOCOPY]: controlPhotocopy,
    [RegistrationControl.SEX]: controlSex,
    [RegistrationControl.SIDES]: controlSides,
    [RegistrationControl.SIGNATURE]: controlSignature
}

function* control<T>({ payload: { controlType, controlPayload } }: { payload: ControlPayload<T> }) {
    if (!controlMap[controlType]) return
    yield call(controlMap[controlType], controlPayload)
}

export default control
