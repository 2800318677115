import { actions as uiActions } from '@nickel/stimulus/ui'

import {
    RegistrationControl,
    UpdateIdentityDocumentReadabilityPayload,
    IdentityDocumentReadability
} from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import modals from './modals'

const control: Control = {
    actions: {
        invalidate: [
            {
                action: uiActions.showModal(modals.notReadable),
                label: 'common:no'
            }
        ],
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.IDENTITY_DOCUMENT_READABILITY,
                    controlPayload: {
                        readability: IdentityDocumentReadability.READABLE
                    } as UpdateIdentityDocumentReadabilityPayload
                }),
                label: 'common:yes'
            }
        ]
    }
}

export default control
