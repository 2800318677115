import { createAction, createSlice } from '@reduxjs/toolkit'

/* ------------- Type & State ------------- */

export type OnfidoReportState = Record<string, never>

export const INITIAL_STATE: OnfidoReportState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@onfido-report'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {}
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    getOnfidoReportAction: createAction(`${SLICE_NAME}/GET_ONFIDO_REPORT`)
}

/* ------------- Selectors ------------- */

export const selectors = {}

/* ------------- Utils ------------- */
