import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { registrationFormResourceApi } from '../../../services'
import { selectors as navigationSelectors } from '../../../store/navigation'

export const GET_CUSTOMER_QUERY_KEY = 'getCustomer'

const getCustomer = (registrationFormId: string) => async () =>
    (await registrationFormResourceApi.getCustomer(registrationFormId))()

const useCustomer = () => {
    const registrationFormId: string = useSelector(navigationSelectors.getCurrentRegistrationFormId) ?? ''

    const { data: customerId, isLoading, isError } = useQuery(GET_CUSTOMER_QUERY_KEY, getCustomer(registrationFormId), {
        onError: Error,
        select: (response) => response.data.customerId
    })
    return {
        customerId,
        isLoading,
        isError
    }
}

export default useCustomer
