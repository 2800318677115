import axios from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { AlertVariant } from '@nickel/ui/components/legacy/Alert/types'

import { StimulusType } from '../../config/errors'
import { ToastData } from '../../config/renderToast'
import { registrationDocumentResourceApi } from '../../services'
import { selectors as navigationSelectors } from '../navigation'
import { http } from '../utils'

import { actions as onfidoReportActions } from '.'

export function* getOnfidoCheckReport() {
    try {
        const registrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const { data } = yield call(
            http({ options: { responseType: 'blob' } }),
            registrationDocumentResourceApi.downloadOnfidoCheckReportFile,
            registrationFormId
        )
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
    } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
            yield put(
                uiActions.showToast({
                    message: 'controls:errors.fetching-warnings-failed',
                    variant: AlertVariant.ERROR
                } as ToastData)
            )
        } else yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}

export default function* sagas() {
    yield all([takeLatest(onfidoReportActions.getOnfidoReportAction, getOnfidoCheckReport)])
}
