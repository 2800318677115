import styled from 'styled-components'

import Pender from '@nickel/ui/components/legacy/Pender'
import { Spacing } from '@nickel/ui/tokens'

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 94%;
    justify-content: space-between;
    padding: ${Spacing.LARGE};
    width: 100%;

    ${Pender} {
        display: block;
        margin: 0 auto;
    }
`

export default {
    Container
}
