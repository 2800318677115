import React from 'react'

import useFormField from '@nickel/forms/hooks/useFormField'
import { FieldProps } from '@nickel/forms/types'
import DateInput from '@nickel/ui/components/legacy/form/DateInput'
import Field from '@nickel/ui/components/legacy/form/Field'

import { sameOrAfterToday } from '../validators'

const ExpirationDate = (props: FieldProps<string>) => {
    const { error, inputProps } = useFormField('expirationDate', [sameOrAfterToday], props)

    return (
        <Field error={error}>
            <DateInput
                {...inputProps}
                // @ts-expect-error @nickel/forms types
                onChange={inputProps.onChange}
                name="expirationDate"
            />
        </Field>
    )
}

export default ExpirationDate
