import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { CivilData, selectors as controlSelectors } from '../..'
import { UpdateFirstNamesPayload } from '../../../../services/api'
import { actions as firstNamesActions } from '../../../firstNames'
import { submitControls } from '../../../firstNames/sagas'

/**
 * Vérifie si le(s) prenom(s) renseigné(s) match avec le(s) prenom(s) renseigné(s) lors de la souscription (non sensible à la casse).
 * @param firstNames prenom(s) renseigné(s) dans l' (les) input(s)
 * @param oldFirstNames prenom(s) recupéré(s) depuis le parcours de souscription
 * @return {boolean} true si le(s) prenom(s) renseigné(s) match avec le(s) prenom(s) renseigné(s) lors de la souscription
 */
function isFistNameMatches(firstNames: string[], oldFirstNames: string[]) {
    if (firstNames.length !== oldFirstNames.length) return false
    return firstNames.every(
        (firstName) =>
            oldFirstNames
                .map((oldFirstName) => {
                    return oldFirstName.toUpperCase()
                })
                .indexOf(firstName.toUpperCase()) >= 0
    )
}

/**
 * Vérifie si l'un des éléments du prenom a été modifié.
 * @param payload contient les données provenant de la vue
 * @param civilData contient les données récupérées depuis le serveur, avant modification
 * @return {boolean} true si l'un des éléments a été modifié
 */
function firstNamesHasChanged(inputtedFirstNames: string[], firstNames: string[] | undefined) {
    if (!inputtedFirstNames || !firstNames) {
        return true
    }
    if (!isFistNameMatches(inputtedFirstNames, firstNames)) {
        return true
    }
}

export function* control(): SagaIterator {
    const firstNames: string[] = yield select(controlSelectors.getFirstNames)
    const payload: UpdateFirstNamesPayload = { firstNames }
    const civilDataProperties: CivilData = yield select(controlSelectors.getCivilDataProperties)
    if (firstNamesHasChanged(firstNames, civilDataProperties.firstNames)) {
        yield put(firstNamesActions.openCloseControlPopin())
    } else {
        yield call(submitControls, payload)
    }
}
