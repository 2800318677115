import { actions as uiActions } from '@nickel/stimulus/ui'

import { RegistrationControl, UpdateChipPresencePayload, ChipPresence } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import modals from './modals'

const control: Control = {
    actions: {
        invalidate: [
            {
                action: uiActions.showModal(modals.doubt),
                label: 'common:i-have-a-doubt'
            },
            {
                action: uiActions.showModal(modals.notCompliant),
                label: 'common:no'
            }
        ],
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.CHIP,
                    controlPayload: {
                        presence: ChipPresence.COMPLIANT
                    } as UpdateChipPresencePayload
                }),
                label: 'common:yes'
            }
        ]
    }
}

export default control
