import axios, { AxiosResponse } from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { AlertVariant } from '@nickel/ui/components/legacy/Alert/types'

import { ControlIdentifier, NextControlType, actions as controlsActions, selectors as controlsSelectors } from '..'
import { StimulusType } from '../../../config/errors'
import { ToastData } from '../../../config/renderToast'
import { ROUTES } from '../../../navigation/constants'
import { controlResourceApi } from '../../../services'
import {
    ControlWarningListView,
    RegistrationControl,
    RegistrationControlProgressView,
    RegistrationFormListItemView,
    ComplianceStatus
} from '../../../services/api'
import { fetchForm } from '../../details/sagas'
import { actions as navigationActions, selectors as navigationSelectors } from '../../navigation'
import { selectors as ocrPropertiesSelector } from '../../ocrProperties'
import { hasMessage, http } from '../../utils'
import { noOcrPropertiesModalData } from '../modals'

import control from './control'
import { get as getProperties } from './properties'

const NO_CONTROL_LEFT_ERROR = { code: 404, message: 'error.noControlLeft' }

function* getProgress({ control: controlId, formId, registrationDocumentId }: ControlIdentifier) {
    const { data }: AxiosResponse<RegistrationControlProgressView> = yield call(
        http(),
        controlResourceApi.getControlsProgress,
        formId,
        registrationDocumentId,
        controlId
    )
    return data
}

function* getWarnings({
    payload: { control: controlId, formId, registrationDocumentId }
}: {
    payload: ControlIdentifier
}) {
    try {
        const hasNoOcrProperties: boolean | undefined = yield select(
            ocrPropertiesSelector.hasNoOcrProperties(registrationDocumentId)
        )
        if (hasNoOcrProperties) return
        const controlCode = controlId.toLowerCase().replace(/_/g, '-')
        const { data }: AxiosResponse<ControlWarningListView> = yield call(
            http({ displayError: false }),
            controlResourceApi.getWarnings,
            formId,
            registrationDocumentId,
            controlCode
        )
        if (data) yield put(controlsActions.getControlWarningsAsyncSuccess(data.warnings ?? []))
    } catch (err) {
        if (!axios.isAxiosError(err) || err.response?.status !== 404) {
            yield put(
                uiActions.showToast({
                    message: 'controls:errors.fetching-warnings-failed',
                    variant: AlertVariant.ERROR
                } as ToastData)
            )
        }
    }
}

const createGet = (nextControlType: NextControlType) =>
    function* getControl() {
        const getControlAsyncSuccess =
            nextControlType === NextControlType.NEXT
                ? controlsActions.getNextControlAsyncSuccess
                : controlsActions.getPreviousControlAsyncSuccess

        const getControlAsyncFailure =
            nextControlType === NextControlType.NEXT
                ? controlsActions.getNextControlAsyncFailure
                : controlsActions.getPreviousControlAsyncFailure

        const resourceApi =
            nextControlType === NextControlType.NEXT
                ? controlResourceApi.getNextControl
                : controlResourceApi.getPreviousControl
        const formId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)

        try {
            const controlId: RegistrationControl | undefined = yield select(controlsSelectors.getControlId)
            const documentId: string | undefined = yield select(controlsSelectors.getDocumentId)

            const { data } = yield call(http({ maxTries: 1 }), resourceApi, formId, documentId, controlId)
            yield put(controlsActions.getControlWarningsAsyncRequest({ ...data, formId }))
            const { properties, progress } = yield all({
                properties: call(getProperties, { ...data, formId }),
                progress: call(getProgress, { ...data, formId })
            })
            yield put(getControlAsyncSuccess({ ...data, ...properties, progress }))
        } catch (err) {
            if (
                axios.isAxiosError(err) &&
                err.response?.status === NO_CONTROL_LEFT_ERROR.code &&
                hasMessage(err.response, NO_CONTROL_LEFT_ERROR.message)
            ) {
                yield put(navigationActions.goTo({ pathname: ROUTES.HOME }))
                const { complianceStatus }: RegistrationFormListItemView = yield call(fetchForm, formId)
                yield put(
                    uiActions.showToast({
                        message:
                            complianceStatus === ComplianceStatus.COMPLIANT
                                ? 'controls:registration-form-valid'
                                : 'controls:registration-form-invalid'
                    })
                )
            } else {
                yield put(getControlAsyncFailure())
                yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.CONTROL_ERROR)))
            }
        }
    }

function* start() {
    yield put(controlsActions.resetControl())

    try {
        const registrationFormId: string | undefined = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const {
            data: { registrationDocumentId }
        } = yield call(
            http({ maxTries: 1 }),
            controlResourceApi.getNextControl,
            registrationFormId,
            undefined,
            undefined
        )

        const hasNoOcrProperties: boolean = yield select(
            ocrPropertiesSelector.hasNoOcrProperties(registrationDocumentId)
        )
        if (hasNoOcrProperties) yield put(uiActions.showModal(noOcrPropertiesModalData))
    } catch (err) {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    } finally {
        yield put(controlsActions.getNextControlAsyncRequest())
    }
}

export default function* sagas() {
    yield all([
        takeLatest(controlsActions.createControlAsyncRequest, control),
        takeLatest(controlsActions.getControlWarningsAsyncRequest, getWarnings),
        takeLatest(controlsActions.getNextControlAsyncRequest, createGet(NextControlType.NEXT)),
        takeLatest(controlsActions.getPreviousControlAsyncRequest, createGet(NextControlType.PREVIOUS)),
        takeLatest(controlsActions.start, start)
    ])
}
