import React from 'react'

import { useTranslation } from 'react-i18next'

import { ComplianceStatus } from '../../../services/api'

import Styled from './styles'

type Props = {
    status: ComplianceStatus
}

const Status = ({ status }: Props) => {
    const { t } = useTranslation()

    return (
        <Styled.Status data-testid="detailStatus">
            {status !== ComplianceStatus.DETERMINING && <Styled.StatusIcon complianceStatus={status} />}
            <Styled.StatusLabel>{t(`common:compliance-status.${status}`)}</Styled.StatusLabel>
        </Styled.Status>
    )
}

export default Status
