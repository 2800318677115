import React from 'react'

import styled from 'styled-components'

const ImageContainer = styled.div`
    flex: 1;
    position: relative;
`

const rotateButtonStyle: React.CSSProperties = {
    alignItems: 'center',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '2rem',
    justifyContent: 'center',
    position: 'fixed',
    width: '2rem',
    top: '50%'
}
export default {
    ImageContainer,
    rotateButtonStyle
}
