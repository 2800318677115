import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { selectors as detailsSelectors } from '../details'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */
type DocumentsState = {
    rotateDegrees: Record<string, number>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    externalWindows: Record<string, any | null>
}

export const INITIAL_STATE: DocumentsState = { rotateDegrees: {}, externalWindows: {} }

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@document-details'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setDocumentFileConfigurationRotateDegrees: (
            state,
            { payload }: PayloadAction<Record<string, number>>
        ): DocumentsState => ({
            ...state,
            rotateDegrees: {
                ...state.rotateDegrees,
                ...payload
            }
        }),
        setOpenedDocumentInExternalWindow: (state, { payload }: PayloadAction<Record<string, Window | null>>) => ({
            ...state,
            externalWindows: {
                ...state.externalWindows,
                ...payload
            }
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getDocumentFileConfiguration: Selector<RootState, DocumentsState> = (state) => state.documents

const getDocumentFileConfigurationRotateDegrees: Selector<RootState, Record<string, number>> = createSelector(
    [getDocumentFileConfiguration, detailsSelectors.getGuardianDocumentsFiles, detailsSelectors.getOwnerDocumentsFiles],
    (documentFileConfiguration, guardianDocumentsFiles, ownerDocumentsFiles) => {
        if (documentFileConfiguration.rotateDegrees) return documentFileConfiguration.rotateDegrees
        const initialRotate: Record<string, number> = {}
        const documentFiles = [...guardianDocumentsFiles, ...ownerDocumentsFiles]
        documentFiles.forEach((file) => {
            initialRotate[file.id] = 0
        })
        return initialRotate
    }
)
const getOpenedDocumentsInExternalWindow: Selector<RootState, Record<string, Window | null>> = createSelector(
    [getDocumentFileConfiguration],
    (documentFileConfiguration) => {
        if (documentFileConfiguration.externalWindows) return documentFileConfiguration.externalWindows
        return {}
    }
)

export const selectors = {
    getDocumentFileConfigurationRotateDegrees,
    getOpenedDocumentsInExternalWindow
}

/* ------------- Utils ------------- */
