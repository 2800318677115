import { createAction, createSelector, createSlice, Selector } from '@reduxjs/toolkit'

import { ROUTES } from '../../navigation/constants'
import { isControl, isDetails } from '../../navigation/utils'
import { RegistrationDocumentOcrPropertiesView } from '../../services/api'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

export interface GoToPayload {
    pathname: string
}

export interface RouterState {
    location: {
        pathname: string
    }
}

export type OcrPropertiesState = {
    data?: Record<string, RegistrationDocumentOcrPropertiesView>
    fetchingIds?: string[]
    noOcrPropertiesIds?: string[]
    registrationFormId?: string
}

export const INITIAL_STATE: OcrPropertiesState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@navigation'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {}
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    goBack: createAction(`${SLICE_NAME}/GO_BACK`),
    goTo: createAction<GoToPayload>(`${SLICE_NAME}/GO_TO`),
    goToExternal: createAction<GoToPayload>(`${SLICE_NAME}/GO_TO_EXTERNAL`)
}

/* ------------- Selectors ------------- */

const getCurrentPathname: Selector<RootState, string> = (state) => state.router.location.pathname

const getCurrentRegistrationFormId = createSelector([getCurrentPathname], (currentPathname) => {
    if (!currentPathname.includes(ROUTES.DETAILS)) return
    const [, , currentRegistrationFormId] = currentPathname.split('/')
    return currentRegistrationFormId
})

const getIsHomeDisplayed = createSelector(
    [getCurrentPathname],
    (currentPathname) => !isControl(currentPathname) && !isDetails(currentPathname)
)

export const selectors = {
    getCurrentPathname,
    getCurrentRegistrationFormId,
    getIsHomeDisplayed
}

/* ------------- Utils ------------- */
