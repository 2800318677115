import { createAction, createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { pathOr } from 'ramda'

import { isNotNil, isSet } from '@nickel/utils/lib/common'

import { BirthFormInterface } from '../../controls/BIRTH_DATA/form'
import { isSameOrAfterToday } from '../../controls/EXPIRATION_DATE/utils'
import {
    AccountStakeholder,
    ControlWarningView,
    DocumentNumberData,
    ExpirationDateData,
    FamilyDocumentType,
    IdentityDocumentType,
    MrzLinesData,
    NamesData,
    NextControlView,
    RegistrationControl,
    RegistrationControlProgressView,
    RegistrationDocumentOcrPropertiesView,
    RegistrationDocumentType,
    SexData,
    UpdateIssuingCountryPayload
} from '../../services/api'
import { DocumentFile } from '../details'
import { RootState } from '../rootReducer'

import { isArrayFilled } from './utils'

/* ------------- Type & State ------------- */

export enum ControlStatus {
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS'
}

export interface CivilData {
    firstNames?: string[]
    lastNames?: string[]
    marriedName?: string
    preferredLastName?: string
    sex?: string
    nationalities?: string[]
}

export type ControlDocumentType = RegistrationDocumentType | IdentityDocumentType | FamilyDocumentType

export type ControlOcrProperties = Pick<
    RegistrationDocumentOcrPropertiesView,
    'documentNumber' | 'expirationDate' | 'issuingCountry' | 'mrzLines' | 'personalNumber' | 'sex'
>

export type ControlUserChoices = ControlOcrProperties &
    DocumentNumberData &
    ExpirationDateData &
    MrzLinesData &
    SexData &
    NamesData &
    CivilData & {
        birthOwnerData?: BirthDataControlProperties
        birthGuardianData?: BirthDataControlProperties
        documentTypes?: Record<string, ControlDocumentType>
    }

export interface BirthDataControlProperties {
    birthCountry?: string
    birthDate?: string
    nationalities?: string[]
    birthPlace?: string
    birthRegion?: string
}

export interface ControlDocumentProperties {
    accountStakeHolder?: AccountStakeholder
    files?: DocumentFile[]
    type: ControlDocumentType
}

export interface ControlProperties {
    birthDataProperties?: BirthDataControlProperties
    civilDataProperties?: CivilData
    documentProperties?: ControlDocumentProperties
    taxIdentificationProperties?: string
    facialCaptures?: DocumentFile[]
    ocrProperties?: ControlOcrProperties
    progress?: RegistrationControlProgressView
    registrationDocumentId?: string
    overriddenOcrDataWarnings?: string[]
    warnings?: ControlWarningView[]
}

export interface NextControlPayload {
    control: RegistrationControl
    registrationDocumentId: string
}

export interface SetDocumentTypePayload {
    documentId: string
    documentType: ControlDocumentType
}

export interface SetOverriddenOcrDataWarningsPayload {
    warnings: string[]
}

export enum NextControlType {
    NEXT = 'NEXT',
    PREVIOUS = 'PREVIOUS'
}

export interface ControlDependency {
    dependents: RegistrationControl[]
    func: (documentId: string, stakeHolder?: AccountStakeholder) => Generator
    id: string
    name: string
    hasStakeHolderParam?: boolean
}

export interface ControlPayload<Payload> {
    controlType: RegistrationControl
    controlPayload?: Payload
}

export enum NameKey {
    FIRST_NAMES_OWNER = 'firstNamesOwner',
    FIRST_NAMES_GUARDIAN = 'firstNamesGuardian',
    LAST_NAMES_OWNER = 'lastNamesOwner',
    LAST_NAMES_GUARDIAN = 'lastNamesGuardian',
    MARRIED_LAST_NAME_OWNER = 'marriedNameOwner',
    MARRIED_LAST_NAME_GUARDIAN = 'marriedNameGuardian',
    PREFERRED_LAST_NAME_OWNER = 'preferredLastNameOwner',
    PREFERRED_LAST_NAME_GUARDIAN = 'preferredLastNameGuardian'
}

export interface ControlIdentifier {
    control: RegistrationControl
    formId: string
    registrationDocumentId: string
}

export type ControlState = ControlProperties & {
    control?: RegistrationControl
    status?: ControlStatus
    userChoices?: ControlUserChoices
    civilDataBeforeEdit?: CivilData
}

export const INITIAL_STATE: ControlState = {
    control: undefined,
    ocrProperties: undefined,
    status: undefined,
    userChoices: undefined
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@controls'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        resetControl: () => INITIAL_STATE,
        // eslint-disable-next-line no-unused-vars
        getControlWarningsAsyncRequest: (state, _: PayloadAction<ControlIdentifier>): ControlState => ({
            ...state,
            warnings: []
        }),
        getControlWarningsAsyncSuccess: (state, { payload }: PayloadAction<ControlWarningView[]>): ControlState => ({
            ...state,
            warnings: payload
        }),
        getNextControlAsyncRequest: (state): ControlState => ({
            ...state,
            status: ControlStatus.IN_PROGRESS
        }),
        getNextControlAsyncSuccess: (
            state,
            { payload }: PayloadAction<NextControlView & ControlProperties>
        ): ControlState => ({
            ...state,
            ...payload,
            status: ControlStatus.SUCCESS
        }),
        getNextControlAsyncFailure: (): ControlState => ({
            ...INITIAL_STATE,
            status: ControlStatus.ERROR
        }),
        getPreviousControlAsyncRequest: (state): ControlState => ({
            ...state,
            status: ControlStatus.IN_PROGRESS
        }),
        getPreviousControlAsyncSuccess: (
            state,
            { payload }: PayloadAction<NextControlView & ControlProperties>
        ): ControlState => ({
            ...state,
            ...payload,
            status: ControlStatus.SUCCESS
        }),
        getPreviousControlAsyncFailure: (): ControlState => ({
            ...INITIAL_STATE,
            status: ControlStatus.ERROR
        }),
        setCivilDataBeforeEdit: (state, { payload }: PayloadAction<CivilData>): ControlState => ({
            ...state,
            civilDataBeforeEdit: {
                ...state.civilDataBeforeEdit,
                ...payload
            }
        }),
        setBirthDataOwnerValue: (state, { payload: { birth } }: PayloadAction<BirthFormInterface>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                birthOwnerData: {
                    birthCountry: birth?.country ?? '',
                    birthDate: birth?.date ?? '',
                    birthPlace: birth?.place ?? '',
                    birthRegion: birth?.region,
                    nationalities: [birth?.nationality ?? '', ...(birth?.otherNationalities ?? [])]
                }
            }
        }),
        setBirthDataGuardianValue: (
            state,
            { payload: { birth } }: PayloadAction<BirthFormInterface>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                birthGuardianData: {
                    birthCountry: birth?.country ?? '',
                    birthDate: birth?.date ?? '',
                    birthPlace: birth?.place ?? '',
                    birthRegion: birth?.region,
                    nationalities: [birth?.nationality ?? '', ...(birth?.otherNationalities ?? [])]
                }
            }
        }),
        setDocumentNumberOwner: (
            state,
            { payload: { documentNumberOwnerData } }: PayloadAction<DocumentNumberData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                documentNumberOwnerData
            }
        }),
        setDocumentNumberGuardian: (
            state,
            { payload: { documentNumberGuardianData } }: PayloadAction<DocumentNumberData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                documentNumberGuardianData
            }
        }),
        setDocumentType: (state, { payload }: PayloadAction<SetDocumentTypePayload>): ControlState => {
            const documentTypes = pathOr({}, ['userChoices', 'documentTypes'], state)
            return {
                ...state,
                userChoices: {
                    ...state.userChoices,
                    documentTypes: {
                        ...documentTypes,
                        [payload.documentId]: payload.documentType
                    }
                }
            }
        },
        setExpirationDateOwner: (
            state,
            { payload: { expirationDateOwner } }: PayloadAction<ExpirationDateData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                expirationDateOwner
            }
        }),
        setExpirationDateGuardian: (
            state,
            { payload: { expirationDateGuardian } }: PayloadAction<ExpirationDateData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                expirationDateGuardian
            }
        }),
        setIssuingCountry: (
            state,
            { payload: { issuingCountry } }: PayloadAction<UpdateIssuingCountryPayload>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                issuingCountry
            }
        }),
        setMrzLinesOwner: (state, { payload: { mrzLinesOwner } }: PayloadAction<MrzLinesData>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                mrzLinesOwner: mrzLinesOwner?.slice()
            }
        }),
        setMrzLinesGuardian: (state, { payload: { mrzLinesGuardian } }: PayloadAction<MrzLinesData>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                mrzLinesGuardian: mrzLinesGuardian?.slice()
            }
        }),
        setOverriddenOcrDataWarnings: (
            state,
            { payload }: PayloadAction<SetOverriddenOcrDataWarningsPayload>
        ): ControlState => ({
            ...state,
            overriddenOcrDataWarnings: payload.warnings
        }),
        setSexOwner: (state, { payload: { sexOwner } }: PayloadAction<SexData>): ControlState => {
            return {
                ...state,
                userChoices: {
                    ...state.userChoices,
                    sexOwner: sexOwner ?? undefined
                }
            }
        },
        setSexGuardian: (state, { payload: { sexGuardian } }: PayloadAction<SexData>): ControlState => {
            return {
                ...state,
                userChoices: {
                    ...state.userChoices,
                    sexGuardian: sexGuardian ?? undefined
                }
            }
        },
        setFirstNamesOwner: (state, { payload: { firstNamesOwner } }: PayloadAction<NamesData>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.FIRST_NAMES_OWNER]: firstNamesOwner
            }
        }),
        setFirstNamesGuardian: (
            state,
            { payload: { firstNamesGuardian } }: PayloadAction<NamesData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.FIRST_NAMES_GUARDIAN]: firstNamesGuardian
            }
        }),
        setLastNamesOwner: (state, { payload: { lastNamesOwner } }: PayloadAction<NamesData>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.LAST_NAMES_OWNER]: lastNamesOwner
            }
        }),
        setLastNamesGuardian: (state, { payload: { lastNamesGuardian } }: PayloadAction<NamesData>): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.LAST_NAMES_GUARDIAN]: lastNamesGuardian
            }
        }),
        setMarriedLastNameOwner: (
            state,
            { payload: { marriedNameOwner } }: PayloadAction<NamesData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.MARRIED_LAST_NAME_OWNER]: marriedNameOwner
            }
        }),
        setMarriedLastNameGuardian: (
            state,
            { payload: { marriedNameGuardian } }: PayloadAction<NamesData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.MARRIED_LAST_NAME_GUARDIAN]: marriedNameGuardian
            }
        }),
        setPreferredLastNameOwner: (
            state,
            { payload: { preferredLastNameOwner } }: PayloadAction<NamesData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.PREFERRED_LAST_NAME_OWNER]: preferredLastNameOwner
            }
        }),
        setPreferredLastNameGuardian: (
            state,
            { payload: { preferredLastNameGuardian } }: PayloadAction<NamesData>
        ): ControlState => ({
            ...state,
            userChoices: {
                ...state.userChoices,
                [NameKey.PREFERRED_LAST_NAME_GUARDIAN]: preferredLastNameGuardian
            }
        }),
        setOcrProperties: (state, { payload }: PayloadAction<ControlOcrProperties>): ControlState => ({
            ...state,
            ...payload
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    createControlAsyncRequest: createAction<ControlPayload<unknown>>(`${SLICE_NAME}/CREATE_CONTROL_REQUEST`),
    createControlAsyncSuccess: createAction(`${SLICE_NAME}/CREATE_CONTROL_SUCCESS`),
    createControlAsyncFailure: createAction(`${SLICE_NAME}/CREATE_CONTROL_FAILURE`),
    start: createAction(`${SLICE_NAME}/START`)
}

/* ------------- Selectors ------------- */

const isFamilyDocument = (documentType: ControlDocumentType) =>
    documentType === RegistrationDocumentType.FAMILY_REGISTER ||
    documentType === RegistrationDocumentType.BIRTH_CERTIFICATE ||
    documentType === RegistrationDocumentType.JUDGEMENT

const getControlId: Selector<RootState, RegistrationControl | undefined> = (state) => state.control.control

const getStatus: Selector<RootState, ControlStatus | undefined> = (state) => state.control.status

const getHasError: Selector<RootState, boolean> = createSelector(
    [getStatus],
    (status) => status === ControlStatus.ERROR
)

const getDocumentId: Selector<RootState, string | undefined> = (state) => state.control.registrationDocumentId

const getDocumentProperties: Selector<RootState, ControlDocumentProperties | undefined> = (state) =>
    state.control.documentProperties

const getIsOwner: Selector<RootState, boolean> = createSelector(
    [getDocumentProperties],
    (documentProperties) => documentProperties?.accountStakeHolder === 'OWNER'
)

const getStakeHolder: Selector<RootState, AccountStakeholder> = createSelector([getIsOwner], (isOwner) =>
    isOwner ? AccountStakeholder.OWNER : AccountStakeholder.GUARDIAN
)

const getFacialCaptures: Selector<RootState, DocumentFile[]> = (state) => state.control.facialCaptures ?? []

const getUserChoices: Selector<RootState, ControlUserChoices> = (state) => state.control.userChoices ?? {}

const getDocumentFiles: Selector<RootState, DocumentFile[]> = createSelector(
    [getDocumentProperties],
    pathOr([], ['files'])
)

const getDocumentFilesWithFacialCaptures: Selector<RootState, DocumentFile[]> = createSelector(
    [getDocumentFiles, getFacialCaptures],
    (documentFiles, facialCaptures) => documentFiles.concat(facialCaptures)
)

const getDocumentType: Selector<RootState, string> = createSelector(
    [getUserChoices, getDocumentId, getDocumentProperties],
    (userChoices, documentId, documentProperties) => {
        if (userChoices.documentTypes && documentId && isSet(userChoices.documentTypes[documentId])) {
            return userChoices.documentTypes[documentId]
        }
        return pathOr('', ['type'], documentProperties)
    }
)

const getBirthDataProperties: Selector<RootState, BirthDataControlProperties> = createSelector(
    [getUserChoices, (state: RootState) => state.control, getIsOwner],
    (userChoices, state, isOwner) => {
        if (isOwner)
            return {
                birthCountry:
                    userChoices?.birthOwnerData?.birthCountry ?? state?.birthDataProperties?.birthCountry ?? '',
                birthDate: userChoices?.birthOwnerData?.birthDate ?? state?.birthDataProperties?.birthDate ?? '',
                birthPlace: userChoices?.birthOwnerData?.birthPlace ?? state?.birthDataProperties?.birthPlace ?? '',
                birthRegion: userChoices?.birthOwnerData?.birthRegion ?? state?.birthDataProperties?.birthRegion ?? '',
                nationalities:
                    userChoices?.birthOwnerData?.nationalities ?? state?.birthDataProperties?.nationalities ?? []
            }
        return {
            birthCountry:
                userChoices?.birthGuardianData?.birthCountry ?? state?.birthDataProperties?.birthCountry ?? '',
            birthDate: userChoices?.birthGuardianData?.birthDate ?? state?.birthDataProperties?.birthDate ?? '',
            birthPlace: userChoices?.birthGuardianData?.birthPlace ?? state?.birthDataProperties?.birthPlace ?? '',
            birthRegion: userChoices?.birthGuardianData?.birthRegion ?? state?.birthDataProperties?.birthRegion ?? '',
            nationalities:
                userChoices?.birthGuardianData?.nationalities ?? state?.birthDataProperties?.nationalities ?? []
        }
    }
)

const getCivilDataProperties: Selector<RootState, CivilData | undefined> = (state) => state.control.civilDataProperties

const getCivilDataBeforeEdit: Selector<RootState, CivilData | undefined> = (state) => state.control.civilDataBeforeEdit

const getTaxIdentificationProperties: Selector<RootState, string | undefined> = (state) =>
    state.control.taxIdentificationProperties

const getOcrProperties: Selector<RootState, ControlOcrProperties | undefined> = (state) => state.control.ocrProperties

const getOverriddenOcrDataWarnings: Selector<RootState, string[] | undefined> = (state) =>
    state.control.overriddenOcrDataWarnings

const getPersonalNumber: Selector<RootState, string | undefined> = createSelector(
    [getUserChoices, getTaxIdentificationProperties, getIsOwner],
    (userChoices, taxIdentificationProperties, isOwner) => {
        if (isOwner) return userChoices?.documentNumberOwnerData?.personalNumber ?? taxIdentificationProperties
        return userChoices.documentNumberGuardianData?.personalNumber ?? taxIdentificationProperties
    }
)

const getDocumentNumber: Selector<RootState, string> = createSelector(
    [getUserChoices, getOcrProperties, getIsOwner],
    (userChoices, ocrProperties, isOwner) => {
        if (isOwner) return userChoices?.documentNumberOwnerData?.documentNumber ?? ocrProperties?.documentNumber ?? ''
        return userChoices?.documentNumberGuardianData?.documentNumber ?? ocrProperties?.documentNumber ?? ''
    }
)

const getExpirationDate: Selector<RootState, string | undefined> = createSelector(
    [getUserChoices, getOcrProperties, getIsOwner],
    (userChoices, ocrProperties, isOwner) => {
        if (isOwner) return userChoices.expirationDateOwner ?? ocrProperties?.expirationDate
        return userChoices.expirationDateGuardian ?? ocrProperties?.expirationDate
    }
)

const getFirstNames: Selector<RootState, string[] | undefined> = createSelector(
    [getUserChoices, getCivilDataProperties, getIsOwner],
    (userChoices, civilData, isOwner) => {
        if (isOwner) return userChoices.firstNamesOwner ?? civilData?.firstNames
        return userChoices.firstNamesGuardian ?? civilData?.firstNames
    }
)

const getIssuingCountry: Selector<RootState, string> = createSelector(
    [getUserChoices, getOcrProperties],
    (userChoices, ocrProperties) => {
        return userChoices.issuingCountry ?? ocrProperties?.issuingCountry ?? ''
    }
)

const getLastNames: Selector<RootState, string[] | undefined> = createSelector(
    [getUserChoices, getCivilDataProperties, getIsOwner],
    (userChoices, civilData, isOwner) => {
        if (isOwner) return userChoices.lastNamesOwner ?? civilData?.lastNames
        return userChoices.lastNamesGuardian ?? civilData?.lastNames
    }
)

const getMarriedLastName: Selector<RootState, string | undefined> = createSelector(
    [getUserChoices, getCivilDataProperties, getIsOwner],
    (userChoices, civilData, isOwner) => {
        if (isOwner) return userChoices.marriedNameOwner ?? civilData?.marriedName
        return userChoices.marriedNameGuardian ?? civilData?.marriedName
    }
)

const getMrzLines: Selector<RootState, string[]> = createSelector(
    [getUserChoices, getOcrProperties, getIsOwner],
    (userChoices, ocrProperties, isOwner) => {
        if (isOwner)
            return Array.isArray(userChoices.mrzLinesOwner) ? userChoices.mrzLinesOwner : ocrProperties?.mrzLines ?? []
        return Array.isArray(userChoices.mrzLinesGuardian)
            ? userChoices.mrzLinesGuardian
            : ocrProperties?.mrzLines ?? []
    }
)

const getPreferredLastName: Selector<RootState, string | undefined> = createSelector(
    [getUserChoices, getCivilDataProperties, getIsOwner],
    (userChoices, civilData, isOwner) => {
        if (isOwner) return userChoices.preferredLastNameOwner ?? civilData?.preferredLastName
        return userChoices.preferredLastNameGuardian ?? civilData?.preferredLastName
    }
)

const getSex: Selector<RootState, string> = createSelector(
    [getUserChoices, getOcrProperties, getIsOwner],
    (userChoices, ocrProperties, isOwner) => {
        if (isOwner) return userChoices.sexOwner ?? ocrProperties?.sex ?? ''
        return userChoices.sexGuardian ?? ocrProperties?.sex ?? ''
    }
)

const getWarnings: Selector<RootState, ControlWarningView[]> = (state) => state.control.warnings ?? []

const getProgress: Selector<RootState, RegistrationControlProgressView | undefined> = (state) => state.control.progress

const getProgressLabel: Selector<RootState, string | undefined> = createSelector([getProgress], (progress) => {
    if (typeof progress?.doneCount !== 'number' || typeof progress?.totalCount !== 'number') return

    const doneCount = String(progress.doneCount + 1).padStart(String(progress.totalCount).length, '0')
    return `${doneCount} / ${progress.totalCount}`
})

const getIsFirst: Selector<RootState, boolean> = createSelector([getProgress], (progress) => progress?.doneCount === 0)

const formValidators: {
    [key in RegistrationControl]?: Selector<RootState, boolean>
} = {
    [RegistrationControl.BIRTH_DATA]: createSelector([getBirthDataProperties], (birthData) => {
        return (
            isSet(birthData) &&
            isSet(birthData?.birthCountry) &&
            isSet(birthData?.birthDate) &&
            isSet(birthData?.birthPlace)
        )
    }),
    [RegistrationControl.DOCUMENT_NUMBER]: createSelector([getDocumentNumber], (documentNumber) =>
        isSet(documentNumber)
    ),
    [RegistrationControl.EXPIRATION_DATE]: createSelector(
        [getExpirationDate],
        (expirationDate) => isNotNil(expirationDate) && isSet(expirationDate) && isSameOrAfterToday(expirationDate)
    ),
    [RegistrationControl.FAMILY_DOCUMENT_TYPE]: createSelector([getDocumentType], isSet),
    [RegistrationControl.FIRST_NAMES]: createSelector(
        [getFirstNames],
        (firstNames) => !!firstNames && isArrayFilled(firstNames)
    ),
    [RegistrationControl.IDENTITY_DOCUMENT_TYPE]: createSelector([getDocumentType], isSet),
    [RegistrationControl.ISSUING_COUNTRY]: createSelector([getIssuingCountry], isSet),
    [RegistrationControl.LAST_NAMES]: createSelector(
        [getLastNames],
        (lastNames) => !!lastNames && isArrayFilled(lastNames)
    ),
    [RegistrationControl.SEX]: createSelector([getSex], isSet)
}

const getFormValidator = (controlId?: RegistrationControl) => controlId && formValidators[controlId]

const getIsFormValid: Selector<RootState, boolean> = createSelector(
    [getControlId, (state: RootState) => state],
    (controlId, state: RootState) => {
        const validator = getFormValidator(controlId)
        return validator ? validator(state) : true
    }
)

const getDocumentTypeContext: Selector<RootState, string | undefined> = createSelector(
    [getDocumentProperties],
    (documentProperties) => {
        if (!documentProperties?.type) return

        if (isFamilyDocument(documentProperties.type)) return 'family_document'
    }
)

export const selectors = {
    getBirthDataProperties,
    getCivilDataProperties,
    getCivilDataBeforeEdit,
    getControlId,
    getDocumentId,
    getDocumentNumber,
    getDocumentProperties,
    getDocumentTypeContext,
    getDocumentType,
    getDocumentFiles,
    getDocumentFilesWithFacialCaptures,
    getExpirationDate,
    getFirstNames,
    getHasError,
    getIsFirst,
    getIsOwner,
    getStakeHolder,
    getIsFormValid,
    getIssuingCountry,
    getLastNames,
    getMarriedLastName,
    getMrzLines,
    getOcrProperties,
    getOverriddenOcrDataWarnings,
    getPersonalNumber,
    getPreferredLastName,
    getProgress,
    getProgressLabel,
    getSex,
    getStatus,
    getWarnings
}

/* ------------- Utils ------------- */
