import { ReactNode } from 'react'

import { ComplianceStatus, RegistrationFormType } from '../../services/api'

export type RegitrationFormTableData = {
    assignee: string
    birthDate: string
    complianceStatus: ReactNode
    createdAt: string
    finalizationDate: string
    firstNames: string
    id: string
    lastNames: string
    registrationCode: ReactNode
    registrationCountry: string
    type: ReactNode
}

export const RegistrationFormComplianceStatus = {
    TODO: ComplianceStatus.TODO,
    COMPLIANT: ComplianceStatus.COMPLIANT,
    NOT_COMPLIANT: ComplianceStatus.NOT_COMPLIANT,
    ALL: ''
} as const
export type RegistrationFormComplianceStatusKeys = keyof typeof RegistrationFormComplianceStatus
export type RegistrationFormComplianceStatusValues = typeof RegistrationFormComplianceStatus[RegistrationFormComplianceStatusKeys]

export const RegistrationFormTypes = {
    ADULT: RegistrationFormType.ADULT,
    CHILD: RegistrationFormType.CHILD,
    ALL: ''
} as const
export type RegistrationFormTypesKeys = keyof typeof RegistrationFormTypes
export type RegistrationFormTypesValues = typeof RegistrationFormTypes[RegistrationFormTypesKeys]

export const RegistrationFormSorts = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const
export type RegistrationFormSortsKeys = keyof typeof RegistrationFormSorts
export type RegistrationFormSortsValues = typeof RegistrationFormSorts[RegistrationFormSortsKeys]
