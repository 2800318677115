import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { getMonth as dateGetMonth, getYear as dateGetYear } from 'date-fns'

import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

type ActivityReportState = {
    isFetching: boolean
    month: number
    year: number
}

export const INITIAL_STATE: ActivityReportState = {
    isFetching: false,
    month: dateGetMonth(new Date()),
    year: dateGetYear(new Date())
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@activity-report'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        fetchAsyncRequest: (state): ActivityReportState => ({
            ...state,
            isFetching: true
        }),
        fetchAsyncSuccess: (state): ActivityReportState => ({
            ...state,
            isFetching: false
        }),
        fetchAsyncFailure: (state): ActivityReportState => ({
            ...state,
            isFetching: false
        }),
        setMonth: (state, { payload }: PayloadAction<number>): ActivityReportState => ({
            ...state,
            month: payload
        }),
        setYear: (state, { payload }: PayloadAction<number>): ActivityReportState => ({
            ...state,
            year: payload
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getIsFetching: Selector<RootState, boolean> = (state) => state.activityReport.isFetching
const getMonth: Selector<RootState, number> = (state) => state.activityReport.month
const getYear: Selector<RootState, number> = (state) => state.activityReport.year

export const selectors = {
    getIsFetching,
    getMonth,
    getYear
}

/* ------------- Utils ------------- */
