import React from 'react'

import { useSelector } from 'react-redux'

import Pender from '@nickel/ui/components/legacy/Pender'

import { RegistrationControl } from '../../services/api'
import { ControlStatus, selectors as controlSelectors } from '../../store/controls'
import { selectors as detailsSelectors } from '../../store/details'

import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import HeaderCustomerData from './HeaderCustomerData'
import Styled from './styles'

const Control = () => {
    const controlId = useSelector(controlSelectors.getControlId) || RegistrationControl.UNKNOWN
    const controlStatus = useSelector(controlSelectors.getStatus)
    const details = useSelector(detailsSelectors.get)

    return (
        <>
            <HeaderCustomerData details={details} />
            <Styled.Container>
                <Header controlId={controlId} />
                {controlStatus === ControlStatus.IN_PROGRESS ? <Pender width={50} /> : <Body controlId={controlId} />}
                <Footer />
            </Styled.Container>
        </>
    )
}
export default Control
