import React, { useCallback } from 'react'

import { ArrowLeft, ArrowRight } from '@nickel/core-ui/components/Icons'

import GridImage from '../GridImage'
import { ImageParts } from '../GridImage/GridImage'

import NewWindow from './NewWindow'
import Styled from './styles'
import { rotate90 } from './utils'

const RIGHT_BUTTON_ID = 'rightButton'
const LEFT_BUTTON_ID = 'leftButton'
const CANVAS_ID = 'canvas'
export const NewWindowDocument = ({
    imageParts,
    newWindowRef,
    testId
}: {
    imageParts: ImageParts
    newWindowRef: Window | null
    testId: string
}) => {
    const setListeners = useCallback(() => {
        if (newWindowRef) {
            const canvas = newWindowRef.document.getElementById(CANVAS_ID) as HTMLCanvasElement
            const buttonRight = newWindowRef.document.getElementById(RIGHT_BUTTON_ID)
            const buttonLeft = newWindowRef.document.getElementById(LEFT_BUTTON_ID)

            buttonRight?.addEventListener('click', () => {
                rotate90(canvas, 'right')
            })
            buttonLeft?.addEventListener('click', () => {
                rotate90(canvas, 'left')
            })
        }
    }, [newWindowRef])

    return (
        <NewWindow newWindow={newWindowRef} customActions={setListeners}>
            <GridImage gridImage={imageParts} testId={testId} id={CANVAS_ID} />
            <button
                id={LEFT_BUTTON_ID}
                style={{
                    ...Styled.rotateButtonStyle,
                    left: '0'
                }}
                type="button"
            >
                <ArrowLeft />
            </button>
            <button
                id={RIGHT_BUTTON_ID}
                style={{
                    ...Styled.rotateButtonStyle,
                    right: '0'
                }}
                type="button"
            >
                <ArrowRight />
            </button>
        </NewWindow>
    )
}
