import { getYear } from 'date-fns'

import i18n from '@nickel/i18n'
import { Options } from '@nickel/ui/types'

export const getDateFormat = () => 'dd/MM/yyyy'

export const getHoursFormat = (separator = ':') => `HH${separator}mm`

export const getDateWithHoursFormat = (hoursSeparator = ':') => `${getDateFormat()} ${getHoursFormat(hoursSeparator)}`

export const addYearsToDate = (count: number, date: Date = new Date()) => date.setFullYear(date.getFullYear() + count)

export const getMonthOptions: () => Options<number> = () => {
    const months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ]
    return months.map((key, index) => ({ label: i18n.t(`common:months.${key}`), value: index }))
}

export const getYearOptions: (nbSubYears: number) => Options<number> = (nbSubYears: number) => {
    const currentYear = getYear(new Date())
    return [...Array(nbSubYears)].map((val, index) => ({
        label: `${currentYear - index}`,
        value: currentYear - index
    }))
}
