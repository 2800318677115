import styled from 'styled-components'

import { bo } from '@nickel/ui/themes'
import { Spacing } from '@nickel/ui/tokens'

const CopiedMessage = styled.div<{ copied: boolean }>`
    align-items: center;
    background: #ffffff;
    border-radius: ${bo.input.borderRadius};
    bottom: 0;
    display: flex;
    justify-content: center;
    opacity: ${({ copied }) => (copied ? 1 : 0)};
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 200ms opacity ease;
    width: 100%;

    svg {
        margin-right: ${Spacing.MEDIUM};
    }
`

const Wrapper = styled.div`
    background: #ffffff;
    border-radius: ${bo.input.borderRadius};
    position: relative;
`

export default {
    CopiedMessage,
    Wrapper
}
