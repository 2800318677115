import React, { Fragment, useCallback, useState } from 'react'

import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'

import Styled from './styles'

type Props = {
    isLeftPosition?: boolean
    ocrData?: string[]
}

const OverriddenOcrDataWarning = ({ isLeftPosition, ocrData }: Props) => {
    const { t } = useTranslation()

    const [isTooltipVisible, setIsTootipVisible] = useState(false)

    const _onMouseEnter = useCallback(() => {
        setIsTootipVisible(true)
    }, [setIsTootipVisible])

    const _onMouseLeave = useCallback(() => {
        setIsTootipVisible(false)
    }, [setIsTootipVisible])

    return (
        <Styled.Container onMouseEnter={_onMouseEnter} onMouseLeave={_onMouseLeave}>
            <Styled.Icon data-testid="overriddenOcrDataWarningIcon" />
            {isTooltipVisible && (
                <Styled.Tooltip data-testid="ocrDataWarning" isLeftPosition={isLeftPosition}>
                    {ocrData && !isEmpty(ocrData) ? (
                        // eslint-disable-next-line react/jsx-fragments
                        <Fragment>
                            <p>{t('controls:ocr-alert.ocr-data')}</p>
                            <ul>{ocrData.map((data) => !!data && <li key={data}>{data}</li>)}</ul>
                        </Fragment>
                    ) : (
                        <p>{t('controls:ocr-alert.no-ocr-data')}</p>
                    )}
                </Styled.Tooltip>
            )}
        </Styled.Container>
    )
}

export default React.memo(OverriddenOcrDataWarning)
