import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Select from '@nickel/ui/components/legacy/form/Select'

import {
    actions as registrationFormsActions,
    selectors as registrationFormsSelectors
} from '../../../store/registrationForms'
import { RegistrationFormTypes, RegistrationFormTypesValues } from '../types'

const RegistrationFormTypeFilter = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const storedValue = useSelector(registrationFormsSelectors.getSearchRegistrationFormType)

    const onChange = useCallback(
        (value: RegistrationFormTypesValues) => {
            dispatch(registrationFormsActions.setRegistrationFormType(value))
        },
        [dispatch]
    )
    const options = Object.values(RegistrationFormTypes).map((value) => ({
        value,
        label: t(`common:${value ? `registration-form-types.${value}` : 'all'}`)
    }))

    return (
        <Select
            light
            name="registration-form-type-select"
            onChange={onChange}
            options={options}
            placeholder={t('home:registration-form-type-filter')}
            label={t('home:registration-form-type-filter')}
            value={storedValue}
        />
    )
}

export default RegistrationFormTypeFilter
