import React, { useEffect, useRef } from 'react'

import { useForm } from 'react-form'
import { useDispatch, useSelector } from 'react-redux'

import BirthForm from '@nickel/kyc/forms/birth'

import { RegistrationFormType } from '../../services/api'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'
import { selectors as detailSelectors } from '../../store/details'

import Styled from './styles'
import { normalize } from './utils'

export interface BirthFormInterface {
    birth: {
        country?: string
        date?: string
        region?: string
        place?: string
        nationality?: string
        otherNationalities?: string[]
    }
}

const Form = () => {
    const dispatch = useDispatch()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const birthDataProperties = useSelector(controlsSelectors.getBirthDataProperties)
    const registrationFormType = useSelector(detailSelectors.getRegistrationFormType)

    const { birthCountry, birthDate, birthPlace, birthRegion, nationalities } = birthDataProperties || {}
    const [nationality, ...otherNationalities] = nationalities ?? []

    const initialValues = useRef({
        birth: {
            country: birthCountry,
            date: birthDate,
            place: normalize(birthPlace ?? ''),
            region: birthRegion,
            nationality,
            otherNationalities
        }
    })

    const { Form: ReactForm, values } = useForm({ defaultValues: initialValues.current })

    useEffect(() => {
        if (isOwner) {
            dispatch(controlsActions.setBirthDataOwnerValue(values))
        } else {
            dispatch(controlsActions.setBirthDataGuardianValue(values))
        }
    }, [dispatch, isOwner, values])

    return (
        <Styled.Container>
            <ReactForm>
                <BirthForm
                    columns={{ mobile: 1, tablet: 1, desktop: 1 }}
                    isChild={isOwner && registrationFormType === RegistrationFormType.CHILD}
                    withOldLocalities
                />
            </ReactForm>
        </Styled.Container>
    )
}

export default React.memo(Form)
