import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { filter, flatten, isEmpty, not, pipe } from 'ramda'

import {
    AccountStakeholder,
    BirthDataView,
    CivilDataView,
    RegistrationDocumentView,
    RegistrationFormType,
    RegistrationFormView
} from '../../services/api'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

export type DocumentFile = {
    id: string
    key: string
    mimeType?: string
    createdAt?: string
}

export type RegistrationDocument = Pick<RegistrationDocumentView, 'accountStakeHolder' | 'id' | 'type'> & {
    files: DocumentFile[]
}

export type Details = RegistrationFormView &
    BirthDataView &
    CivilDataView & {
        registrationDocuments?: RegistrationDocument[]
        facialCaptures?: DocumentFile[]
    }

type DocumentdetailsState = {
    isFetching: boolean
    data: Details
}

export const INITIAL_STATE: DocumentdetailsState = {
    isFetching: false,
    data: {}
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@document-details'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        fetchRequest: (state): DocumentdetailsState => ({
            ...state,
            isFetching: true
        }),
        fetchSuccess: (state, { payload }: PayloadAction<Details>): DocumentdetailsState => ({
            ...state,
            isFetching: false,
            data: payload
        }),
        fetchFailure: (state): DocumentdetailsState => ({
            ...state,
            isFetching: false
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const get: Selector<RootState, Details> = (state) => state.details.data

const getIsFetching: Selector<RootState, boolean> = (state) => state.details.isFetching

const getDocuments: Selector<RootState, RegistrationDocument[]> = createSelector(
    [get],
    (details) => details.registrationDocuments ?? []
)

const getFacialCaptures: Selector<RootState, DocumentFile[]> = createSelector(
    [get],
    (details) => details.facialCaptures ?? []
)

const getOwnerDocuments: Selector<RootState, RegistrationDocument[]> = createSelector(
    [getDocuments],
    filter((doc: RegistrationDocument) => doc.accountStakeHolder === AccountStakeholder.OWNER)
)
const getGuardianDocuments: Selector<RootState, RegistrationDocument[]> = createSelector(
    [getDocuments],
    filter((doc: RegistrationDocument) => doc.accountStakeHolder === AccountStakeholder.GUARDIAN)
)

const getHasGuardianDocuments: Selector<RootState, boolean> = createSelector([getGuardianDocuments], pipe(isEmpty, not))

const getOwnerDocumentsFiles: Selector<RootState, DocumentFile[]> = createSelector(
    [getOwnerDocuments, getFacialCaptures, getHasGuardianDocuments],
    (ownerDocuments, facialCaptures, hasGuardianDocuments) => {
        const ownerFiles = flatten(ownerDocuments.map((doc) => doc.files))
        return ownerFiles.concat(hasGuardianDocuments ? [] : facialCaptures)
    }
)
const getGuardianDocumentsFiles: Selector<RootState, DocumentFile[]> = createSelector(
    [getGuardianDocuments, getFacialCaptures, getHasGuardianDocuments],
    (guardianDocuments, facialCaptures, hasGuardianDocuments) => {
        const guardianFiles = flatten(guardianDocuments.map((doc) => doc.files))
        return guardianFiles.concat(hasGuardianDocuments ? facialCaptures : [])
    }
)

const getRegistrationFormType: Selector<RootState, RegistrationFormType | undefined> = createSelector(
    [get],
    (details) => details.registrationFormType
)

export const selectors = {
    get,
    getFacialCaptures,
    getHasGuardianDocuments,
    getIsFetching,
    getGuardianDocumentsFiles,
    getOwnerDocumentsFiles,
    getRegistrationFormType
}

/* ------------- Utils ------------- */
