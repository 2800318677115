import { ModalData } from '../../config/errors'
import { actions as navigationActions } from '../navigation'

export const noOcrPropertiesModalData: ModalData = {
    message: 'controls:modals.no-ocr-properties.message',
    primaryAction: {
        text: 'common:continue'
    },
    secondaryAction: {
        action: navigationActions.goBack(),
        text: 'common:cancel'
    },
    title: 'controls:modals.no-ocr-properties.title'
}
