import React, { useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import i18n from '@nickel/i18n'
import Field from '@nickel/ui/components/legacy/form/Field'
import Select from '@nickel/ui/components/legacy/form/Select'
import { Spacing } from '@nickel/ui/tokens'

import { getMonthOptions, getYearOptions } from '../l10n/date-utils'
import { selectors as activityReportSelectors, actions as activityReportActions } from '../store/activityReport'

const ActivityReportForm = styled.div`
    display: flex;
    margin-top: ${Spacing.XXLARGE};
    text-align: left;

    > * {
        margin-right: ${Spacing.XXLARGE};

        &:last-child {
            margin-right: 0;
        }
    }
`

const DetailsError = styled.div`
    text-align: left;
`

export const TemplateType = {
    DETAILS_ERROR: 'DETAILS_ERROR',
    ERROR: 'ERROR',
    EXPORT_ACTIVITY_REPORT: 'EXPORT_ACTIVITY_REPORT',
    LAST_NAMES_MODIFICATION: 'LAST_NAMES_MODIFICATION',
    FIRST_NAMES_MODIFICATION: 'FIRST_NAMES_MODIFICATION',
    ALREADY_KNOWN_CUSTOMER: 'ALREADY_KNOWN_CUSTOMER'
}

export const modalTemplateMap = new Map()

modalTemplateMap.set(TemplateType.ERROR, (_: unknown, message: string | string[]) => (
    <div className="cn-modal-content cn-modal-content--error">
        <i className="icon icon-close-circle" />
        <p className="cn-text-default">{i18n.t(message)}</p>
    </div>
))

modalTemplateMap.set(TemplateType.ALREADY_KNOWN_CUSTOMER, (_: unknown, message: string | string[]) => (
    <div className="cn-modal-content cn-modal-content--error">
        <i className="icon icon-close-circle" />
        <p className="cn-text-default">{i18n.t(message)}</p>
        <p className="cn-text-default">
            <a href={window.REACT_APP_ZENDESK_URL} target="_blank" rel="noreferrer">
                {i18n.t('common:open-zendesk')}
            </a>
        </p>
    </div>
))

modalTemplateMap.set(TemplateType.DETAILS_ERROR, (_: unknown, errors: string[]) => {
    return (
        <DetailsError>
            <span>{i18n.t('details:errors.message')}</span>
            <ul>
                {errors.map((error) => (
                    <li key={error}>{i18n.t(`details:errors.list.${error}`)}</li>
                ))}
            </ul>
        </DetailsError>
    )
})

modalTemplateMap.set(TemplateType.EXPORT_ACTIVITY_REPORT, () => {
    const dispatch = useDispatch()

    const month = useSelector(activityReportSelectors.getMonth)
    const year = useSelector(activityReportSelectors.getYear)

    const months = useMemo(getMonthOptions, [])
    const years = useMemo(() => getYearOptions(50), [])

    const onMouthChange = useCallback(
        (value) => {
            dispatch(activityReportActions.setMonth(value))
        },
        [dispatch]
    )
    const onYearChange = useCallback(
        (value) => {
            dispatch(activityReportActions.setYear(value))
        },
        [dispatch]
    )

    return (
        <>
            <span>{i18n.t('activity-report:modal.message')}</span>
            <ActivityReportForm>
                <Field htmlFor="month" label={i18n.t('activity-report:modal.month')}>
                    <Select name="month" onChange={onMouthChange} options={months} value={month} />
                </Field>
                <Field htmlFor="year" label={i18n.t('activity-report:modal.year')}>
                    <Select name="year" onChange={onYearChange} options={years} value={year} />
                </Field>
            </ActivityReportForm>
        </>
    )
})
