import { all, call, put, select } from 'redux-saga/effects'

import { ControlDependency, actions as controlsActions, selectors as controlsSelectors } from '..'
import {
    RegistrationControl,
    RegistrationDocumentOcrPropertiesView,
    RegistrationDocumentView
} from '../../../services/api'
import { fetchBirthAndNationalitiesData, fetchCivilData, fetchTaxId } from '../../details/sagas'
import { fetchDocument } from '../../documents/sagas'
import { fetchLastFacialCapture } from '../../facialCaptures/sagas'
import { selectors as ocrPropertiesSelectors } from '../../ocrProperties'
import { fetchOcrProperties } from '../../ocrProperties/sagas'
import { getOverriddenOcrDataWarnings } from '../utils'

export function* _fetchOcrProperties(documentId: string) {
    const ocrProperties: RegistrationDocumentOcrPropertiesView = yield call(fetchOcrProperties, documentId)
    if (!ocrProperties) return

    const { documentNumber, expirationDate, issuingCountry, mrzLines } = ocrProperties
    put(controlsActions.setOcrProperties({ documentNumber, expirationDate, issuingCountry, mrzLines }))

    return ocrProperties
}

const controlDependencies: ControlDependency[] = [
    {
        dependents: [
            RegistrationControl.BIRTH_DATA,
            RegistrationControl.DOCUMENT_NUMBER,
            RegistrationControl.EXPIRATION_DATE,
            RegistrationControl.FIRST_NAMES,
            RegistrationControl.ISSUING_COUNTRY,
            RegistrationControl.LAST_NAMES,
            RegistrationControl.MRZ_LINES,
            RegistrationControl.SEX
        ],
        func: _fetchOcrProperties,
        id: 'registrationDocumentId',
        name: 'ocrProperties'
    },
    {
        dependents: [RegistrationControl.BIRTH_DATA],
        func: fetchBirthAndNationalitiesData,
        hasStakeHolderParam: true,
        id: 'formId',
        name: 'birthDataProperties'
    },
    {
        dependents: [RegistrationControl.FIRST_NAMES, RegistrationControl.LAST_NAMES],
        func: fetchCivilData,
        hasStakeHolderParam: true,
        id: 'formId',
        name: 'civilDataProperties'
    },
    {
        dependents: [RegistrationControl.DOCUMENT_NUMBER],
        func: fetchTaxId,
        hasStakeHolderParam: true,
        id: 'formId',
        name: 'taxIdentificationProperties'
    },
    {
        dependents: [RegistrationControl.PHOTO],
        func: fetchLastFacialCapture,
        id: 'formId',
        name: 'facialCaptures'
    }
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* get(data: any) {
    const documentProperties: RegistrationDocumentView = yield call(fetchDocument, data.registrationDocumentId)
    const dependencies = controlDependencies.filter(({ dependents }) => dependents.includes(data.control))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propertiesArray: any[] = yield all(
        dependencies.map(({ func, hasStakeHolderParam, id }) => {
            if (hasStakeHolderParam) return call(func, data[id], documentProperties.accountStakeHolder)
            return call(func, data[id])
        })
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const properties = propertiesArray.reduce((acc: any, props: any, index: number) => {
        const { name } = dependencies[index]
        acc[name] = props
        return acc
    }, {})
    properties.documentProperties = documentProperties
    const ocrProperties: RegistrationDocumentOcrPropertiesView = yield select(
        ocrPropertiesSelectors.getOcrPropertiesByDocumentId(data.registrationDocumentId)
    )
    const issuingCountry: string = yield select(controlsSelectors.getIssuingCountry)

    const overriddenOcrDataWarnings = getOverriddenOcrDataWarnings(
        data.control,
        properties,
        ocrProperties,
        issuingCountry
    )
    yield put(controlsActions.setOverriddenOcrDataWarnings({ warnings: overriddenOcrDataWarnings }))

    return properties
}
