import { AxiosResponse } from 'axios'
import { addMonths, format, subDays } from 'date-fns'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import i18n from '@nickel/i18n'
import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import { StimulusType } from '../../config/errors'
import { statisticsResourceApi } from '../../services'
import { NotCompliantCauseCountView, NotCompliantCause, NotCompliantCauseDistributionView } from '../../services/api'
import { http } from '../utils'

import { actions as activityReportActions, selectors as activityReportSelectors } from '.'

function downloadActivityReportCsv(data: NotCompliantCauseCountView[], month: number, year: number, total = 0) {
    const getCount = (row: NotCompliantCause) => {
        const rowData = data.find((obj) => obj.notCompliantCause === row)
        return rowData ? rowData.count : 0
    }

    const monthLabel = month < 10 ? `0${month}` : month
    const columns = [
        i18n.t('activity-report:csv-columns.blocking-type'),
        i18n.t('activity-report:csv-columns.blocked-customer-volume'),
        i18n.t('activity-report:csv-columns.blocking-month'),
        i18n.t('activity-report:csv-columns.total-registrations')
    ]
    const rows = Object.values(NotCompliantCause).sort()

    const content = `data:text/csv;charset=utf-8,${columns.join(',')}\n${rows
        .map((row) =>
            [i18n.t(`activity-report:csv-rows.${row}`), getCount(row), `${monthLabel}/${year}`, total].join(',')
        )
        .join('\n')}`

    const encodedUri = encodeURI(content)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `activity_report_${monthLabel}_${year}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

function* fetch() {
    try {
        const month: number = yield select(activityReportSelectors.getMonth)
        const year: number = yield select(activityReportSelectors.getYear)

        const fromDate = new Date(year, month, 1)
        const toDate = subDays(addMonths(fromDate, 1), 1)

        const response: AxiosResponse<NotCompliantCauseDistributionView> = yield call(
            http(),
            statisticsResourceApi.getNotCompliantCauseDistribution,
            format(fromDate, 'yyyy-MM-dd'),
            format(toDate, 'yyyy-MM-dd')
        )
        const {
            data: { data, totalRegistrationsToModerate }
        } = response
        yield call(downloadActivityReportCsv, data ?? [], month + 1, year, totalRegistrationsToModerate)
        yield put(activityReportActions.fetchAsyncSuccess())
    } catch {
        yield put(activityReportActions.fetchAsyncFailure())
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}

export default function* sagas() {
    yield all([takeLatest(activityReportActions.fetchAsyncRequest, fetch)])
}
