import React, { useCallback } from 'react'

import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@nickel/ui/components/legacy/Button'

import ControlWarnings from '../../../components/ControlWarnings'
import DocumentCheckerAlert from '../../../components/DocumentCheckerAlert'
import DocumentsList from '../../../components/DocumentsList'
import OverriddenOcrDataWarning from '../../../components/OverriddenOcrDataWarning'
import controls from '../../../controls'
import { Control as ControlType } from '../../../controls/types'
import { RegistrationControl } from '../../../services/api'
import { selectors as controlSelectors } from '../../../store/controls'
import { selectors as documentsSelectors, actions as documentsActions } from '../../../store/documents'

import Styled from './styles'

type Props = {
    controlId: RegistrationControl
}

const Body = ({ controlId }: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const documentTypeContext = useSelector(controlSelectors.getDocumentTypeContext)
    const isFormValid = useSelector(controlSelectors.getIsFormValid)
    const warnings = useSelector(controlSelectors.getWarnings)
    const overriddenOcrDataWarnings = useSelector(controlSelectors.getOverriddenOcrDataWarnings)

    const { actions, Form, hasDocumentChecker, hasFacialCaptures }: ControlType = controls[controlId]

    const files = useSelector(
        hasFacialCaptures ? controlSelectors.getDocumentFilesWithFacialCaptures : controlSelectors.getDocumentFiles
    )

    const documentRotateDegrees = useSelector(documentsSelectors.getDocumentFileConfigurationRotateDegrees)

    const dispatchActionWithContext = useCallback(
        (action) => {
            const { isWithI18nContext } = action.payload
            if (!isWithI18nContext) return dispatch(action)

            const actionWithContext = {
                ...action,
                payload: {
                    ...action.payload,
                    i18nContext: documentTypeContext
                }
            }
            dispatch(actionWithContext)
        },
        [dispatch, documentTypeContext]
    )

    const onRotateDocument = useCallback(
        (id: string, rotateDegrees: number) => {
            dispatch(
                documentsActions.setDocumentFileConfigurationRotateDegrees({
                    [id]: rotateDegrees
                })
            )
        },
        [dispatch]
    )

    return (
        <Styled.Container>
            {(!isEmpty(warnings) || hasDocumentChecker) && (
                <Styled.Cards>
                    {!isEmpty(warnings) && <ControlWarnings warnings={warnings} />}
                    {hasDocumentChecker && <DocumentCheckerAlert />}
                </Styled.Cards>
            )}
            <Styled.RowWrapper>
                <Styled.Row>
                    <Styled.DocumentsListWrapper>
                        <DocumentsList
                            files={files}
                            rotateDegrees={documentRotateDegrees}
                            onRotateDocument={onRotateDocument}
                        />
                    </Styled.DocumentsListWrapper>
                    <Styled.RightSide hasForm={!!Form}>
                        {Form && (
                            <Styled.FormWrapper>
                                {overriddenOcrDataWarnings && overriddenOcrDataWarnings.length > 0 && (
                                    <OverriddenOcrDataWarning ocrData={overriddenOcrDataWarnings} />
                                )}
                                <Form />
                            </Styled.FormWrapper>
                        )}
                        <Styled.FormButtons>
                            {actions.invalidate?.map(({ action, label = 'common:no' }) => (
                                <Button
                                    key={label}
                                    onClick={() => dispatchActionWithContext(action)}
                                    testId={
                                        label.toLowerCase().includes('doubt')
                                            ? 'doubtControlButton'
                                            : 'invalidControlButton'
                                    }
                                    text={t(label)}
                                    variant="secondary"
                                />
                            ))}
                            {actions.validate?.map(({ action, label = 'common:yes' }) => (
                                <Styled.ValidateButton
                                    disabled={!isFormValid}
                                    key={label}
                                    onClick={() => dispatchActionWithContext(action)}
                                    testId="validControlButton"
                                    text={t(label)}
                                />
                            ))}
                        </Styled.FormButtons>
                    </Styled.RightSide>
                </Styled.Row>
            </Styled.RowWrapper>
        </Styled.Container>
    )
}

export default Body
