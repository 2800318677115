import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontSize, Spacing } from '@nickel/ui/tokens'

const LinkItem = styled(Link)`
    pointer-events: none;
`

const SearchBarContainer = styled.div<{ isCollapsed: boolean }>`
    align-items: center;
    display: flex;
    padding: 0 ${Spacing.LARGE} ${Spacing.LARGE};
    transition: 200ms all ease-in;
    z-index: 2;

    > * {
        margin: 0 ${Spacing.XLARGE} 0 0;

        &:last-child {
            flex: 1;
            margin: 0;
        }
    }

    ${(props) =>
        props.isCollapsed &&
        `
            transform: translateX(3.75rem)
        `}
`

const TableContainer = styled.div`
    flex-grow: 1;
    overflow: auto;
`

const Container = styled.div`
    display: flex;
    margin: 0 auto;
`
const Label = styled.span`
    align-items: center;
    display: flex;
    font-size: ${FontSize.SMALL};
`
const Footer = styled.div`
    display: flex;
`
export default {
    LinkItem,
    SearchBarContainer,
    TableContainer,
    Footer,
    Label,
    Container
}
