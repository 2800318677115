import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Select from '@nickel/ui/components/legacy/form/Select'

import { FamilyDocumentType } from '../../services/api'
import { actions as controlsActions, selectors as controlSelectors } from '../../store/controls'

const Form = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const documentId = useSelector(controlSelectors.getDocumentId)
    const familyDocumentType = useSelector(controlSelectors.getDocumentType)

    const onFamilyDocumentTypeChange = useCallback(
        (documentType: FamilyDocumentType) => {
            if (documentId) dispatch(controlsActions.setDocumentType({ documentId, documentType }))
        },
        [dispatch, documentId]
    )

    const options = useMemo(
        () =>
            Object.values(FamilyDocumentType).map((value) => ({
                label: t(`controls:FAMILY_DOCUMENT_TYPE.options.${value}`),
                value
            })),
        [t]
    )

    return (
        <Select
            name="family-document-type"
            onChange={onFamilyDocumentTypeChange}
            options={options}
            // @ts-expect-error @nickel/ui select types
            value={familyDocumentType}
        />
    )
}

export default Form
