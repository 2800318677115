import { RegistrationControl } from '../services/api'

import BIRTH_DATA from './BIRTH_DATA'
import CHIP from './CHIP'
import COLORS from './COLORS'
import DOCUMENT_NUMBER from './DOCUMENT_NUMBER'
import DOCUMENT_NUMBER_POSITION from './DOCUMENT_NUMBER_POSITION'
import EXPIRATION_DATE from './EXPIRATION_DATE'
import FAMILY_DOCUMENT_READABILITY from './FAMILY_DOCUMENT_READABILITY'
import FAMILY_DOCUMENT_TYPE from './FAMILY_DOCUMENT_TYPE'
import FIRST_NAMES from './FIRST_NAMES'
import FONT from './FONT'
import FRENCH_REPUBLIC_MENTIONS from './FRENCH_REPUBLIC_MENTIONS'
import HOLOGRAMS from './HOLOGRAMS'
import IDENTITY_DOCUMENT_READABILITY from './IDENTITY_DOCUMENT_READABILITY'
import IDENTITY_DOCUMENT_TYPE from './IDENTITY_DOCUMENT_TYPE'
import ISSUING_COUNTRY from './ISSUING_COUNTRY'
import LAST_NAMES from './LAST_NAMES'
import MRZ_LINES from './MRZ_LINES'
import PHOTO from './PHOTO'
import PHOTOCOPY from './PHOTOCOPY'
import SEX from './SEX'
import SIDES from './SIDES'
import SIGNATURE from './SIGNATURE'
import { Control } from './types'
import UNKNOWN from './UNKNOWN'

const controls: Record<RegistrationControl, Control> = {
    BIRTH_DATA,
    CHIP,
    COLORS,
    DOCUMENT_NUMBER,
    DOCUMENT_NUMBER_POSITION,
    EXPIRATION_DATE,
    FAMILY_DOCUMENT_READABILITY,
    FAMILY_DOCUMENT_TYPE,
    FIRST_NAMES,
    FONT,
    FRENCH_REPUBLIC_MENTIONS,
    HOLOGRAMS,
    IDENTITY_DOCUMENT_READABILITY,
    IDENTITY_DOCUMENT_TYPE,
    ISSUING_COUNTRY,
    LAST_NAMES,
    MRZ_LINES,
    PHOTO,
    PHOTOCOPY,
    SEX,
    SIDES,
    SIGNATURE,
    UNKNOWN
}

export default controls
