import { TemplateType } from '../../config/templates'
import { RegistrationControl, UpdateChipPresencePayload, ChipPresence } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'

const doubt = {
    message: 'controls:CHIP.modals.doubt.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.CHIP,
            controlPayload: {
                presence: ChipPresence.DOUBT
            } as UpdateChipPresencePayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:CHIP.title'
}

const notCompliant = {
    message: 'controls:CHIP.modals.notCompliant.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.CHIP,
            controlPayload: {
                presence: ChipPresence.NOT_COMPLIANT
            } as UpdateChipPresencePayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:CHIP.title'
}

export default {
    doubt,
    notCompliant
}
