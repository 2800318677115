import React from 'react'

import styled from 'styled-components'

type Props = {
    color?: string
    label?: string
}

const Label = styled.span<Props>`
    color: ${(props) => props.color};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const ColorItemRendered = ({ color, label }: Props) => <Label color={color}>{label}</Label>

export default ColorItemRendered
