import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import { COUNTRY_CODE } from '@nickel/l10n/constants'

import CopiableInfo from '../../../components/CopiableInfo'
import OnfidoReport from '../../../components/OnfidoReport'
import { RegistrationFormType, Sex } from '../../../services/api'
import { Details } from '../../../store/details'
import { selectors as registrationFormsSelectors } from '../../../store/registrationForms'

import Styled from './styles'
import useCustomer from './useCustomer'

type Props = {
    details: Details
}

const HeaderCustomerData = ({ details }: Props) => {
    const { t } = useTranslation()
    const { customerId } = useCustomer()
    const subscriptionCountry = useSelector(registrationFormsSelectors.getSearchCountry)

    const { lastNames, firstNames, registrationCode, registrationCodeParent, registrationFormType, sex } = details
    const civility = sex === Sex.MALE ? t('details:customer-civility.male') : t('details:customer-civility.female')
    const fullNameLabel = useMemo(
        () => `${lastNames?.map((lastName) => lastName.toUpperCase())?.join(' ') ?? ''} ${firstNames?.join(' ') ?? ''}`,
        [lastNames, firstNames]
    )
    const BO_CLIENT_DETAIL_ASPX = '/ClientDetails.aspx?ref='

    return (
        <>
            <Styled.Container>
                {fullNameLabel.length > 1 ? (
                    <Styled.ContainerFullName>
                        {subscriptionCountry !== COUNTRY_CODE.DE && (
                            <>
                                <Styled.Civility>{civility}</Styled.Civility>
                                &nbsp;
                            </>
                        )}
                        <Styled.Name>{fullNameLabel}</Styled.Name>
                        &nbsp;
                        {customerId ? (
                            <Styled.Link
                                href={`${window.REACT_APP_BO_CUSTOMER_CARD_URL + BO_CLIENT_DETAIL_ASPX + customerId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('details:customer-card')}
                            </Styled.Link>
                        ) : null}
                    </Styled.ContainerFullName>
                ) : (
                    <Skeleton width={150} height={20} />
                )}

                <CopiableInfo
                    tooltipPosition="bottom"
                    info={registrationCode}
                    testId="registrationCode"
                    successText={t('common:registrationCode.copied')}
                    failureText={t('common:registrationCode.erreur')}
                />

                {registrationFormType === RegistrationFormType.CHILD && registrationCodeParent ? (
                    <CopiableInfo
                        tooltipPosition="bottom"
                        info={registrationCodeParent}
                        testId="parentRegistrationCode"
                        successText={t('common:parentRegistrationCode.copied')}
                        failureText={t('common:parentRegistrationCode.erreur')}
                    />
                ) : null}
                <OnfidoReport />
            </Styled.Container>
        </>
    )
}

export default HeaderCustomerData
