import { RegistrationControl } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import form from './form'

const control: Control = {
    actions: {
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.FIRST_NAMES
                }),
                label: 'common:continue'
            }
        ]
    },
    Form: form
}

export default control
