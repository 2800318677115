import { all, call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, isStimulus } from '@nickel/stimulus/stimulus'

import { registrationDocumentResourceApi, registrationDocumentResourceV1Api } from '../../services'
import { RegistrationDocumentFileView, RegistrationDocumentView } from '../../services/api'
import { ERRORS } from '../errors'
import { selectors as navigationSelectors } from '../navigation'
import { http } from '../utils'

import { setDataToLocalStorage } from './utils'

export function* downloadFile(fileId: string, documentId: string, formId: string) {
    const { data } = yield call(
        http(),
        registrationDocumentResourceV1Api.downloadRegistrationDocumentFileParts,
        formId,
        documentId,
        fileId
    )
    return (yield call(setDataToLocalStorage, data)) as string
}

export function* fetchDocuments() {
    try {
        const currentRegistrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const { data } = yield call(
            http(),
            registrationDocumentResourceApi.getRegistrationDocuments,
            currentRegistrationFormId
        )
        return data
    } catch (err) {
        return new Error(ERRORS.DOCUMENTS)
    }
}

export function* fetchDocument(documentId: string) {
    try {
        const currentRegistrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const { data }: { data: RegistrationDocumentView } = yield call(
            http(),
            registrationDocumentResourceApi.getRegistrationDocument,
            currentRegistrationFormId,
            documentId
        )

        if (!data.files) return []

        const fileKeys: string[] = yield all(
            data.files.map((file: RegistrationDocumentFileView) => {
                if (!file.id) return ''
                return call(downloadFile, file.id, documentId, currentRegistrationFormId)
            })
        )

        const files = data.files.map((file: RegistrationDocumentFileView, index: number) => ({
            ...file,
            key: fileKeys[index]
        }))

        return { ...data, files }
    } catch (err) {
        if (isStimulus(err)) {
            yield put(stimulusActions.handleStimulus(err))
        }
    }
}
