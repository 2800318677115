import { goBack as _goBack, LOCATION_CHANGE, LocationChangeAction, push } from 'connected-react-router'
import { isEmpty } from 'ramda'
import { AnyAction } from 'redux'
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { ROUTES } from '../../navigation/constants'
import { isControl, isDetails, isHome } from '../../navigation/utils'
import { actions as controlsActions } from '../controls'
import { actions as detailsActions, Details, selectors as detailsSelectors } from '../details'
import { selectors as documentsSelectors } from '../documents'

import { actions as navigationActions } from '.'

export function* goTo({ payload: { pathname } }: AnyAction) {
    if (pathname === ROUTES.HOME) {
        const windowsToClose: Record<string, Window | null> = yield select(
            documentsSelectors.getOpenedDocumentsInExternalWindow
        )
        Object.values(windowsToClose).forEach((windowToClose) => windowToClose?.close())
    }
    yield put(push(pathname))
}

export const goToExternal = ({ payload: { pathname } }: AnyAction) => {
    window.location.href = pathname
}

export function* goBack() {
    yield put(_goBack())
}

export function* handleLocationChange({
    payload: {
        location: { pathname }
    }
}: LocationChangeAction) {
    if (isControl(pathname)) {
        yield put(controlsActions.start())

        const details: Details = yield select(detailsSelectors.get)
        if (isEmpty(details)) yield put(detailsActions.fetchRequest())
        return
    }
    if (isDetails(pathname)) {
        yield put(detailsActions.fetchRequest())
        return
    }
    if (isHome(pathname)) {
        return
    }

    yield put(navigationActions.goTo({ pathname: ROUTES.HOME }))
}

export default function* sagas() {
    yield all([
        takeEvery(LOCATION_CHANGE, handleLocationChange),
        takeEvery(navigationActions.goBack, goBack),
        takeLatest(navigationActions.goTo, goTo),
        takeLatest(navigationActions.goToExternal, goToExternal)
    ])
}
