import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Select from '@nickel/ui/components/legacy/form/Select'

import { Sex } from '../../services/api'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'
import { RootState } from '../../store/rootReducer'

const Form = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const sex = useSelector<RootState, string | undefined>(controlsSelectors.getSex)

    const onSexChange = useCallback(
        (value) => {
            if (isOwner) {
                dispatch(controlsActions.setSexOwner({ sexOwner: value }))
            } else {
                dispatch(controlsActions.setSexGuardian({ sexGuardian: value }))
            }
        },
        [dispatch, isOwner]
    )

    const options = useMemo(
        () =>
            Object.values(Sex)
                .filter((value) => value !== 'UNKNOWN')
                .map((value) => ({
                    label: t(`controls:SEX.options.${value}`),
                    value
                })),
        [t]
    )

    return <Select name="sex" options={options} value={sex} onChange={onSexChange} />
}

export default Form
