import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui/redux/actions'

import { StimulusType } from '../../config/errors'
import modals from '../../controls/modals'
import { controlResourceApi } from '../../services'
import { UpdateFirstNamesPayload, UpdateFirstNamesPayloadModificationReasonsEnum } from '../../services/api'
import { actions as controlsActions, selectors as controlSelectors } from '../controls'
import { selectors as navigationSelectors } from '../navigation'
import { http, isAlreadyKnownCustomer } from '../utils'

import { actions as firstNamesActions, selectors as firstNamesSelectors } from '.'

/**
 * Soumet l'écran et passe à l'étape suivate
 * @param payload contient les données provenant de la vue
 */
export function* submitControls(payload: UpdateFirstNamesPayload): SagaIterator {
    try {
        const currentRegistrationFormId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId: string = yield select(controlSelectors.getDocumentId)
        const popinState = yield select(firstNamesSelectors.getPopinState)
        if (popinState) {
            yield put(firstNamesActions.openCloseControlPopin())
        }
        yield call(
            http({ maxTries: 1 }),
            controlResourceApi.updateFirstNames,
            currentRegistrationFormId,
            documentId,
            payload
        )
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        if (isAlreadyKnownCustomer(err)) {
            yield put(
                uiActions.showModal(
                    modals.createExistingCustomerModal({
                        registrationCode: err.response?.data.params.registrationCode,
                        existingCustomerRegistrationCode: err.response?.data.params.existingCustomerRegistrationCode
                    })
                )
            )
        } else {
            yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
        }
    }
}

/**
 * Soumet les raisons de modification et passe à l'écran suivant
 */
export function* submitModificationReasonAndEndScreen(): SagaIterator {
    const firstNames: string[] = yield select(controlSelectors.getFirstNames)
    const modificationReasons: UpdateFirstNamesPayloadModificationReasonsEnum[] = yield select(
        firstNamesSelectors.getModificationReason
    )
    const payload: UpdateFirstNamesPayload = { firstNames, modificationReasons }
    yield call(submitControls, payload)
}

export default function* sagas() {
    yield all([takeLatest(firstNamesActions.popinValidation, submitModificationReasonAndEndScreen)])
}
