import React, { ReactNode } from 'react'

import IdentityProvider from '@nickel/bo-authentication/context/IdentityProvider'
import useAxiosToken from '@nickel/bo-authentication/hooks/useAxiosToken'
import useBoAuthentication from '@nickel/bo-authentication/hooks/useBoAuthentication'
import { UserAuthentication } from '@nickel/bo-authentication/model/userAuthentication'
import { setCurrentAccessToken } from '@nickel/bo-authentication/utils'

type AuthProviderProps = {
    children: ReactNode
}

const AuthIdentityProvider = ({ children }: AuthProviderProps) => {
    const userAuthentication = useBoAuthentication()
    useAxiosToken(userAuthentication.accessToken)

    if (!userAuthentication.accessToken) return null

    return <IdentityProvider value={userAuthentication}>{children}</IdentityProvider>
}

const ALREADY_AUTH: UserAuthentication = {
    accessToken: 'dummy',
    authenticated: true
}
const NOT_AUTH: UserAuthentication = { authenticated: false }

const AuthProvider = ({ children }: AuthProviderProps) => {
    if (window.Cypress) {
        setCurrentAccessToken(ALREADY_AUTH.accessToken ?? '')
    }

    return (
        <IdentityProvider value={window.Cypress ? ALREADY_AUTH : NOT_AUTH}>
            <AuthIdentityProvider>{children}</AuthIdentityProvider>
        </IdentityProvider>
    )
}

export default AuthProvider
