import {
    ControlResourceApiFp,
    RegistrationDocumentResourceApiFp,
    RegistrationFormResourceApiFp,
    FacialCaptureResourceApiFp,
    StatisticsResourceApiFp,
    RegistrationDocumentResourceV1ApiFp,
    FacialCaptureResourceV1ApiFp
} from './api'
import { Configuration } from './configuration'

export const controlResourceApi = ControlResourceApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const registrationFormResourceApi = RegistrationFormResourceApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const registrationDocumentResourceApi = RegistrationDocumentResourceApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const facialCaptureResourceApi = FacialCaptureResourceApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const statisticsResourceApi = StatisticsResourceApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const registrationDocumentResourceV1Api = RegistrationDocumentResourceV1ApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))

export const facialCaptureResourceV1Api = FacialCaptureResourceV1ApiFp(new Configuration({ basePath: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_API }))