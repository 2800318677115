import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import { actions as controlsActions, selectors as controlSelectors } from '../..'
import { StimulusType } from '../../../../config/errors'
import { controlResourceApi } from '../../../../services'
import { UpdateDocumentNumberPayload } from '../../../../services/api'
import { selectors as navigationSelectors } from '../../../navigation'
import { http } from '../../../utils'

export function* control(): SagaIterator {
    try {
        const currentRegistrationFormId = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId = yield select(controlSelectors.getDocumentId)
        const documentNumber = yield select(controlSelectors.getDocumentNumber)
        const personalNumber = yield select(controlSelectors.getPersonalNumber)
        const stakeholder = yield select(controlSelectors.getStakeHolder)
        const payload: UpdateDocumentNumberPayload = { documentNumber, personalNumber }
        yield call(
            http(),
            controlResourceApi.updateDocumentNumber,
            currentRegistrationFormId,
            documentId,
            stakeholder,
            payload
        )
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}
