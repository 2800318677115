import { AxiosResponse } from 'axios'
import { last, pipe, sort } from 'ramda'
import { call } from 'redux-saga/effects'

import { isNotNil } from '@nickel/utils/lib/common'

import { facialCaptureResourceApi, facialCaptureResourceV1Api } from '../../services'
import { FacialCaptureListView, FacialCaptureView } from '../../services/api'
import { setDataToLocalStorage } from '../documents/utils'
import { ERRORS } from '../errors'
import { http } from '../utils'

function* downloadFacialCapture(facialCaptureId: string, formId: string) {
    const { data }: AxiosResponse<Blob> = yield call(
        http(),
        facialCaptureResourceV1Api.downloadFacialCaptureImageParts,
        formId,
        facialCaptureId
    )
    return (yield call(setDataToLocalStorage, data)) as string
}

export function* fetchLastFacialCapture(formId: string) {
    try {
        const {
            data: { facialCaptures }
        }: AxiosResponse<FacialCaptureListView> = yield call(http(), facialCaptureResourceApi.getFacialCaptures, formId)

        if (isNotNil(facialCaptures)) {
            const lastFacialCapture = pipe(
                sort(
                    (a: FacialCaptureView, b: FacialCaptureView) =>
                        (a.createdAt ? new Date(a.createdAt).getTime() : 0) -
                        (b.createdAt ? new Date(b.createdAt).getTime() : 0)
                ),
                (list) => last<FacialCaptureView>(list)
            )(facialCaptures)

            if (isNotNil(lastFacialCapture) && isNotNil(lastFacialCapture.id)) {
                const key: string = yield call(downloadFacialCapture, lastFacialCapture.id, formId)

                return [
                    {
                        ...lastFacialCapture,
                        key
                    }
                ]
            }
        }

        return new Error(ERRORS.FACIAL_CAPTURES)
    } catch (err) {
        return new Error(ERRORS.FACIAL_CAPTURES)
    }
}
