import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import Avatar from '@nickel/core-ui/components/Avatar'
import { AvatarSize } from '@nickel/core-ui/components/Avatar/types'
import { ArrowLeft, ArrowRight } from '@nickel/core-ui/components/Icons'
import { Color } from '@nickel/foundations/tokens'

import { DocumentFile } from '../../store/details'
import AsyncImage from '../AsyncImage'

import Styled from './styles'

type Props = {
    files: DocumentFile[]
    titleContext?: string
    titleKey?: string
    rotateDegrees?: Record<string, number>
    onRotateDocument?: (id: string, rotateDegrees: number) => void
}

const DocumentsList = ({ files, titleContext, titleKey, rotateDegrees = {}, onRotateDocument }: Props) => {
    const { t } = useTranslation()

    const rotate90Left = useCallback(
        (file: DocumentFile, degrees: number) => {
            onRotateDocument?.(file.id, (degrees - 90) % 360)
        },
        [onRotateDocument]
    )

    const rotate90Right = useCallback(
        (file: DocumentFile, degrees: number) => {
            onRotateDocument?.(file.id, (degrees + 90) % 360)
        },
        [onRotateDocument]
    )

    return (
        <>
            {titleKey && <h2>{t(titleKey, { context: titleContext })}</h2>}
            <Styled.DetailFiles count={files.length}>
                {files.map((file: DocumentFile, index) => {
                    const testIdPrefix = `documents#${index}`
                    const degrees = rotateDegrees[file.id] ?? 0
                    return (
                        <Styled.ImgContainer data-testid={`${testIdPrefix}#container`} key={testIdPrefix}>
                            <Styled.AvatarBtnLeft
                                type="button"
                                onClick={() => rotate90Left(file, degrees)}
                                data-testid={`${testIdPrefix}#btn#rotate90Left`}
                            >
                                <Avatar
                                    icon={<ArrowLeft />}
                                    size={AvatarSize.MEDIUM}
                                    backgroundColor={Color.OUTLINE}
                                    testId={`${testIdPrefix}#avatar#rotate90Left`}
                                />
                            </Styled.AvatarBtnLeft>
                            <Styled.AvatarBtnRight
                                type="button"
                                onClick={() => rotate90Right(file, degrees)}
                                data-testid={`${testIdPrefix}#btn#rotate90Right`}
                            >
                                <Avatar
                                    icon={<ArrowRight />}
                                    size={AvatarSize.MEDIUM}
                                    backgroundColor={Color.OUTLINE}
                                    testId={`${testIdPrefix}#avatar#rotate90Right`}
                                />
                            </Styled.AvatarBtnRight>
                            <AsyncImage
                                key={file.key}
                                rotateDegrees={degrees}
                                forageKey={file.key}
                                testId={testIdPrefix}
                                fileId={file.id}
                            />
                        </Styled.ImgContainer>
                    )
                })}
            </Styled.DetailFiles>
        </>
    )
}

export default DocumentsList
