import React, { useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Pagination from '@nickel/core-ui/components/Pagination'
import { formatDataTestId } from '@nickel/core-ui/utils'
import Table from '@nickel/ui/components/legacy/Table'

import { ROUTES } from '../../navigation/constants'
import { actions as navigationActions } from '../../store/navigation'

import Styled from './styles'
import useRegistrationFormList, { QUERY_DEFAULT_LIMIT } from './useRegistrationFormList'
import { convertToTableData, getColumns } from './utils'

const RegistrationFormList = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation()

    const dispatch = useDispatch()
    const columns = useMemo(getColumns, [language])

    const {
        isLoading,
        data: { registrationForms = [], totalCount },
        showMore
    } = useRegistrationFormList()

    const onRowClick = useCallback(
        (row) => {
            const path = { pathname: `/${ROUTES.DETAILS}/${row.original.id}` }
            return dispatch(navigationActions.goTo(path))
        },
        [dispatch]
    )

    return (
        <>
            <Styled.TableContainer>
                <Table
                    columns={columns}
                    data={convertToTableData(registrationForms)}
                    loading={isLoading}
                    noDataText={t('home:no-registration-form')}
                    onRowClick={onRowClick}
                    predictedRowCount={20}
                />
            </Styled.TableContainer>
            {totalCount && !isLoading && (
                <Styled.Footer>
                    <Styled.Container>
                        <Pagination
                            ariaControlsId="id"
                            infiniteScroll
                            loading={isLoading}
                            onPageChange={showMore}
                            pageIndex={0}
                            pageSize={QUERY_DEFAULT_LIMIT}
                            testId={formatDataTestId('registrationFormList')}
                            totalElements={totalCount}
                        />
                    </Styled.Container>
                    <Styled.Label>
                        {t('home:registration-form-found', {
                            count: Math.min(totalCount ?? 0, registrationForms.length),
                            totalCount,
                            context: totalCount > 1 ? 'plural' : ''
                        })}
                    </Styled.Label>
                </Styled.Footer>
            )}
        </>
    )
}

export default RegistrationFormList
