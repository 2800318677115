import React, { useEffect, ReactNode, useRef, useCallback } from 'react'

import ReactDOM from 'react-dom'

type Props = {
    children?: ReactNode
    host: string
    id: string
    mfProps: Record<string, unknown>
}

enum Status {
    MOUNTED = 'MOUNTED',
    MOUNTING = 'MOUNTING',
    UNMOUNTED = 'UNMOUNTED'
}

window.mf = window.mf || {}
window.React = React
window.ReactDOM = ReactDOM

const MicroFrontendLoader = ({ children = null, host, id, mfProps }: Props) => {
    const status = useRef<Status>(Status.UNMOUNTED)

    const renderMF = useCallback(() => {
        const mf = window.mf[id]
        if (!mf) {
            setTimeout(renderMF, 100)
            return
        }
        mf.render(id, undefined, mfProps)
        status.current = Status.MOUNTED
    }, [id, mfProps])

    useEffect(() => {
        if (status.current !== Status.UNMOUNTED) return
        const mf = window.mf[id]
        const timestamp = Date.now().toString()
        const scriptId = `micro-frontend-script-${id}`
        status.current = Status.MOUNTING
        fetch(`${host}/asset-manifest.json?${timestamp}`)
            .then((res) => res.json())
            .then((manifest) => {
                manifest.entrypoints.forEach((entrypoint: string) => {
                    if (!entrypoint.endsWith('js')) return
                    const script = document.createElement('script')
                    script.id = scriptId
                    script.crossOrigin = ''
                    script.src = `${host}/${entrypoint}`
                    document.head.appendChild(script)
                })
                renderMF()
            })
            .catch(() => {
                status.current = Status.UNMOUNTED
            })
        // On unmount
        return () => {
            if (!mf || status.current !== Status.MOUNTED) return
            mf.unmount(id)
        }
    }, [host, id, renderMF])

    return (
        <div id={id} key={id}>
            {children}
        </div>
    )
}

export default MicroFrontendLoader
