import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import { actions as controlsActions, selectors as controlSelectors } from '../..'
import { StimulusType } from '../../../../config/errors'
import { controlResourceApi } from '../../../../services'
import { UpdateDocumentNumberPositionPayload } from '../../../../services/api'
import { selectors as navigationSelectors } from '../../../navigation'
import { http } from '../../../utils'

export function* control(payload: UpdateDocumentNumberPositionPayload): SagaIterator {
    try {
        const currentRegistrationFormId = yield select(navigationSelectors.getCurrentRegistrationFormId)
        const documentId = yield select(controlSelectors.getDocumentId)
        yield call(
            http(),
            controlResourceApi.updateDocumentNumberPosition,
            currentRegistrationFormId,
            documentId,
            payload
        )
        yield put(controlsActions.getNextControlAsyncRequest())
    } catch (err) {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DEFAULT)))
    }
}
