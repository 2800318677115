import { darken } from 'polished'
import styled, { css } from 'styled-components'

import Button from '@nickel/ui/components/legacy/Button'
import { FontSize, FontWeight, Spacing } from '@nickel/ui/tokens'

import { ComplianceStatus, RegistrationFormType } from '../../../services/api'
import { getComplianceStatusColor, getRegistrationFormTypeColor } from '../../utils'

interface ComplianceStatusProp {
    readonly complianceStatus: ComplianceStatus
}

interface FlexProps {
    readonly flexGrow?: string
}

interface RegistrationFormTypeProp {
    readonly registrationFormType: RegistrationFormType
}

const BoxShadow = css`
    border-radius: 0.2em;
    box-shadow: 0.1rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
`

const Column = styled.div<FlexProps>`
    align-items: baseline;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${(props) => props.flexGrow && `flex-grow: ${props.flexGrow}`};
`

const Banner = styled.span<RegistrationFormTypeProp>`
    ${BoxShadow}
    background: ${(props) => getRegistrationFormTypeColor(props.registrationFormType)};
    color: #ffffff;
    font-size: ${FontSize.LARGE};
    font-weight: 500;
    margin: 0 1em 0 -1.5em;
    padding: ${Spacing.MEDIUM} ${Spacing.LARGE} ${Spacing.MEDIUM} ${Spacing.XLARGE};
    position: relative;
    text-transform: capitalize;

    &::before {
        border-left: ${Spacing.MEDIUM} solid transparent;
        border-top: 5px solid ${(props) => darken(0.1, getRegistrationFormTypeColor(props.registrationFormType))};
        bottom: -5px;
        content: '';
        height: 0;
        left: 3px;
        line-height: 0;
        position: absolute;
        width: 0;
    }

    > svg {
        fill: #ffffff;
        height: 0.7em;
        margin-left: 0.5em;
    }
`

const Infos = styled.div`
    align-items: stretch;
    background: #ffffff;
    column-gap: ${Spacing.LARGE};
    display: flex;
    ${BoxShadow}
    padding: 1em 1em 1.5em;
`
const InfoText = styled.span`
    margin-top: ${Spacing.MEDIUM};
`
const InfoTextRegistrationCode = styled.span`
    margin-top: ${Spacing.MEDIUM};
    text-align: end;
`

const Name = styled(InfoText)`
    font-weight: ${FontWeight.MEDIUM};
    text-transform: uppercase;
`

const NotCompliantCause = styled.span`
    align-self: flex-end;
    color: ${getComplianceStatusColor(ComplianceStatus.NOT_COMPLIANT)};
    font-size: ${FontSize.LARGE};
    font-weight: ${FontWeight.MEDIUM};
    text-transform: uppercase;
`

const RightSide = styled.div<FlexProps>`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 30%;
    & > :first-child {
        margin-right: ${Spacing.LARGE};
    }
`

const Status = styled.div`
    align-items: center;
    display: flex;
    font-size: ${FontSize.SMALL};
    margin: ${Spacing.LARGE} ${Spacing.XLARGE} 0 0;
    text-transform: uppercase;
`

const StatusIcon = styled.div<ComplianceStatusProp>`
    background-color: ${(props) => getComplianceStatusColor(props.complianceStatus)};
    border-radius: 1em;
    box-shadow: 0.1rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1) inset;
    height: 1em;
    margin-right: ${Spacing.MEDIUM};
    width: 1em;
`

const StatusLabel = styled.span`
    max-width: 6rem;
`

const ControlButton = styled(Button)`
    align-self: flex-end;
    margin-top: ${Spacing.MEDIUM};
`

const ReportButton = styled.div`
    align-self: flex-end;
    margin-top: ${Spacing.MEDIUM};
`

export default {
    Banner,
    Column,
    ControlButton,
    Infos,
    InfoText,
    Name,
    NotCompliantCause,
    RightSide,
    Status,
    StatusIcon,
    StatusLabel,
    ReportButton,
    InfoTextRegistrationCode
}
