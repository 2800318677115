import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SupportedCountryISO2 } from '@nickel/l10n/types'

import { UpdateComplianceStatusPayloadComplianceStatusEnum } from '../../services/api'
import {
    RegistrationFormComplianceStatusValues,
    RegistrationFormSortsValues,
    RegistrationFormTypesValues
} from '../../views/RegistrationFormList/types'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

export type ControlCustomerPayload = {
    registrationFormId: string | undefined
}

export type UpdateComplianceStatusPayload = {
    registrationFormId: string
    status: UpdateComplianceStatusPayloadComplianceStatusEnum
}

export enum Filters {
    sortComplianceStatus = 'sortComplianceStatus',
    sortCountry = 'sortCountry',
    sortInput = 'sortInput',
    sortFormType = 'sortFormType',
    sortOrder = 'sortOrder',
    dateRangeCreationDate = 'dateRangeCreationDate'
}

type RegistrationFormsState = {
    searchComplianceStatus: RegistrationFormComplianceStatusValues
    searchCountry: SupportedCountryISO2
    searchQuery: string
    searchRegistrationFormType: RegistrationFormTypesValues
    searchSort: RegistrationFormSortsValues
    searchDateRangeCreationDate: [string, string]
}

const INITIAL_STATE: RegistrationFormsState = {
    searchComplianceStatus: sessionStorage.getItem(
        Filters.sortComplianceStatus
    ) as RegistrationFormComplianceStatusValues,
    searchCountry: sessionStorage.getItem(Filters.sortCountry) as SupportedCountryISO2,
    searchQuery: sessionStorage.getItem(Filters.sortInput) as string,
    searchRegistrationFormType: sessionStorage.getItem(Filters.sortFormType) as RegistrationFormTypesValues,
    searchSort: sessionStorage.getItem(Filters.sortOrder) as RegistrationFormSortsValues,
    searchDateRangeCreationDate: getInitDateRangeCreationDate()
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@registration-forms'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setComplianceStatus: (
            state,
            { payload }: PayloadAction<RegistrationFormComplianceStatusValues>
        ): RegistrationFormsState => ({
            ...state,
            searchComplianceStatus: payload
        }),
        setSearchCountry: (state, { payload }: PayloadAction<SupportedCountryISO2>): RegistrationFormsState => ({
            ...state,
            searchCountry: payload
        }),
        setSearchQuery: (state, { payload }: PayloadAction<string>): RegistrationFormsState => ({
            ...state,
            searchQuery: payload
        }),
        setRegistrationFormType: (state, { payload }: PayloadAction<RegistrationFormTypesValues>) => ({
            ...state,
            searchRegistrationFormType: payload
        }),
        setSearchSort: (state, { payload }: PayloadAction<RegistrationFormSortsValues>): RegistrationFormsState => ({
            ...state,
            searchSort: payload
        }),
        searchDateRangeCreationDate: (state, { payload }: PayloadAction<[string, string]>): RegistrationFormsState => ({
            ...state,
            searchDateRangeCreationDate: payload
        })
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    controlCustomer: createAction<ControlCustomerPayload>(`${SLICE_NAME}/CONTROL_CUSTOMER`),
    updateComplianceStatus: createAction<UpdateComplianceStatusPayload>(`${SLICE_NAME}/UPDATE_COMPLIANCE_STATUS`)
}

/* ------------- Selectors ------------- */

const getSearchComplianceStatus = (state: RootState) => state.registrationForms.searchComplianceStatus
const getSearchCountry = (state: RootState) => state.registrationForms.searchCountry
const getSearchQuery = (state: RootState) => state.registrationForms.searchQuery
const getSearchRegistrationFormType = (state: RootState) => state.registrationForms.searchRegistrationFormType
const getSearchSort = (state: RootState) => state.registrationForms.searchSort
const getDateRangeCreationDate = (state: RootState) => state.registrationForms.searchDateRangeCreationDate

export const selectors = {
    getSearchComplianceStatus,
    getSearchCountry,
    getSearchQuery,
    getSearchRegistrationFormType,
    getSearchSort,
    getDateRangeCreationDate
}

/* ------------- Utils ------------- */

function getInitDateRangeCreationDate(): [string, string] {
    const storedRange = sessionStorage.getItem(Filters.dateRangeCreationDate)
    if (storedRange) {
        const range = JSON.parse(storedRange)
        return range
    }

    return ['', '']
}
