import { TemplateType } from '../../config/templates'
import { DoubtableValidityPayload, DoubtableValidity, RegistrationControl } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'

const doubt = {
    message: 'controls:COLORS.modals.doubt.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.COLORS,
            controlPayload: {
                validity: DoubtableValidity.DOUBT
            } as DoubtableValidityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:COLORS.modals.doubt.title'
}

const invalid = {
    message: 'controls:COLORS.modals.invalidate.message',
    primaryAction: {
        action: controlsActions.createControlAsyncRequest({
            controlType: RegistrationControl.COLORS,
            controlPayload: {
                validity: DoubtableValidity.INVALID
            } as DoubtableValidityPayload
        }),
        text: 'common:confirm'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.ERROR },
    title: 'controls:COLORS.modals.invalidate.title'
}

export default {
    doubt,
    invalid
}
