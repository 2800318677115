import React, { useEffect, useRef, useCallback } from 'react'

import { noop } from 'lodash'

import { formatDataTestId } from '@nickel/core-ui/utils'

import Styled from './styles'

export interface ImageParts {
    data: string[]
    size: {
        width: number
        height: number
    }
    nbRows: number
    nbColumns: number
}

interface GridImageProps {
    gridImage: ImageParts
    onClick?: () => void
    testId: string
    id?: string
}

const GridImage: React.FC<GridImageProps> = ({ gridImage, onClick = noop, testId, id }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    const redrawGridImages = useCallback(() => {
        const canvas = canvasRef.current
        if (!canvas) return

        const context = canvas.getContext('2d')
        if (!context) return

        const images = gridImage.data
        const parent = canvas.parentElement

        if (!parent) return

        const containerWidth = parent.clientWidth
        const { maxHeight: containerMaxHeightPx } = window.getComputedStyle(parent)

        const imageRatio = gridImage.size.width / gridImage.size.height

        let canvasWidth = containerWidth
        let canvasHeight = canvasWidth / imageRatio

        const containerMaxHeight = parseFloat(containerMaxHeightPx)

        if (canvasHeight > containerMaxHeight) {
            canvasWidth = containerMaxHeight * imageRatio
            canvasHeight = containerMaxHeight
        }

        canvas.width = canvasWidth
        canvas.height = canvasHeight

        const imageWidth = Math.floor(canvasWidth / gridImage.nbColumns)
        const imageHeight = Math.floor(canvasHeight / gridImage.nbRows)

        context.clearRect(0, 0, canvasWidth, canvasHeight)

        for (let row = 0; row < gridImage.nbRows; row += 1) {
            for (let col = 0; col < gridImage.nbColumns; col += 1) {
                const x = col * imageWidth
                const y = row * imageHeight
                const base64Image = images[row * gridImage.nbColumns + col]
                const img = new Image()

                img.onload = () => {
                    context.drawImage(img, x, y, imageWidth, imageHeight)
                }

                img.src = base64Image
            }
        }
    }, [gridImage])

    useEffect(() => {
        redrawGridImages()
    }, [gridImage, redrawGridImages])

    useEffect(() => {
        if (!canvasRef?.current) return
        const handleContextMenu = (event: MouseEvent) => event.preventDefault()
        const canvas = canvasRef.current
        canvas.addEventListener('contextmenu', handleContextMenu)
        return () => {
            canvas.removeEventListener('contextmenu', handleContextMenu)
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            redrawGridImages()
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [gridImage, redrawGridImages])

    return <Styled.Canvas ref={canvasRef} onClick={onClick} data-testid={formatDataTestId(testId, 'canvas')} id={id} />
}

export default GridImage
