import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export default {
    Container
}
