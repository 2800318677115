import { actions as uiActions } from '@nickel/stimulus/ui'

import { DoubtableValidity, RegistrationControl, DoubtableValidityPayload } from '../../services/api'
import { actions as controlsActions } from '../../store/controls'
import { Control } from '../types'

import modals from './modals'

const control: Control = {
    actions: {
        invalidate: [
            {
                action: uiActions.showModal(modals.isPhotocopyConfirmationModal),
                label: 'common:i-have-a-doubt'
            },
            {
                action: uiActions.showModal(modals.isFraudConfirmationModal),
                label: 'common:yes'
            }
        ],
        validate: [
            {
                action: controlsActions.createControlAsyncRequest({
                    controlType: RegistrationControl.PHOTOCOPY,
                    controlPayload: {
                        validity: DoubtableValidity.VALID
                    } as DoubtableValidityPayload
                }),
                label: 'common:no'
            }
        ]
    }
}

export default control
