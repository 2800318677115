import styled, { createGlobalStyle } from 'styled-components'

import { Spacing } from '@nickel/ui/tokens'

import '@csstools/normalize.css'

const Global = createGlobalStyle`
    body {
        margin: 0;
    }

    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    [data-testid="Modal#content"] {
        overflow: visible !important;
    }
`

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    overflow: hidden auto;
    position: relative;
`

const LeftSection = styled.div<{ collapsed: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    transform: translateX(-100%);
    transition: 200ms all ease-in;
    ${(props) =>
        !props.collapsed &&
        `
        transform: translateX(0);
    `};
    width: 100%;
`

const RightSection = styled.div<{ collapsed: boolean }>`
    background-color: rgb(242, 242, 242);
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    transform: translateX(100%);
    transition: 200ms all ease-in;
    width: 100%;
    ${(props) =>
        !props.collapsed &&
        `
        transform: translateX(3.75rem);
        width: calc(100% - 3.75rem);
    `};
`

const Title = styled.h1`
    flex: 1;
`

const TitleContainer = styled.div`
    align-items: center;
    display: flex;
    padding: 0 ${Spacing.LARGE};
`

export default {
    Content,
    Global,
    LeftSection,
    RightSection,
    Title,
    TitleContainer
}
