import { getCurrentLng } from '@nickel/i18n'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

export default function getISO2CountryCodeFromCurrentLng() {
    switch (getCurrentLng().slice(0, 2).toLowerCase()) {
        case 'fr':
            return SupportedCountryISO2.FR
        case 'es':
            return SupportedCountryISO2.ES
        default:
            return SupportedCountryISO2.BE
    }
}
