import React, { useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import InheritedPropertiesProvider from '@nickel/ui/inheritedProperties/InheritedPropertiesProvider'

import { ROUTES } from '../../navigation/constants'
import { actions as navigationActions, selectors as navigationSelectors } from '../../store/navigation'

import ComplianceStatusFilter from './fields/ComplianceStatusFilter'
import DateRangeInputCreationAt from './fields/DateRangeInputCreationAt'
import RegistrationFormTypeFilter from './fields/RegistrationFormTypeFilter'
import SearchCountry from './fields/SearchCountry'
import SearchInput from './fields/SearchInput'
import SearchSort from './fields/SearchSort'
import Styled from './styles'

const SearchBar = () => {
    const isHomeDisplayed = useSelector(navigationSelectors.getIsHomeDisplayed)
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        if (!isHomeDisplayed) dispatch(navigationActions.goTo({ pathname: ROUTES.HOME }))
    }, [dispatch, isHomeDisplayed])

    return (
        <InheritedPropertiesProvider>
            <Styled.SearchBarContainer onClick={onClick} isCollapsed={!isHomeDisplayed}>
                <SearchCountry />
                <RegistrationFormTypeFilter />
                <ComplianceStatusFilter />
                <SearchSort />
                <DateRangeInputCreationAt />
                <SearchInput />
            </Styled.SearchBarContainer>
        </InheritedPropertiesProvider>
    )
}

export default SearchBar
