import React from 'react'

import { Dispatch } from 'redux'
import styled, { keyframes } from 'styled-components'

import i18ng from '@nickel/i18n'
import { UiDataType } from '@nickel/stimulus/stimulus/config'
import { actions as uiActions, ToastState } from '@nickel/stimulus/ui'
import Alert from '@nickel/ui/components/legacy/Alert'
import { AlertPosition, AlertVariant } from '@nickel/ui/components/legacy/Alert/types'

import { getHeaderHeight } from '../layout/utils'

type AlertProps = {
    top: number
}

export type ToastData = UiDataType & {
    position?: AlertPosition
    variant?: AlertVariant
}

type ToastProps = ToastState & {
    uiData: ToastData
}

const TOAST_ANIMATION_DURATION = 5000

const slideInOut = keyframes`
    0% { opacity: 0; transform: translateX(calc(100% + 0.5rem)); }
    3%, 97% { opacity: 1; transform: translateX(0); }
    100%   { opacity: 0; transform: translateX(calc(100% + 0.5rem)); }
`

const Styled = {
    Alert: styled(Alert)<AlertProps>`
        animation: ${slideInOut} ${TOAST_ANIMATION_DURATION}ms ease-out;
        top: calc(0.5rem + ${(props) => `${props.top}px`});
        transform: translateX(calc(100% + 0.5rem));
    `
}

const renderToast = (toast: ToastProps, dispatch: Dispatch, i18n: typeof i18ng) => {
    const {
        isVisible,
        uiData: { message, variant = AlertVariant.SUCCESS, position = AlertPosition.TOP_RIGHT }
    } = toast

    return (
        isVisible && (
            <Styled.Alert
                autoHideDuration={TOAST_ANIMATION_DURATION}
                onClose={() => dispatch(uiActions.hideToast())}
                position={position}
                testId="SuccessToast"
                top={getHeaderHeight()}
                variant={variant}
                visible={isVisible}
            >
                {i18n.t(message ?? '')}
            </Styled.Alert>
        )
    )
}
export default renderToast
