import { createAction, createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { path } from 'ramda'

import { RegistrationDocumentOcrPropertiesView } from '../../services/api'
import { RootState } from '../rootReducer'

/* ------------- Type & State ------------- */

export interface PutOcrPropertiesPayload {
    documentId: string
    ocrProperties: RegistrationDocumentOcrPropertiesView
}

export interface UpdateFetchingIdsPayload {
    documentId: string
    fetched?: boolean
}

export type OcrPropertiesState = {
    data?: Record<string, RegistrationDocumentOcrPropertiesView>
    fetchingIds?: string[]
    noOcrPropertiesIds?: string[]
    registrationFormId?: string
}

export const INITIAL_STATE: OcrPropertiesState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@ocr-properties'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        pushNoOcrPropertiesId: (state, { payload: documentId }: PayloadAction<string>): OcrPropertiesState => ({
            ...state,
            noOcrPropertiesIds: Array.isArray(state.noOcrPropertiesIds)
                ? [...state.noOcrPropertiesIds, documentId]
                : [documentId]
        }),
        putOcrProperties: (state, { payload }: PayloadAction<PutOcrPropertiesPayload>): OcrPropertiesState => ({
            ...state,
            data: {
                ...state.data,
                [payload.documentId]: payload.ocrProperties
            }
        }),
        setRegistrationFormId: (state, { payload: registrationFormId }: PayloadAction<string>): OcrPropertiesState => ({
            registrationFormId
        }),
        updateFetchingIds: (
            state,
            { payload: { documentId, fetched = false } }: PayloadAction<UpdateFetchingIdsPayload>
        ): OcrPropertiesState => {
            const fetchingIds: string[] = state.fetchingIds ?? []
            const index: number = fetched ? fetchingIds.indexOf(documentId) : -1
            if (fetched && index === -1) return state

            return {
                ...state,
                fetchingIds:
                    index > -1
                        ? [...fetchingIds.slice(0, index), ...fetchingIds.slice(index + 1)]
                        : [...fetchingIds, documentId]
            }
        }
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    fetch: createAction<string>(`${SLICE_NAME}/FETCH`)
}

/* ------------- Selectors ------------- */

const getOcrPropertiesState: Selector<RootState, OcrPropertiesState | undefined> = (state) => state.ocrProperties

const getRegistrationFormId: Selector<RootState, string | undefined> = createSelector(
    [getOcrPropertiesState],
    path(['registrationFormId'])
)

const getOcrProperties: Selector<
    RootState,
    Record<string, RegistrationDocumentOcrPropertiesView> | undefined
> = createSelector([getOcrPropertiesState], path(['data']))

const getOcrPropertiesByDocumentId: (
    documentId: string
) => Selector<RootState, RegistrationDocumentOcrPropertiesView | undefined> = (documentId: string) =>
    createSelector([getOcrProperties], path([documentId]))

const hasNoOcrProperties: (documentId: string) => Selector<RootState, boolean> = (documentId: string) =>
    createSelector([getOcrPropertiesState], (state) =>
        state?.noOcrPropertiesIds ? state.noOcrPropertiesIds.includes(documentId) : false
    )

const isFetchingId: (documentId: string) => Selector<RootState, boolean> = (documentId: string) =>
    createSelector([getOcrPropertiesState], (state) =>
        state?.fetchingIds ? state.fetchingIds.includes(documentId) : false
    )

export const selectors = {
    getOcrProperties,
    getOcrPropertiesByDocumentId,
    getRegistrationFormId,
    hasNoOcrProperties,
    isFetchingId
}

/* ------------- Utils ------------- */
