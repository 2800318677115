import { actions as navigationActions } from '../../store/navigation'

export const accessUnauthorized = {
    primaryAction: {
        text: 'common:cancel'
    },
    secondaryAction: {
        action: navigationActions.goToExternal({ pathname: 'https://habilitations.nickel.club/identityiq/login.jsf' }),
        text: 'home:modal.secondary-button'
    },
    message: 'home:modal.body',
    title: 'home:modal.title'
}
