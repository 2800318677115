import styled from 'styled-components'

import AddCircleIcon from '@nickel/ui/components/icons/AddCircle'
import XCircleIcon from '@nickel/ui/components/icons/XCircle'
import { Color, FontSize, FontWeight, Spacing } from '@nickel/ui/tokens'

const AddIcon = styled(AddCircleIcon)`
    align-self: flex-end;
    cursor: pointer;
    fill: ${Color.SUCCESS};
    font-size: ${Spacing.XXLARGE};
    margin: ${Spacing.SMALL} ${Spacing.MEDIUM};
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${Spacing.MEDIUM};
`

const RemoveIcon = styled(XCircleIcon)`
    cursor: pointer;
    font-size: ${Spacing.XXLARGE};
    margin: 0 ${Spacing.MEDIUM};
`

const Row = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: ${Spacing.MEDIUM};

    > :first-child {
        flex-grow: 1;
    }
`

const Title = styled.label`
    color: ${Color.PRIMARY};
    display: block;
    font-size: ${FontSize.LARGE};
    font-weight: ${FontWeight.MEDIUM};
    margin-bottom: ${Spacing.MEDIUM};
`

export default {
    AddIcon,
    Container,
    RemoveIcon,
    Row,
    Title
}
