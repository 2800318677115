import { AxiosError } from 'axios'
import { DefaultOptions } from 'react-query'

const queryConfig: DefaultOptions<AxiosError> = {
    queries: {
        refetchOnWindowFocus: false
    }
}

export default queryConfig
