import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DateRangeInput from '@nickel/ui/components/legacy/form/DateRangeInput'

import {
    actions as registrationFormsActions,
    selectors as registrationFormsSelectors
} from '../../../store/registrationForms'

const DateRangeInputCreationAt = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const storedDateRangeCreationDate = useSelector(registrationFormsSelectors.getDateRangeCreationDate)

    const handleDateRangeChange = useCallback(
        (range: [string, string]) => {
            dispatch(registrationFormsActions.searchDateRangeCreationDate(range))
        },
        [dispatch]
    )

    return (
        <DateRangeInput
            testId="dateRangeCreationDate"
            value={storedDateRangeCreationDate}
            label={t('home:date-range-creation-date')}
            onChange={handleDateRangeChange}
        />
    )
}

export default DateRangeInputCreationAt
