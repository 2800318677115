import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import history from './history'
import rootReducer from './rootReducer'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware(history), sagaMiddleware]
const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares
})

sagaMiddleware.run(rootSaga)

if (window.Cypress) {
    window.store = store
}

export default store
