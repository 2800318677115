import { useEffect, useState, FunctionComponent } from 'react'

import ReactDOM from 'react-dom'

interface NewWindowProps {
    newWindow: Window | null
    customActions?: () => void
}

const NewWindow: FunctionComponent<NewWindowProps> = ({ children, newWindow, customActions }) => {
    const [containerEl, setContainerEl] = useState<HTMLDivElement>()

    useEffect(() => {
        setContainerEl(document.createElement('div'))
    }, [])

    useEffect(() => {
        if (!containerEl) return
        if (!newWindow) return
        newWindow.requestAnimationFrame(() => {
            const event = new Event('resize')
            window.dispatchEvent(event)
        })
        newWindow.document.body.appendChild(containerEl)
        newWindow.addEventListener('contextmenu', (event) => event.preventDefault())
        customActions?.()
        return () => {
            if (!newWindow) return
            newWindow.close()
        }
    }, [containerEl, customActions, newWindow])

    return containerEl ? ReactDOM.createPortal(children, containerEl) : null
}

export default NewWindow
