import { TemplateType } from '../config/templates'
import { ComplianceStatus } from '../services/api'
import { actions as activityReportActions } from '../store/activityReport'
import { actions as registrationFormsActions } from '../store/registrationForms'

export const confirmCompliantStatus = (registrationFormId: string, status: ComplianceStatus) => ({
    primaryAction: {
        action: registrationFormsActions.updateComplianceStatus({ registrationFormId, status }),
        text: 'common:yes'
    },
    secondaryAction: {
        text: 'common:no'
    },
    title: 'details:update-compliant-status-confirmation'
})

export const exportActivityReport = {
    primaryAction: {
        action: activityReportActions.fetchAsyncRequest(),
        text: 'activity-report:modal.export'
    },
    secondaryAction: {
        text: 'common:cancel'
    },
    template: { type: TemplateType.EXPORT_ACTIVITY_REPORT },
    title: 'activity-report:modal.title'
}
