import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from '@nickel/core-ui/components/Button'
import * as icons from '@nickel/ui/components/icons'

import { actions as onfidoReportActions } from '../../store/onfidoReport'

const OnfidoReport = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const onOpenOnfidoReport = useCallback(() => dispatch(onfidoReportActions.getOnfidoReportAction()), [dispatch])
    return (
        <Button
            onClick={onOpenOnfidoReport}
            Icon={icons.ExitTopRight}
            testId="onfido_report"
            text={t('common:onfido_report')}
            variant="ghost"
        />
    )
}

export default OnfidoReport
