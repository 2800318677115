import { isEmpty } from 'ramda'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import { StimulusType } from '../../config/errors'
import { ROUTES } from '../../navigation/constants'
import { registrationFormResourceApi } from '../../services'
import { RegistrationFormListItemView, AccountStakeholder, BirthDataView } from '../../services/api'
import { fetchDocuments, downloadFile } from '../documents/sagas'
import { ERRORS } from '../errors'
import { fetchLastFacialCapture } from '../facialCaptures/sagas'
import { actions as navigationActions, selectors as navigationSelectors } from '../navigation'
import { actions as ocrPropertiesActions } from '../ocrProperties'
import { http } from '../utils'

import { actions as detailsActions, Details, RegistrationDocument, DocumentFile } from '.'

export function* fetchForm(formId: string) {
    try {
        const { data }: { data: RegistrationFormListItemView } = yield call(
            http(),
            registrationFormResourceApi.getRegistrationForm,
            formId
        )
        return data
    } catch (err) {
        return new Error(ERRORS.FORM)
    }
}

export function* fetchBirthData(formId: string, stakeHolder: AccountStakeholder = AccountStakeholder.OWNER) {
    try {
        const { data }: { data: BirthDataView } = yield call(
            http(),
            registrationFormResourceApi.getBirthData,
            formId,
            stakeHolder
        )
        return data
    } catch (err) {
        return new Error(ERRORS.BIRTH_DATA)
    }
}

export function* fetchCivilData(formId: string, stakeHolder: AccountStakeholder = AccountStakeholder.OWNER) {
    try {
        const { data } = yield call(http(), registrationFormResourceApi.getCivilData, formId, stakeHolder)
        return data
    } catch (err) {
        return new Error(ERRORS.CIVIL_DATA)
    }
}
export function* fetchBirthAndNationalitiesData(
    formId: string,
    stakeHolder: AccountStakeholder = AccountStakeholder.OWNER
) {
    try {
        const { data: civilData } = yield call(http(), registrationFormResourceApi.getCivilData, formId, stakeHolder)
        const { data }: { data: BirthDataView } = yield call(
            http(),
            registrationFormResourceApi.getBirthData,
            formId,
            stakeHolder
        )
        return { ...data, nationalities: civilData.nationalities }
    } catch (err) {
        return new Error(ERRORS.BIRTH_DATA)
    }
}

export function* fetchTaxId(formId: string, stakeHolder: AccountStakeholder = AccountStakeholder.OWNER) {
    try {
        const { data } = yield call(http(), registrationFormResourceApi.getPersonalNumber, formId, stakeHolder)
        return data
    } catch (err) {
        return new Error(ERRORS.TAX_ID)
    }
}

function* downloadFiles(registrationDocument: RegistrationDocument, formId: string) {
    if (!registrationDocument.files || !registrationDocument.id) return null

    try {
        yield put(ocrPropertiesActions.fetch(registrationDocument.id))

        const fileKeys: string[] = yield all(
            registrationDocument.files.map((file: DocumentFile) => {
                return call(downloadFile, file.id as string, registrationDocument.id as string, formId)
            })
        )

        const files = registrationDocument.files.map((file, index) => ({
            ...file,
            key: fileKeys[index]
        }))

        return { ...registrationDocument, files }
    } catch (err) {
        return new Error(ERRORS.DOWNLOAD_FILES)
    }
}

function* fetch() {
    const formId: string = yield select(navigationSelectors.getCurrentRegistrationFormId)

    const { form, birth, civil, documents, facialCaptures } = yield all({
        form: call(fetchForm, formId),
        birth: call(fetchBirthData, formId),
        civil: call(fetchCivilData, formId),
        documents: call(fetchDocuments),
        facialCaptures: call(fetchLastFacialCapture, formId)
    })

    const errors: string[] = [form, birth, civil, documents, facialCaptures].reduce((acc, current) => {
        if (current instanceof Error) acc.push(current.message)
        return acc
    }, [])

    const registrationDocuments: Array<RegistrationDocument | Error> | undefined =
        documents instanceof Error
            ? []
            : yield all(
                  documents.registrationDocuments.map((registrationDocument: RegistrationDocument) =>
                      call(downloadFiles, registrationDocument, formId)
                  )
              )

    const registrationDocumentsError: RegistrationDocument | Error | undefined = registrationDocuments?.find(
        (value) => value instanceof Error
    )
    if (registrationDocumentsError instanceof Error) errors.push(registrationDocumentsError.message)

    const details: Details = {
        ...form,
        ...birth,
        ...civil,
        ...(!isEmpty(registrationDocuments) && { registrationDocuments }),
        ...(!isEmpty(facialCaptures) && { facialCaptures })
    }

    if (errors.length) {
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.DETAILS_ERROR, errors)))
    }

    yield put(
        isEmpty(details) ? navigationActions.goTo({ pathname: ROUTES.HOME }) : detailsActions.fetchSuccess(details)
    )
}

export default function* sagas() {
    yield all([takeLatest(detailsActions.fetchRequest, fetch)])
}
