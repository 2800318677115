import { ImageParts } from '../GridImage/GridImage'

const rotateBase64Image = (base64Image: string, rotationDegrees: number): Promise<string> => {
    return new Promise((resolve) => {
        const image = new Image()
        image.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

            canvas.height = rotationDegrees % 180 === 0 ? image.height : image.width
            canvas.width = rotationDegrees % 180 === 0 ? image.width : image.height

            ctx.translate(canvas.width / 2, canvas.height / 2)

            ctx.rotate((rotationDegrees * Math.PI) / 180)

            ctx.drawImage(image, -image.width / 2, -image.height / 2)

            resolve(canvas.toDataURL())
        }
        image.src = base64Image
    })
}

const rotateBase64Images = (base64Images: string[], rotationDegrees: number): Promise<string[]> =>
    Promise.all(base64Images.map((base64Image) => rotateBase64Image(base64Image, rotationDegrees)))

// Internal method for rotating the die 90 degrees clockwise
const rotate90Degrees = (matrix: string[], m: number, n: number): { matrix: string[]; m: number; n: number } => {
    const pivotMatrix: string[] = new Array(n * m)

    for (let i = 0; i < m; i += 1) {
        for (let j = 0; j < n; j += 1) {
            pivotMatrix[j * m + (m - 1 - i)] = matrix[i * n + j]
        }
    }

    return { matrix: pivotMatrix, m: n, n: m }
}

const rotateMatrix = (matrix: string[], rotationDegrees: number, m: number, n: number): string[] => {
    const rotations: number = Math.floor((((rotationDegrees % 360) + 360) % 360) / 90) // Calculating the number of rotations to be performed

    let newMatrix: string[] = matrix

    let row = m
    let col = n
    for (let rotation = 0; rotation < rotations; rotation += 1) {
        const { matrix: mat, ...dim } = rotate90Degrees(newMatrix, row, col)
        newMatrix = mat
        row = dim.m
        col = dim.n
    }
    return newMatrix
}

export const rotateImageParts = async (imageParts: ImageParts, rotationDegrees: number) => {
    const {
        data: images,
        size: { height, width },
        nbRows,
        nbColumns
    } = imageParts
    const base64Images = await rotateBase64Images(images, rotationDegrees)
    const rotatedImage: string[] = rotateMatrix(base64Images, rotationDegrees, nbRows, nbColumns)

    const isDegreesMultipleOf180 = rotationDegrees % 180 === 0

    return {
        nbRows: isDegreesMultipleOf180 ? nbRows : nbColumns,
        nbColumns: isDegreesMultipleOf180 ? nbColumns : nbRows,
        data: rotatedImage,
        size: {
            height: isDegreesMultipleOf180 ? height : width,
            width: isDegreesMultipleOf180 ? width : height
        }
    }
}
export const getCurrentRotation = (el: HTMLElement) => {
    const style = window.getComputedStyle(el, null)
    const transform = style.getPropertyValue('transform') || 'none'
    if (transform !== 'none') {
        const values = transform.split('(')[1].split(')')[0].split(',')
        const angle = Math.round(Math.atan2(Number(values[1]), Number(values[0])) * (180 / Math.PI))
        return angle < 0 ? angle + 360 : angle
    }
    return 0
}

export const rotate90 = (el: HTMLElement, dir: 'right' | 'left') => {
    const isRight = dir === 'right'
    const currentAngle = getCurrentRotation(el)
    let translate: number
    if (currentAngle === 0) {
        translate = 300
    } else {
        translate = currentAngle === 180 ? -300 : 0
    }
    // eslint-disable-next-line no-param-reassign
    el.style.transform = `rotate(${isRight ? currentAngle + 90 : currentAngle - 90}deg) translateX(${
        isRight ? translate : -translate
    }px)`
}
